import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { API } from "../../constants/env";
import CarnetSTable from "./CarnetSTable";
import Smoyenne from "./Smoyenne";
import SmoyenneG from "./SmoyenneG";

const CarnetS = ({ trimestre }) => {
  const { id } = useParams();
  const [carnet, setCarnet] = useState(null);
  useEffect(() => {
    fetch(API.carnetStudent.info(id), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        setCarnet(response);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  }, []);
  const roundN = (num1) => {
    const num = num1?.toString(); //If it's not already a String
    const res = num.slice(0, num.indexOf(".") + 3); //With 3 exposing the hundredths place
    return Number(res);
  };

  const changeCarnetRemarque = (data) => {
    setCarnet({ ...carnet, ["remarque"]: data });
  };

  const changeModuleRemarque = (unitInedx, data) => {
    const units = carnet.units;
    units[unitInedx].unitRemarque = data;
    setCarnet({
      ...carnet,
      units,
    });
  };

  const changeNoteEdge = (unitInedx, subjectIndex, note, edge) => {
    if (note.length > 1 && note.charAt(0) === "0" && note.charAt(1) !== ",")
      note = note.substring(1);

    const { units } = carnet;
    const { subjects } = units[unitInedx];
    //  console.log(subjects[subjectIndex], note);
    if (edge === "max") subjects[subjectIndex].max = note;
    else subjects[subjectIndex].min = note;
    setCarnet({ ...carnet, units });
  };

  const changeNote = (unitInedx, subjectIndex, note) => {
    if (note.length > 1 && note.charAt(0) === "0" && note.charAt(1) !== ",")
      note = note.substring(1);
    const units = carnet.units;
    const subjects = units[unitInedx].subjects;
    subjects[subjectIndex].note = note;
    units[unitInedx].unitMoyenne = roundN(
      subjects.reduce((result, subject) => {
        return parseFloat(result) + parseFloat(subject.note);
      }, 0) / subjects.filter((subject) => subject.note !== 0).length
    );
    const moyenne = moyenneCarnet(units);
    setCarnet({ ...carnet, units, moyenne });
  };
  const moyenneCarnet = (units) => {
    const languageUnits = units.filter(
      (unit) =>
        unit.unitName === "مجال اللغة الفرنسية" ||
        unit.unitName === "مجال اللغة الانجليزية"
    );
    const languageCoef = languageUnits.reduce((result, unit) => {
      return unit.unitCoef + result;
    }, 0);

    const languageMoyenne = roundN(
      languageUnits.reduce((result, unit) => {
        return result + unit.unitMoyenne * unit.unitCoef;
      }, 0) / languageCoef
    );
    const MoyenneCor = [
      { moyenne: languageMoyenne, Coef: 1.5, Name: "Language" },
      ...units
        .filter(
          (unit) =>
            unit.unitName !== "مجال اللغة الفرنسية" &&
            unit.unitName !== "مجال اللغة الانجليزية"
        )
        .map((unit) => {
          return {
            moyenne: unit.unitMoyenne,
            Coef: unit.unitCoef,
            Name: unit.unitName,
          };
        }),
    ];

    const moyenneC = MoyenneCor.reduce(
      (result, moy) => {
        return {
          moyenne: result.moyenne + moy.moyenne * moy.Coef,
          Coef: moy.Coef + result.Coef,
        };
      },
      { moyenne: 0, Coef: 0 }
    );
    return moyenneC.moyenne / moyenneC.Coef;
  };

  const saveCarnet = () => {
    updateMoyT(calcMoy(), trimestre);
    fetch(API.carnetStudent.update(id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(carnet),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good job!", "Carnet modifie", "success");
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };
  const updateStatus = (status) => {
    fetch(API.carnetStudent.updateStatus(id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ status: status }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        fetch(API.carnetStudent.info(id), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.error) {
              return swal("Error", response.message, "error");
            }

            setCarnet(response);
          })
          .catch((e) => {
            return swal("Error", e.message, "error");
          });
        return swal("Good job!", "Status Updated", "success");
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };
  const updateMoyT = (moyenne, trimestre) => {
    fetch(API.overAllAverge.updateMoyT(carnet?.student?._id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        moyenne: moyenne.toFixed(2),
        trimestre: trimestre,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log({ response });
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good job!", "Status Updated", "success");
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };
  // console.log({ cc: carnet?.units });
  const calcMoy = () => {
    let moyArab = 0;
    let moyFr = 0;
    let moyT = 0;
    let finalMoy = 0;
    for (let i in carnet?.units) {
      if (
        carnet?.units[i].unitName === "مجال العربية" ||
        carnet?.units[i].unitName === "مجال اللغة العربية" ||
        carnet?.units[i].unitName === "مجال العلوم" ||
        carnet?.units[i].unitName === "مجال العلوم و التكنولوجيا"
      ) {
        moyArab = moyArab + carnet?.units[i].unitMoyenne * 2;
      } else if (carnet?.units[i].unitName === "مجال التنشئة") {
        moyT = moyT + carnet?.units[i].unitMoyenne * 1;
      } else {
        moyFr = moyFr + carnet?.units[i].unitMoyenne / 2;
      }
    }
    moyFr = moyFr * 1.5;

    return (moyFr + moyArab + moyT) / 6.5;
  };
  // console.log(carnet);
  return (
    <div>
      <div className="row justify-content-between align-items-center my-10">
        <div style={{ fontSize: "22px" }}>
          {" "}
          <span style={{ fontWeight: "bold", fontSize: "22px" }}>
            status :
          </span>{" "}
          {carnet?.status}
        </div>
        <div>
          {carnet?.status !== "initial" && (
            <>
              {carnet?.status === "pending" || carnet?.status === "cancled" ? (
                <span
                  className="btn btn-bg-success text-white "
                  onClick={() => updateStatus("approved")}
                >
                  approve
                </span>
              ) : null}
              {carnet?.status === "pending" || carnet?.status === "approved" ? (
                <span
                  className="btn btn-bg-danger text-white"
                  onClick={() => updateStatus("cancled")}
                >
                  cancel
                </span>
              ) : null}
            </>
          )}
        </div>
      </div>
      {carnet?.units?.map((unit, index) => (
        <React.Fragment key={index}>
          <CarnetSTable
            changeNote={changeNote}
            unit={unit}
            setCarnet={setCarnet}
            unitInedx={index}
            changeModuleRemarque={changeModuleRemarque}
            changeNoteEdge={changeNoteEdge}
          />
          <br />
        </React.Fragment>
      ))}

      <Smoyenne
              general
              studentId={carnet?.student._id}
        semestreNumberThree={localStorage.getItem("tris") == "troisieme"}
        changeCarnetRemarque={changeCarnetRemarque}
        confirm={localStorage.getItem("tris") != "troisieme"} // remove button save carnet when tris = 3
        color="#b87006"
        moyenne={calcMoy()}
        fontSizeTitle="28px"
        title="Moyenne trimestriel"
        fontSizeMoyenne="50px"
        remarque={carnet?.remarque}
        saveCarnet={saveCarnet}
      />
      {localStorage.getItem("tris") != "troisieme" && (
        <SmoyenneG
          minmax={true}
          studentId={carnet?.student._id}
          color="#b87006"
          moyenne={carnet?.moyenne}
          trimestre={localStorage.getItem("tris")}
          fontSizeTitle="18px"
          title="Moyenne général"
          fontSizeMoyenne="20px"
          saveCarnet={saveCarnet}
        />
      )}
      {localStorage.getItem("tris") === "troisieme" && (
        <SmoyenneG
          studentId={carnet?.student._id}
          color="#b87006"
          moyenne={carnet?.moyenne}
          trimestre={localStorage.getItem("tris")}
          fontSizeTitle="18px"
          title="Moyenne général"
          fontSizeMoyenne="20px"
          saveCarnet={saveCarnet}
        />
      )}
    </div>
  );
};

export default CarnetS;
