import React from "react";
import { STATIC } from "../../constants/env";
function CantineForm(props) {
  return (
    <div className="card card-custom">
      {/*begin::Form*/}
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          <div className="form-group">
            <label>
              Selectionnez le jour
              <span className="text-danger"> *</span>
            </label>

            <select
              className="form-control"
              ref={props.register}
              name="day"
              placeholder="Selectionnez le jour"
            >
              {STATIC.DAYS.map((elem, index) => {
                if (props.data) {
                  if (index === props.data.day) {
                    return (
                      <option selected value={index} key={index}>
                        {elem}
                      </option>
                    );
                  } else {
                    return (
                      <option value={index} key={index}>
                        {elem}
                      </option>
                    );
                  }
                } else {
                  return (
                    <option value={index} key={index}>
                      {elem}
                    </option>
                  );
                }
              })}
            </select>
          </div>

          <div className="form-group">
            <label>
              Ajouter Déjeuner<span className="text-danger"> *</span>
            </label>
            <div className="row">
              {props.fields.map(({ dej, id }, index) => (
                <div className="col-4 mb-3" key={id}>
                  <div className="row m-1">
                    <input
                      ref={props.registerRequired}
                      name={`meal[${index}].dej`}
                      type="text"
                      className="form-control col"
                      placeholder="Enter le Déjeuner"
                      defaultValue={dej}
                    />
                    <button
                      className="btn btn-icon btn-light btn-hover-danger  col-1"
                      onClick={() => props.remove(index)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <svg
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <title>Supprimer</title>
                          <desc>Created with Sketch.</desc>
                          <defs />
                          <g
                            id="Stockholm-icons-/-General-/-Trash"
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect
                              id="bound"
                              x={0}
                              y={0}
                              width={24}
                              height={24}
                            />
                            <path
                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                              id="round"
                              fill="#000000"
                              fillRule="nonzero"
                            />
                            <path
                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                              id="Shape"
                              fill="#000000"
                              opacity="0.3"
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                  {props.errors?.meal?.[index] && (
                    <span className="text-danger">Déjeuner est requis</span>
                  )}
                </div>
              ))}

              <div className="col-4">
                <button
                  className="btn btn-primary btn-sm font-weight-bolder "
                  onClick={() => props.append("")}
                >
                  {" "}
                  +{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button type="submit" className="btn btn-primary mr-2">
            Valider
          </button>
          <button
            type="reset"
            onClick={() => props.reset()}
            className="btn btn-secondary"
          >
            <i className="fa fa-redo"></i>reset
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default CantineForm;
