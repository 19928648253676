import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import Container from "../../components/Container/Container";
import NotifForm from "../../components/Notifications/NotifForm";
import { API, ROUTER } from "../../constants/env";

const EditNotif = () => {
  const { id } = useParams();

  const { register, handleSubmit, errors, reset, setValue, control } =
    useForm();
  const [data, setData] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    fetch(API.notification.info(id), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }
        //  console.log(response);

        setData(response);
      })
      .catch((e) => {
        return swal("Error fel request", e.message, "error");
      });
  }, []);

  const onSubmit = ({ file, value, title, subTitle, date, body, type }) => {
    console.log(value);
    const fd = new FormData();
    const values = value?.map(({ value }) => value);
    for (const elem of files) {
      fd.append("files", elem.file);
    }
    if (type !== "All")
      for (var i = 0; i < values?.length; i++) {
        fd.append("value[]", values[i]);
      }

    fd.append("type", type);
    fd.append("title", title);
    fd.append("subTitle", subTitle);
    fd.append("body", body);
    fd.append("date", date);

    fetch(API.notification.create, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      body: fd,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }
        return swal("Good job!", "Notification ajouter", "success");
      })
      .catch((e) => {
        return swal("Error fel request", e.message, "error");
      });
  };

  return (
    <Container
      name="Modifier  Notification"
      back={true}
      backRoute={ROUTER.NOTIFICATION.LIST}
      submit={true}
    >
      <NotifForm
        register={register()}
        registerRequired={register({ required: true })}
        errors={errors}
        data={data}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        setValue={setValue}
        control={control}
        files={files}
        setFiles={setFiles}
      />
    </Container>
  );
};

export default EditNotif;
