import React, { useState } from "react";
import Container from "../../components/Container/Container";
import Table from "../../components/Table/Table";
import { API, ROUTER } from "../../constants/env";
import swal from "sweetalert";
import moment from "moment";

const ListAbsences = () => {
  const fields = [
    { title: "Etudiant", field: "student" },
    { title: "Classe", field: "classe" },
    { title: "enseignant", field: "teacher" },
    { title: "Matiere", field: "subject" },
    { title: "Date", field: "date" },
    { title: "A/P", field: "title" },
  ];

  const [data, setData] = useState([]);

  const fetchData = (
    currentPage,
    count,
    search,
    setTotalPage,
    setTotal,
    idClasse
  ) => {
    fetch(
      API.absences.list +
        `?page=${currentPage}&count=${count}&searchQuery=${search}&filterByClasse=${idClasse}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        const result = response.data.map((elem) => ({
          _id: elem._id,
          teacher: elem.teacher.profil.firstName,
          subject: elem.subject.name,
          classe: elem.classe.level,
          title: elem.title,
          student: `${elem.student.profil.firstName} ${elem.student.profil.lastName}`,
          date: moment(elem.date).format("L"),
        }));
        setData(result);
        setTotalPage(response.totalPage);
        setTotal(response.total);
      })
      .catch((e) => {
        swal("Error", e.message, "error");
      });
  };

  return (
    <Container
      name="Registre d'appel"
      actionRoute={ROUTER.ABSENCE.CREATE}
      actionName="Ajouter"
    >
      <Table
        name="Liste des absences et punitions"
        fields={fields}
        data={data}
        classeApi={API.classes.all}
        pageName="abs"
        editRoute={(id) => ROUTER.ABSENCE.EDIT(id)}
        deleteApiRoute={API.absences.delete}
        deleteMultiple={API.absences.deleteMultiple}
        fetchData={fetchData}
        showSearch={true}
      />
    </Container>
  );
};

export default ListAbsences;
