import React, { useState, useEffect } from "react";
import Container from "../../../components/Container/Container";
import ServicesForm from "../../../components/Services/ServicesForm";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { API } from "../../../constants/env";
import { useParams } from "react-router-dom";

const EditServices = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const { register, handleSubmit, errors, reset } = useForm();

  const onSubmit = (data) => {
    fetch(API.services.update(id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good", "Service modifié", "success");
      })
      .catch((e) => {
        return swal(
          "Failed",
          "Something went wrong! check the server ",
          "error"
        );
      });
  };

  useEffect(() => {
    const fetchdata = async () => {
      fetch(API.services.info(id), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }
          setData(response);
        })
        .catch((e) => {});
    };
    fetchdata();
  }, []);

  return (
    <Container
      name="Modifier Trismestre"
      back={true}
      backRoute="/services"
      submit={true}
    >
      <ServicesForm
        registerRequired={register({ required: true })}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        data={data}
      />
    </Container>
  );
};

export default EditServices;
