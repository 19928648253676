import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import swal from "sweetalert";
import moment from "moment";
import { API } from "../../constants/env";
function ActionForm(props) {
  const [actionType, setActionType] = useState([]);
  const [selectedActionType, setSelectedActionType] = useState([]);
  const [classe, setClasse] = useState([]);
  const [show, setShow] = useState(false);
  const [period, setPeriod] = useState(false);

  useEffect(() => {
    if (props.data) {
      if (props.data.actionType.perClasse) {
        setShow(true);
        if (props.data.listClasse.length > 0) {
          let response = props.data.listClasse.map(({ _id, name }) => ({
            value: _id,
            label: name,
          }));
          setSelectedActionType(response);
        }
      }

      if (props.data.period) {
        setPeriod(props.data.period);
      }
    }
  }, [props.data]);

  useEffect(() => {
    const fetchData = () => {
      fetch(API.actionTypes.all, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }
          response = response.map(({ _id, title, perClasse }) => ({
            value: _id,
            option: title,
            perClasse: perClasse,
          }));
          setActionType(response);
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = () => {
      fetch(API.classes.all, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }
          response = response.map(({ _id, name }) => ({
            value: _id,
            label: name,
          }));
          setClasse(response);
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);

  return (
    <div className="card card-custom">
      {/*begin::Form*/}
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          <div className="form-group">
            <label>
              Type d'événement <span className="text-danger">*</span>
            </label>
            <select
              className="form-control"
              name="actionType"
              ref={props.registerRequired}
              onChange={(e) => {
                const findedActionType = actionType.find(
                  (elem) => elem.value === e.target.value
                );
                if (findedActionType.perClasse) {
                  setShow(true);
                } else {
                  setShow(false);
                }
              }}
            >
              {!props.data?.actionType && (
                <option value="" disabled selected hidden>
                  Selectionnez le type d'événement...
                </option>
              )}
              {actionType.map(({ option, value }, index) => {
                if (value === props.data?.actionType?._id) {
                  return (
                    <option key={index} value={value} selected>
                      {option}
                    </option>
                  );
                } else {
                  return (
                    <option key={index} value={value}>
                      {option}
                    </option>
                  );
                }
              })}
            </select>
            {props.errors.actionType && (
              <span className="text-danger">type d'evenement est requis</span>
            )}
          </div>
          <div className="form-group">
            <label>
              Titre <span className="text-danger">*</span>
            </label>
            <input
              ref={props.registerRequired}
              name="description"
              type="text"
              className="form-control"
              placeholder="Enter un Titre"
              defaultValue={props.data ? props.data.description : ""}
            />
            {props.errors.description && (
              <span className="text-danger">le titre est requis</span>
            )}
          </div>
          <div className="row">
            <div className="form-group col-2">
              <label>Par period ?</label>
              <input
                onChange={(e) => setPeriod(e.currentTarget.checked)}
                ref={props.register}
                name="period"
                type="checkbox"
                style={{ width: 30, height: 30, marginLeft: 30 }}
                className="form-control"
                defaultChecked={props.data?.period}
              />
            </div>
            <div className={`form-group ${period ? "col-5" : "col-10"}`}>
              <label>
                {period ? "Start" : "Date"}
                <span className="text-danger">*</span>
              </label>
              <input
                ref={props.registerRequired}
                name="startDate"
                type="date"
                className="form-control"
                placeholder="Entrer un date"
                defaultValue={
                  props.data
                    ? moment(props.data.startDate).format("yyyy-MM-DD")
                    : ""
                }
              />
              {props.errors?.startDate && (
                <span className="text-danger">
                  {period ? "Start" : "Date"} est requis
                </span>
              )}
            </div>
            {period && (
              <div className="form-group col-5">
                <label>
                  End
                  <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="endDate"
                  type="date"
                  className="form-control"
                  placeholder="Entrer un date"
                  defaultValue={
                    props.data
                      ? moment(props.data.endDate).format("yyyy-MM-DD")
                      : ""
                  }
                />
                {props.errors?.endDate && (
                  <span className="text-danger">End est requis</span>
                )}
              </div>
            )}
          </div>
          {show && (
            <div className="form-group">
              <label>
                Les classes <span className="text-danger">*</span>
              </label>
              {/* Select-Form with react-hook-form */}
              <Controller
                control={props.control}
                as={
                  <Select
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                }
                options={classe}
                name="listClasse"
                isClearable
                errors={props.errors.actionTypes}
                defaultValue={selectedActionType}
              />
              {props.errors.actionTypes && (
                <span className="text-danger">les classes est requise</span>
              )}
            </div>
          )}

          <div className="card-footer">
            <button type="submit" className="btn btn-primary mr-2">
              Submit
            </button>
            <button
              type="reset"
              onClick={() => props.reset()}
              className="btn btn-secondary"
            >
              <i className="fa fa-redo"></i>reset
            </button>
          </div>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default ActionForm;
