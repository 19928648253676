import React from "react";
import Container from "../../components/Container/Container";
import Table from "../../components/Table/Table";
import { API, STATIC, ROUTER } from "../../constants/env";
import swal from "sweetalert";

const ListCantine = (props) => {
  // fields for the list cantine page
  const fields = [
    { title: "Jour", field: "day" },
    { title: "Déjeuner", field: "meal" },
  ];
  const [data, setData] = React.useState([]);

  // get the day of the week
  const getDay = (day) => {
    return STATIC.DAYS[day];
  };

  // get mealt from meal dishes
  const getMealt = (meal) => {
    return meal.reduce((elem, str) => (str += " - " + elem), "").slice(0, -2);
  };

  const fetchData = (currentPage, count, search, setTotalPage, setTotal) => {
    fetch(
      API.cantine.menuList +
        `?page=${currentPage}&count=${count}&searchQuery=${search}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        const result = response.data.map((elem) => ({
          day: getDay(elem.day),
          _id: elem._id,
          meal: getMealt(elem.meal),
        }));
        setData(result);
        setTotalPage(response.totalPage);
        setTotal(response.total);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };

  return (
    <Container
      name="Cantine"
      actionRoute={ROUTER.CANTINE.CREATE}
      actionName="Ajouter un Déjeuner"
    >
      <Table
        pageName="contine"
        name="Menu"
        fields={fields}
        data={data}
        editRoute={(id) => ROUTER.CANTINE.EDIT(id)}
        deleteApiRoute={API.cantine.remove}
        deleteMultiple={API.cantine.deleteMultiple}
        fetchData={fetchData}
        showSearch={false}
      />
    </Container>
  );
};

export default ListCantine;
