import React, { useState } from "react";
import swal from "sweetalert";

import { useForm } from "react-hook-form";
import ParentForm from "../../components/Parent/ParentForm";
import Container from "../../components/Container/Container";
import { API, ROUTER } from "../../constants/env";
import { useParams } from "react-router-dom";

function EditParent() {
  let { id } = useParams();

  const { register, handleSubmit, errors, reset } = useForm();
  const [data, setData] = useState({});
  React.useEffect(() => {
    const fetchdata = async () => {
      fetch(API.parents.info(id), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }

          setData(response);
        })
        .catch((e) => {});
    };
    fetchdata();
  }, [id]);
  const onSubmit = (data) => {
    let fd = new FormData();
    if (data.file.length > 0) {
      fd.append("file", data.file[0]);
    }
    fd.append("email", data.email);
    fd.append("firstName", data.firstName);
    fd.append("lastName", data.lastName);
    fd.append("userName", data.userName);
    fd.append("password", data.password);
    fd.append("adress", data.adress);
    fd.append("phone", data.phone);
    fd.append("sexe", data.sexe);
    fd.append("country", data.country);
    fd.append("numCin", data.numCin);
    fd.append("profession", data.profession);
    fetch(API.parents.update(id), {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      body: fd,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good job!", "parent modifie", "success");
      })
      .catch((e) => {});
  };
  return (
    <Container
      name="Modifier un parent "
      back={true}
      backRoute={ROUTER.PARENTS.LIST}
      submit={true}
    >
      <ParentForm
        registerRequired={register({ required: false })}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        data={data}
      />
    </Container>
  );
}

export default EditParent;
