import React, { useState, useEffect } from "react";
import { API, STATIC } from "../../constants/env";
import swal from "sweetalert";
import Select from "react-select";
import { Controller } from "react-hook-form";

function AddStudentForm(props) {
  const [imgData, setImgData] = useState(null);
  const [parents, setParents] = useState([]);
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    const fetchClasseData = () => {
      fetch(API.classes.all, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }
          response = response.map(({ _id, name, level }) => ({
            value: _id,
            label: name + ":" + level,
          }));
          setClasses(response);
        })
        .catch((e) => {
          return swal("Error", e.message, "error");
        });
    };
    fetchClasseData();
  }, []);
  useEffect(() => {
    const fetchParentData = () => {
      fetch(API.parents.all, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }
          response = response.map(({ _id, profil }) => ({
            value: _id,
            label: profil.firstName + " " + profil.lastName,
          }));
          setParents(response);
        })
        .catch((e) => {
          return swal("Error", e.message, "error");
        });
    };
    fetchParentData();
  }, []);
  useEffect(() => {
    if (props.data) {
      if (props.data.classe) {
        props.setValue("classe", {
          value: props.data.classe._id,
          label: `${props.data.classe.name}:${props.data.classe.level}`,
        });
      }
      if (props.data.parent) {
        props.setValue(
          "parent",
          props.data?.parent?.map(({ _id, profil }) => ({
            value: _id,
            label: profil.firstName + " " + profil.lastName,
          }))
        );
      }
    }
  }, [props]);
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  //console.log(props.data);

  return (
    <div className="card card-custom">
      {/*begin::Form*/}
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          {/* START Prénom + Nom */}
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label>
                  Prénom <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.register}
                  name="firstName"
                  type="text"
                  className="form-control"
                  placeholder="Enter le Prénom"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.firstName
                        : ""
                      : ""
                  }
                />
                {props.errors.firstName && (
                  <span className="text-danger">
                    le Prénom d'élève est requis
                  </span>
                )}
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>
                  Nom <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.register}
                  name="lastName"
                  type="text"
                  className="form-control"
                  placeholder="Enter le Nom"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.lastName
                        : ""
                      : ""
                  }
                />
                {props.errors.lastName && (
                  <span className="text-danger">Le Nom d'élève est requis</span>
                )}
              </div>
            </div>

            {/* END Prénom + Nom */}
            {/* START Email + Username + Mot de passe */}

            {/* <div className="col-5">
              <div className="form-group">
                <label>
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.register}
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Enter l'addresse Email"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.email
                        : ""
                      : ""
                  }
                />
                {props.errors.email && (
                  <span className="text-danger">
                    l'addresse Email est requis
                  </span>
                )}
              </div>
            </div> */}
            <div className="col-4">
              <div className="form-group">
                <label>
                  Username <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.register}
                  name="userName"
                  type="text"
                  className="form-control"
                  placeholder="Enter le Username"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.userName
                        : ""
                      : ""
                  }
                />
                {props.errors.userName && (
                  <span className="text-danger">le Username est requis</span>
                )}
              </div>
            </div>

            {props.data && (
              <div className="col-6">
                <div className="form-group">
                  <label>
                    Matricule <span className="text-danger">*</span>
                  </label>
                  <input
                    ref={props.register}
                    name="matricule"
                    type="phone"
                    className="form-control"
                    defaultValue={props.data?.matricule}
                    disabled
                  />
                  {props.errors.matricule && (
                    <span className="text-danger">la matricule est requis</span>
                  )}
                </div>
              </div>
            )}
            {/*props.data && (
              <React.Fragment>
                <div className="col-6">
                  <div className="form-group">
                    <label>
                      Matricule <span className="text-danger">*</span>
                    </label>
                    <input
                      ref={props.register}
                      name="matricule"
                      type="phone"
                      className="form-control"
                      defaultValue={props.data?.matricule}
                    />
                    {props.errors.matricule && (
                      <span className="text-danger">
                        la matricule est requis
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <label>
                      Mot de passe <span className="text-danger">*</span>
                    </label>
                    <input
                      ref={props.register}
                      name="password"
                      type="password"
                      className="form-control"
                      placeholder="Enter le mot de passe"
                    />
                    {props.errors.password && (
                      <span className="text-danger">
                        le mot de passe est requis
                      </span>
                    )}
                  </div>
                </div>
              </React.Fragment>
                    )*/}
          </div>
          {/* END Email + Username + Mot de passe */}

          {/* START Address */}
          {/* <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label>
                  Addresse <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.register}
                  name="adress"
                  type="text"
                  className="form-control"
                  placeholder="Enter l'Addresse"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.adress.adress
                        : ""
                      : ""
                  }
                />
                {props.errors.adress && (
                  <span className="text-danger">l'Addresse est requis</span>
                )}
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>
                  Ville <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.register}
                  name="city"
                  type="text"
                  className="form-control"
                  placeholder="Enter le nom de la ville"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.adress.city
                        : ""
                      : ""
                  }
                />
                {props.errors.city && (
                  <span className="text-danger">
                    le nom de la ville est requis
                  </span>
                )}
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>
                  Code Postal <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.register}
                  name="zipCode"
                  type="number"
                  className="form-control"
                  placeholder="Enter le code postal"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.adress.zipCode
                        : ""
                      : ""
                  }
                />
                {props.errors.zipCode && (
                  <span className="text-danger">le code postal est requis</span>
                )}
              </div>
            </div>
          </div> */}
          {/* END Address */}
          {/* START Tel + Sexe + Pay d'origine */}
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>
                  Tél. <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.register}
                  name="phone"
                  type="phone"
                  className="form-control"
                  placeholder="Enter le numero de télephone"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.phone
                        : ""
                      : ""
                  }
                />
                {props.errors.phone && (
                  <span className="text-danger">
                    le numero de télephone est requis
                  </span>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>
                  Le sexe <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  ref={props.register}
                  name="sexe"
                >
                  {["Mâle", "Femelle"].map((elem) => {
                    if (props.data) {
                      if (elem === props.data?.profil.sexe) {
                        return (
                          <option selected value={elem}>
                            {elem}
                          </option>
                        );
                      } else {
                        return <option value={elem}>{elem}</option>;
                      }
                    } else {
                      return <option value={elem}>{elem}</option>;
                    }
                  })}
                </select>
                {/*
                <select class="form-control" ref={props.register} name="sexe">
                  <option selected>Mâle</option>
                  <option>Femelle</option>
                </select>
                */}

                {props.errors.sexe && (
                  <span className="text-danger">Le sexe est requis</span>
                )}
              </div>
            </div>
            <div className="col-6">
              {/* <div className="form-group">
                <label>
                  Pays d'origine <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.register}
                  name="country"
                  type="text"
                  className="form-control"
                  placeholder="Enter la Pays d'origine"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.country
                        : ""
                      : "Tunisie"
                  }
                />
                {props.errors.country && (
                  <span className="text-danger">
                    la Pays d'origine est requis
                  </span>
                )}
              </div> */}
              <div className="form-group">
                <label>
                  Adresse <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.register}
                  name="adress"
                  type="text"
                  className="form-control"
                  placeholder="Enter l'Adresse"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.adress
                        : ""
                      : ""
                  }
                />
                {props.errors.adress && (
                  <span className="text-danger">l'Adresse est requis</span>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>
                  Date de Naissance <span className="text-danger">*</span>
                </label>

                <input
                  ref={props.register}
                  name="birthDate"
                  type="date"
                  data-date-format="DD-MMMM-YYYY"
                  className="form-control"
                  placeholder="Enter la Date de Naissance"
                  defaultValue={props.data?.birthDate}
                />
                {props.errors.birthDate && (
                  <span className="text-danger">
                    la Date de Naissance est requis
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* END Tel + Sexe + Pay d'origine  */}
          {/* START dateNais + photo de profile  */}
          <div className="row">
            <div className="col-12">
              {/*

  <div className="form-group">
                <label>
                  La photo de profile <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.register}
                  name="file"
                  type="file"
                  className="form-control-file"
                  placeholder="Enter le nombre de places"
                />
                {props.errors.file && (
                  <span className="text-danger">
                    La photo de profile est requis
                  </span>
                )}
              </div>
                */}
              {imgData !== null ? (
                <img className="image-wrapper" src={imgData} alt="img" />
              ) : (
                props.data && (
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="image-wrapper"
                        style={{
                          backgroundImage: `url(${STATIC.ROUTE(
                            props.data
                              ? props.data.profil
                                ? props.data.profil.picture
                                : ""
                              : ""
                          )})`,
                        }}
                      ></div>
                    </div>
                  </div>
                )
              )}

              <div className="form-group">
                <label>
                  La photo de profil <span className="text-danger">*</span>
                </label>
                <div />
                <div className="custom-file">
                  <input
                    ref={props.register}
                    type="file"
                    className="custom-file-input"
                    name="file"
                    style={{ opacity: 0 }}
                    onChange={onChangePicture}
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    Choose file
                  </label>
                </div>
                {props.errors.file && (
                  <span className="text-danger">
                    La photo de profil est requis
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* END dateNais + photo de profile  */}
          {/* START Parent + Classe  */}
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>
                  Le Parent <span className="text-danger">*</span>
                </label>
                {/* Select-Form with react-hook-form */}

                <Controller
                  control={props.control}
                  as={
                    <Select
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isMulti
                    />
                  }
                  options={parents}
                  onChange={([selected]) => {
                    // React Select return object instead of value for selection

                    return { value: selected };
                  }}
                  name="parent"
                  isClearable
                  isMulti
                  rules={{ required: true }}
                  errors={props.errors.parent}
                />
                {/* <input
                ref={props.register}
                name="parent"
                type="text"
                className="form-control"
                placeholder="Enter le parent"
                value="5f39683bee58c44dcadf914d" //TODO: will be Removed later
                /> */}
                {props.errors.parent && (
                  <span className="text-danger">le parent est requis</span>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>
                  La classe <span className="text-danger">*</span>
                </label>
                {/* Select-Form with react-hook-form */}
                <Controller
                  control={props.control}
                  as={
                    <Select
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  }
                  options={classes}
                  name="classe"
                  isClearable
                  rules={{ required: true }}
                  errors={props.errors.classe}
                />
                {/* <input
                ref={props.register}
                name="classe"
                type="text"
                className="form-control"
                placeholder="Enter la classe"
                value="5f396806ee58c44dcadf914b" //TODO: will be Removed later
                /> */}
                {props.errors.classe && (
                  <span className="text-danger">La classe est requise</span>
                )}
              </div>
            </div>
          </div>
          {/* END Parent + Classe  */}

          <div className="card-footer">
            <button type="submit" className="btn btn-primary mr-2">
              Valider
            </button>
            <button
              type="reset"
              onClick={() => props.reset()}
              className="btn btn-secondary"
            >
              <i className="fa fa-redo"></i>reset
            </button>
          </div>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default AddStudentForm;
