import React, { useState, useEffect } from "react";
import swal from "sweetalert";

import { useForm } from "react-hook-form";
import StudentForm from "../../components/Student/StudentForm";
import Container from "../../components/Container/Container";
import { API, ROUTER } from "../../constants/env";
import { useParams } from "react-router-dom";

function EditStudent() {
  let { id } = useParams();

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    setValue,
  } = useForm();
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchdata = async () => {
      fetch(API.students.info(id), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }

          const date = new Date(response.birthDate);

          response.birthDate =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1) +
            "-" +
            (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());

          setData(response);
        })
        .catch((e) => {
          return swal("Error", e.message, "error");
        });
    };
    fetchdata();
  }, [id]);
  const onSubmit = (data) => {
    const parents = [];
    data.parent.forEach((singleParen) => parents.push(singleParen.value));
    data.classe = data.classe.value;
    const fd = new FormData();
    fd.append("file", data.file[0]);
    fd.append("email", data.email);
    fd.append("classe", data.classe);
    fd.append("parent", parents);
    fd.append("firstName", data.firstName);
    fd.append("lastName", data.lastName);
    fd.append("userName", data.userName);
    fd.append("password", data.password);
    fd.append("adress", data.adress);
    fd.append("phone", data.phone);
    fd.append("sexe", data.sexe);
    fd.append("country", data.country);
    fd.append("birthDate", data.birthDate);

    fetch(API.students.update(id), {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      body: fd,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good job!", "eleve modifie", "success");
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };
  return (
    <Container
      name="Modifier un eleve "
      back={true}
      backRoute={ROUTER.STUDENTS.LIST}
      submit={true}
    >
      <StudentForm
        register={register({ required: false })}
        registerRequired={register({ required: true })}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        control={control}
        data={data}
        setValue={setValue}
      />
    </Container>
  );
}

export default EditStudent;
