import React from "react";
import { STATIC } from "../../constants/env";

const FraisForm = (props) => {
  return (
    <div className="card card-custom">
      {/*begin::Form*/}
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          <div className="form-group">
            <label>
              Nom de frais
              <span className="text-danger"> *</span>
            </label>
            <input
              ref={props.registerRequired}
              name="name"
              type="text"
              className="form-control col"
              placeholder="Enter un nom"
              defaultValue={props.data?.name}
            />
            {props.errors?.name && (
              <span className="text-danger">Nom de frais est requis </span>
            )}
          </div>
          <div className="form-group">
            <label>
              Prix de frais (TND)
              <span className="text-danger"> *</span>
            </label>
            <input
              step="0.001"
              ref={props.registerRequired}
              name="price"
              type="number"
              className="form-control col"
              placeholder="Enter un prix"
              defaultValue={props.data?.price}
            />
            {props.errors?.price && (
              <span className="text-danger">Prix de frais est requis</span>
            )}
          </div>
          <div className="form-group">
            <label>
              Selectionnez le periode
              <span className="text-danger"> *</span>
            </label>

            <select
              className="form-control"
              ref={props.register}
              name="period"
              placeholder="Selectionnez le periode"
            >
              {STATIC.PERIODE().map(({ option, value }, index) => {
                if (props.data) {
                  if (value === props.data.period) {
                    return (
                      <option selected value={value} key={index}>
                        {option}
                      </option>
                    );
                  } else {
                    return (
                      <option value={value} key={index}>
                        {option}
                      </option>
                    );
                  }
                } else {
                  return (
                    <option value={value} key={index}>
                      {option}
                    </option>
                  );
                }
              })}
            </select>
          </div>
        </div>
        <div className="card-footer">
          <button type="submit" className="btn btn-primary mr-2">
            Valider
          </button>
          <button
            type="reset"
            onClick={() => props.reset()}
            className="btn btn-secondary"
          >
            <i className="fa fa-redo"></i>reset
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
};

export default FraisForm;
