import React, { useState } from "react";
import swal from "sweetalert";
import { API, SERVER_URL } from "../../constants/env";

const Pdf = () => {
  const [loading, setLoading] = useState(false);
  const generatePdf = () => {
    const paths = window.location.pathname.split("/");

    setLoading(true);
    fetch(
      paths.length <= 5
        ? API.generatePdf.student(paths[3])
        : API.generatePdf.prepa(
            paths[3],
            parseInt(paths[paths.length - 1].charAt(0)) - 1
          ),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        //  console.log("localhost:5000" + response.reportCardPdf);
        window.open(SERVER_URL + response.reportCardPdf.substring(1), "_blank");
      })
      .catch((e) => {
        swal("Error", e.message, "error");
      })
      .finally(() => setLoading(false));
  };

  return (
    <button
      className="btn btn-light ml-2"
      onClick={() => generatePdf()}
      disabled={loading}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g color="#fff">
          <path d="M27.844 11.252c-0.101-4.022-3.389-7.252-7.433-7.252-2.369 0-4.477 1.109-5.839 2.835-0.764-0.987-1.959-1.624-3.303-1.624-2.307 0-4.176 1.871-4.176 4.179 0 0.201 0.015 0.399 0.043 0.592-0.351-0.063-0.711-0.098-1.080-0.098-3.344-0-6.054 2.712-6.054 6.058s2.71 6.058 6.054 6.058h2.868l7.078 7.328 7.078-7.328 3.484-0c3.004-0.006 5.438-2.444 5.438-5.451 0-2.565-1.771-4.716-4.156-5.296zM16 26l-6-6h4v-6h4v6h4l-6 6z"></path>
        </g>
      </svg>
    </button>
  );
};

export default Pdf;
