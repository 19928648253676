/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import swal from "sweetalert";
import { API, STATIC } from "../../constants/env";
import { Button, Modal, Alert, Card, Table } from "react-bootstrap";
function ModalSeeMore({ id }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [data, setData] = useState({});
  const fields = [
    { title: "Photo", field: "picture" },
    { title: "Prénom", field: "firstName" },
    { title: "Nom", field: "lastName" },
    { title: "Email", field: "email" },
    { title: "Username", field: "username" },
    { title: "Mot de passe", field: "password" },
    { title: "Tél.", field: "phone" },
    { title: "Adresse", field: "adress" },
    { title: "Sexe", field: "sexe" },
    { title: "CIN", field: "numCin" },
    { title: "Les Matiéres", field: "subjects" },
  ];
  const fetchData = () => {
    fetch(API.teachers.info(id), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        response = {
          picture: (
            <img
              src={STATIC.ROUTE(response.profil.picture)}
              className="rounded imgWidthThumbnail"
              alt="teacher_pic"
            />
          ),
          firstName: response.profil.firstName,
          lastName: response.profil.lastName,
          email: response.email,
          username: response.profil.userName,
          phone: response.profil.phone,
          adress: response.profil.adress,
          sexe: response.profil.sexe,
          password: response.profil.password,
          //country:response.profil.country,
          numCin: response.numCin,
          subjects: response.subjects,
        };

        console.log("All Teacher Data ", response);
        setData(response);
      })
      .catch((e) => {});
  };
  // End Fetch
  const handleDataShow = () => {
    handleShow();
    fetchData();
  };
  const RenderShowScreen = (data, fields) => {
    if (data) {
      let result = (
        <>
          <Modal.Header style={{ "align-self": "center" }}>
            <Modal.Title id="contained-modal-title-vcenter">
              {data.sexe === "femme"
                ? `Madame ${data.firstName} ${data.lastName}`
                : `Monsieur ${data.firstName} ${data.lastName}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body centered>
            <Card>
              <Table striped bordered hover>
                <tbody>
                  {fields.map(({ title, field }) => {
                    if (field === "subjects" && data[field]) {
                      return (
                        <tr>
                          <td>
                            <b>{title} :</b>
                          </td>
                          <td className="text-center">
                            {data[field].map((elem) => {
                              return (
                                <span>{` ${elem.name} ${elem.level} ,`}</span>
                              );
                            })}
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr>
                          <td>
                            <b>{title} :</b>
                          </td>
                          <td className="text-center">{data[field]}</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
            </Card>
          </Modal.Body>
        </>
      );
      return result;
    } else {
      return (
        <>
          <Modal.Body>
            <Alert variant="danger">
              Désolé !!! Pas d'informations en ce moment sur cette personne
            </Alert>
          </Modal.Body>
        </>
      );
    }
  };
  return (
    <>
      <Button variant="btn btn-primary mr-2" onClick={handleDataShow}>
        {`Voir Plus`}
      </Button>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        {RenderShowScreen(data, fields)}

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalSeeMore;
