import Logout from "../components/Logout/Logout";

import StudentPay from "../pages/Payment/Student_payment/StudentPay";
import Bill from "../pages/Payment/Single_Bill/Bill";

import withAuth from "../libs/withAuth";

import Student from "../pages/Emploi/Student";
import Teacher from "../pages/Emploi/Teacher";

import ListUsers from "../pages/Users/ListUsers";
import AddUsers from "../pages/Users/AddUser";
import EditUsers from "../pages/Users/EditUser";

import Salles from "../pages/Salles/ListSalles";
import EditSalle from "../pages/Salles/EditSalle";
import AddSalle from "../pages/Salles/AddSalle";

import Matiere from "../pages/Subjects/ListSubjects";
import EditMatiere from "../pages/Subjects/EditSubject";
import AddMatiere from "../pages/Subjects/AddSubject";

import ListStudents from "../pages/Students/ListStudents";
import EditStudent from "../pages/Students/EditStudent";
import AddStudent from "../pages/Students/AddStudent";

import ListTeacher from "../pages/Teachers/ListTeachers";
import EditTeacher from "../pages/Teachers/EditTeacher";
import AddTeacher from "../pages/Teachers/AddTeacher";

import ListParent from "../pages/Parents/ListParents";
import EditParent from "../pages/Parents/EditParent";
import AddParent from "../pages/Parents/AddParent";

import ListClasses from "../pages/Classes/ListClasses";
import AddClasses from "../pages/Classes/AddClasse";
import EditClasses from "../pages/Classes/EditClasse";
import NotFound from "../components/NotFound/NotFound";

import ListCantine from "../pages/Cantine/ListCantine";
import AddCantine from "../pages/Cantine/AddCantine";
import EditCantine from "../pages/Cantine/EditCantine";

import ListServices from "../pages/Payment/Services/ListServices";
import AddServices from "../pages/Payment/Services/AddServices";
import EditServices from "../pages/Payment/Services/EditServices";

import ListClub from "../pages/Payment/Club/ListClub";
import AddClub from "../pages/Payment/Club/AddClub";
import EditClub from "../pages/Payment/Club/EditClub";

import ListFrais from "../pages/Payment/Frais/ListFrais";
import AddFrais from "../pages/Payment/Frais/AddFrais";
import EditFrais from "../pages/Payment/Frais/EditFrais";

import ActionType from "../pages/EventsActions/types/ActionTypes";
import AddType from "../pages/EventsActions/types/AddType";
import EditType from "../pages/EventsActions/types/EditType";

import ListAction from "../pages/EventsActions/Actions/ListActions";
import AddAction from "../pages/EventsActions/Actions/AddAction";
import EditAction from "../pages/EventsActions/Actions/EditAction";

import ListAbsences from "../pages/Absences/ListAbsence";
import EditAbsence from "../pages/Absences/EditAbsence";
import AddAbsence from "../pages/Absences/AddAbsence";

import ListNotif from "../pages/Notifications/ListNotif";
import AddNotif from "../pages/Notifications/AddNotif";
import EditNotif from "../pages/Notifications/EditNotif";
import ArchiveNotification from "../pages/Notifications/ArchiveNotification";

import ListExam from "../pages/Exams/ListExams";
import AddExam from "../pages/Exams/AddExam";
import EditExam from "../pages/Exams/EditExam";

import ListModule from "../pages/Modules/ListModule";
import AddModule from "../pages/Modules/AddModule";
import EditModule from "../pages/Modules/EditModule";

import ListCarnet from "../pages/Carnet/Student/ListCarnet";
import CarnetStudent from "../pages/Carnet/Student/CarnetStudent";

import ListCarnetPrepa from "../pages/Carnet/Prepa/ListCarnetPrepra";
import CarnetPrepa from "../pages/Carnet/Prepa/CarnetPrepa";
import Settings from "../pages/Settings/Settings";

export const routes = [
  {
    path: "/",
    exact: true,
    main: withAuth(ListStudents),
  },
  {
    //===========>>> Les Students  ListStudents/EditStudent/AddStudent
    path: "/students",
    exact: true,
    main: withAuth(ListStudents),
  },
  {
    path: "/student/new",
    exact: true,
    main: withAuth(AddStudent),
  },
  {
    path: "/student/edit/:id",
    main: withAuth(EditStudent),
  },
  {
    //===========>>> Les Parents  ListTeacher/EditTeacher/AddTeacher
    path: "/parents",
    exact: true,
    main: withAuth(ListParent),
  },
  {
    path: "/parent/new",
    exact: true,
    main: withAuth(AddParent),
  },
  {
    path: "/parent/edit/:id",
    exact: true,
    main: withAuth(EditParent),
  },
  {
    //===========>>> Les classes
    path: "/classes",
    exact: true,
    main: withAuth(ListClasses),
  },
  {
    path: "/classe/new",
    main: withAuth(AddClasses),
  },
  {
    path: "/classe/edit/:id",
    main: withAuth(EditClasses),
  },
  {
    //===========>>> Les Salles
    path: "/salles",
    exact: true,
    main: withAuth(Salles),
  },
  {
    path: "/salle/new",
    exact: true,
    main: withAuth(AddSalle),
  },
  {
    path: "/salle/edit/:id",
    exact: true,
    main: withAuth(EditSalle),
  },
  // ==========> Notifications
  { path: "/notification", exact: true, main: withAuth(ListNotif) },
  {
    path: "/notification/new",
    exact: true,
    main: withAuth(AddNotif),
  },
  {
    path: "/notification/edit/:id",
    exact: true,
    main: withAuth(EditNotif),
  },
  {
    path: "/archive-notification",
    exact: true,
    main: withAuth(ArchiveNotification),
  },
  //===========>>> Les Matieres
  {
    path: "/subjects",
    exact: true,
    main: withAuth(Matiere),
  },
  {
    path: "/subject/new",
    exact: true,
    main: withAuth(AddMatiere),
  },
  {
    path: "/subject/edit/:id",
    exact: true,
    main: withAuth(EditMatiere),
  },
  {
    //===========>>> Les Enseignants  ListTeacher/EditTeacher/AddTeacher
    path: "/teachers",
    exact: true,
    main: withAuth(ListTeacher),
  },
  {
    path: "/teacher/new",
    exact: true,
    main: withAuth(AddTeacher),
  },
  {
    path: "/teacher/edit/:id",
    exact: true,
    main: withAuth(EditTeacher),
  },
  {
    path: "/404",
    main: withAuth(NotFound),
  },

  // =====> Emploi
  {
    path: "/emploi/student",
    main: withAuth(Student),
  },
  {
    path: "/emploi/teacher",
    main: withAuth(Teacher),
  },
  // =============> Cantine
  {
    path: "/cantine",
    exact: true,
    main: withAuth(ListCantine),
  },

  {
    path: "/cantine/new",
    main: withAuth(AddCantine),
  },
  {
    path: "/cantine/edit/:id",
    main: withAuth(EditCantine),
  },

  // =============> Services
  {
    path: "/services",
    exact: true,
    main: withAuth(ListServices),
  },

  {
    path: "/services/new",
    main: withAuth(AddServices),
  },
  {
    path: "/services/edit/:id",
    main: withAuth(EditServices),
  },

  // ============> Club
  {
    path: "/club",
    exact: true,
    main: withAuth(ListClub),
  },

  {
    path: "/club/new",
    main: withAuth(AddClub),
  },
  {
    path: "/club/edit/:id",
    main: withAuth(EditClub),
  },

  // =============> Frais

  {
    path: "/fees",
    exact: true,
    main: withAuth(ListFrais),
  },

  {
    path: "/fees/new",
    main: withAuth(AddFrais),
  },
  {
    path: "/fees/edit/:id",
    main: withAuth(EditFrais),
  },
  // ============> Student pay TODO later

  {
    path: "/frais/edit/:id",
    main: withAuth(EditFrais),
  },
  //=============> Payement

  {
    path: "/payment",
    main: withAuth(StudentPay),
    exact: true,
  },

  {
    path: "/payment/st/:id",
    main: withAuth(Bill),
  },

  // ===========> Absences/P

  {
    path: "/absences",
    exact: true,
    main: withAuth(ListAbsences),
  },
  {
    path: "/absences/new",
    main: withAuth(AddAbsence),
  },
  {
    path: "/absences/edit/:id",
    main: withAuth(EditAbsence),
  },
  ///===========> actionTypes
  {
    path: "/actionType",
    exact: true,
    main: withAuth(ActionType),
  },

  {
    path: "/actionType/new",
    main: withAuth(AddType),
  },
  {
    path: "/actionType/edit/:id",
    main: withAuth(EditType),
  },

  // ============> Exams

  {
    path: "/examens",
    exact: true,
    main: withAuth(ListExam),
  },

  {
    path: "/examens/new",
    main: withAuth(AddExam),
  },
  {
    path: "/examens/edit/:id",
    main: withAuth(EditExam),
  },
  // ============> Modules

  {
    path: "/modules",
    exact: true,
    main: withAuth(ListModule),
  },

  {
    path: "/modules/new",
    main: withAuth(AddModule),
  },
  {
    path: "/modules/edit/:id",
    main: withAuth(EditModule),
  },

  // ============> Carnet Prepa

  {
    path: "/carnetPrepa",
    main: withAuth(ListCarnetPrepa),
    exact: true,
  },

  {
    path: "/carnetPrepa/st/:id/:trimestre/:trimstereNumber",
    main: withAuth(CarnetPrepa),
  },

  // ============> Carnet de note
  {
    path: "/carnets",
    exact: true,
    main: withAuth(ListCarnet),
  },

  {
    path: "/carnets/st/:id/:trimestre",
    main: withAuth(CarnetStudent),
  },

  // =============> Settings
  {
    path: "/settings",
    exact: true,
    main: withAuth(Settings),
  },

  ///===========> actions
  {
    path: "/event",
    exact: true,
    main: withAuth(ListAction),
  },

  {
    path: "/event/new",
    main: withAuth(AddAction),
  },
  {
    path: "/event/edit/:id",
    main: withAuth(EditAction),
  },
  // ===========> logout

  {
    path: "/Logout",
    main: withAuth(Logout),
  },

  // ===========> Users
  {
    path: "/users",
    exact: true,
    main: withAuth(ListUsers),
  },
  {
    path: "/user/new",
    main: withAuth(AddUsers),
  },
  {
    path: "/user/edit",
    main: withAuth(EditUsers),
  },
];
