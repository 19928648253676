import React, { useState } from "react";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import TeacherForm from "../../components/Teacher/TeacherForm";
import Container from "../../components/Container/Container";
import { API, ROUTER } from "../../constants/env";
function AddUser() {
  const { register, handleSubmit, errors, reset, control } = useForm();
  const [data, setData] = useState({});
  const onSubmit = (data) => {
    // data.adress = {
    //   adress: data.adress,
    //   city: data.city,
    //   zipCode: data.zipCode,
    // };
    // delete data.city;
    // delete data.zipCode;
    data.subjects = data.subjects.map(({ value }) => value);
    let fd = new FormData();
    if (data.file.length > 0) {
      fd.append("file", data.file[0]);
    }
    fd.append("email", data.email);
    fd.append("firstName", data.firstName);
    fd.append("lastName", data.lastName);
    fd.append("userName", data.userName);
    fd.append("password", data.password);
    fd.append("adress", data.adress);
    fd.append("phone", data.phone);
    fd.append("sexe", data.sexe);
    fd.append("country", data.country);
    fd.append("numCin", data.numCin);
    for (let i = 0; i < data.subjects.length; i++) {
      fd.append("subjects[]", data.subjects[i]);
    }
    fetch(API.teachers.create, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      body: fd,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }
        return swal("Good job!", "enseignant(e) ajouter", "success");
      })
      .catch((e) => {});
  };
  return (
    <Container
      name="Ajouter un(e) enseignant(e)"
      back={true}
      backRoute={ROUTER.TEACHERS.LIST}
      submit={true}
    >
      <TeacherForm
        registerRequired={register({ required: true })}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        control={control}
        data={data}
      />
    </Container>
  );
}

export default AddUser;
