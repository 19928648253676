/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Container from "../../components/Container/Container";
import Table from "../../components/Table/Table";
import { API } from "../../constants/env";
import swal from "sweetalert";

function ListUsers() {
  const fields = [
    { title: "email", field: "email" },
    { title: "fname", field: "fname" },
    { title: "lname", field: "lname" },
    { title: "website", field: "website" },
    { title: "address", field: "address" },
  ];
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const fetchData = () => {
      fetch(API.user.list)
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }

          setData(response);
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);

  return (
    <Container
      name="Liste utilisateur"
      actionRoute="/user/new"
      actionName="Ajouter un utilisateur"
    >
      <Table name="Utilisateurs" fields={fields} data={data} />
    </Container>
  );
}

export default ListUsers;
