import moment from "moment";
import React, { useState } from "react";
import { Button, Modal, Table, Container } from "react-bootstrap";
import BillTable from "../../../components/Bill/billTable";

const StudentModel = ({ payments, title }) => {
  const [show, setShow] = useState(false);

  return (
    <React.Fragment>
      <Button variant="btn btn-primary mr-2" onClick={() => setShow(!show)}>
        Voir plus
      </Button>
      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(!show)}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="font-weight-bolder m-5 text-center ">{title}</div>
        {payments?.length === 0 ? (
          <div>No payments</div>
        ) : (
          <Container>
            <BillTable payments={payments} />
          </Container>
        )}

        <br />
      </Modal>
    </React.Fragment>
  );
};

export default StudentModel;
