import React, { useState } from "react";
import swal from "sweetalert";
import { API, ROUTER, SERVER_URL, STATIC } from "../../constants/env";
import Table from "../../components/Table/Table";
import { Button, Modal } from "react-bootstrap";
function ModalListStudent({ name, level, id }) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [data, setData] = useState([]);
  const fields = [
    { title: "Photo d'éleve", field: "picture" },
    { title: "Prénom", field: "firstName" },
    { title: "Nom", field: "lastName" },
    { title: "Classe", field: "classe" },
    { title: "Parent", field: "parent" },
    { title: "Tél.", field: "phone" },
    { title: "Adresse", field: "adress" },
  ];
  // TODO : fetch should be work for specific classe
  // The Fetch Function
  const fetchData = (currentPage, count, search, setTotalPage, setTotal) => {
    fetch(
      API.students.list +
        `?specificClassId=${id}&page=${currentPage}&count=${count}&searchQuery=${search}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        const results = response.data.map((elem) => ({
          _id: elem._id,
          picture: (
            <img
              src={STATIC.ROUTE(elem.profil.picture)}
              alt="student_pic"
              className="rounded imgWidthThumbnail"
            />
          ),
          firstName: elem.profil.firstName,
          lastName: elem.profil.lastName,
          classe: elem.classe ? elem.classe.name : "**Non défini**",
          parent: elem.parent
            ? `${elem.parent[0].profil.firstName} ${elem.parent[0].profil.lastName}`
            : "**Non défini**",
          phone: elem.profil.phone,
          adress: `${elem.profil.adress}`,
        }));
        setData(results);
        setTotalPage(response.totalPage);
        setTotal(response.total);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };
  // End Fetche
  const handleDataShow = () => {
    handleShow();
  };
  const generatePdf = () => {
    console.log({ id });
    const paths = window.location.pathname.split("/");
    setLoading(true);
    fetch(API.generatePdf.classe(id), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        //  console.log("localhost:5000" + response.reportCardPdf);
        window.open(SERVER_URL + response.classCardPdf, "_blank");
      })
      .catch((e) => {
        swal("Error", e.message, "error");
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Button variant="btn btn-primary mr-2" onClick={() => handleDataShow()}>
        afficher la liste des élèves
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            La liste des élèves
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table
            editRoute={(id) => ROUTER.STUDENTS.EDIT(id)}
            deleteApiRoute={API.students.remove}
            deleteMultiple={API.students.deleteMultiple}
            fetchData={fetchData}
            name={`Les élèves de la classe: ${name.toUpperCase()} Niveau: ${level.toUpperCase()}`}
            fields={fields}
            data={data}
            showSearch={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={generatePdf}>
            imprimer
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalListStudent;
