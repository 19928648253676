import React, { useEffect,useState } from "react";
import Sremarque from "./Sremarque";
import { API } from "../../constants/env";
import swal from "sweetalert";

// Utility function to round the number to two decimal places
const roundN = (num) => {
  const numStr = num?.toString();
  return numStr.slice(0, numStr.indexOf(".") + 3);
};

const Smoyenne = ({
  moyenne,
  fontSizeTitle,
  title,
  fontSizeMoyenne,
  color,
  remarque,
  confirm,
  changeCarnetRemarque,
  changeModuleRemarque,
  general,
  unitInedx,
  saveCarnet,
    semestreNumberThree,
    studentId
}) => {
  const [allC, setAllC] = useState({});

  const handleInputChange = (value, name) => {
    value = parseFloat(value);
    if (isNaN(value)) value = 0;
    if (value > 20) value = 20;
    if (value < 0) value = 0;
    setAllC((prev) => ({ ...prev, [name]: value }));
  };
    const saveMoyennes = () => {
    
    fetch(API.overAllAverge.update(studentId), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        ...allC,
        trimestre: "troisieme",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        // return swal("Error", response.message, "error");
      })
      .catch((e) => {
        swal("Error", e.message, "error");
      });

   
  };

  const styles = {
    container: {
      textAlign: "center",
      margin: "0 auto",
      fontSize: "17px",
      color: color,
      fontWeight: 10,
    },
    header: {
      color: color,
      fontWeight: "bolder",
      fontSize: fontSizeTitle,
      marginBottom: 8,
    },
    moyenne: {
      textAlign: "center",
      margin: "0 auto",
      fontSize: fontSizeMoyenne,
      color: "#474444",
      fontWeight: 800,
    },
    input: {
      outline: 0,
      width: "70px",
      height: "30px",
      textAlign: "center",
    },
    inputContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap", // Ensures that elements wrap if the container is too narrow
    },
  };
  useEffect(() => {
    if (studentId) {
      console.log(studentId);
      var trimestre = localStorage.getItem("tris");

      fetch(API.overAllAverge.get(studentId), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (!response) {
            setAllC({
              moyenne: 0,
              max: 0,
              min: 0,
              tmax: 0,
              tmin: 0,
              trimestre: trimestre,
            });
          } else if (response.error) {
            return swal("Error", response.message, "error");
          } else {
            const newState = { trimestre: trimestre };

            switch (trimestre) {
              case "premier":
                newState.tmax = response.premier.max;
                newState.tmin = response.premier.min;
                break;
              case "deuxieme":
                newState.tmax = response.deuxieme.max;
                newState.tmin = response.deuxieme.min;
                break;
              case "troisieme":
                console.log(response.troisieme);
                newState.tmax = response.troisieme.max;
                newState.tmin = response.troisieme.min;
                break;
              default:
                break;
            }

            setAllC((prevState) => ({
              ...prevState,
              ...newState,
            }));
          }
        })
        .catch((e) => {
          swal("Error", e.message, "error");
        });
    }
  }, [studentId]);
  return (
    <div className="card">
      <div className="card-body">
        <header style={styles.header}>{title}</header>
        <div style={{ display: "inline-block", width: "35%", position: "relative" }}>
          <span style={styles.moyenne}>{moyenne ? roundN(moyenne) : 0}</span>
    
        </div>
        <div style={{ display: "inline-block", width: "65%" }}>
          <Sremarque
            general={general}
            unitInedx={unitInedx}
            color={color}
            remarque={remarque}
            changeCarnetRemarque={changeCarnetRemarque}
            changeModuleRemarque={changeModuleRemarque}
          />
                {semestreNumberThree && (
            <div style={styles.inputContainer}>
              <div style={{ flex: 1 }}>
                <h1 style={styles.container}>
                  Moyenne la plus élevée : <br />
                  <input
                    value={allC.tmax || ''}
                    type="number"
                    name="tmax"
                    placeholder="00.00"
                    onChange={(e) => handleInputChange(e.currentTarget.value, "tmax")}
                    style={styles.input}
                    step="0.01"
                  />
                </h1>
              </div>
              <div style={{ flex: 1 }}>
                <h1 style={styles.container}>
                  Moyenne la plus basse : <br />
                  <input
                    value={allC.tmin || ''}
                    type="number"
                    name="tmin"
                    placeholder="00.00"
                    onChange={(e) => handleInputChange(e.currentTarget.value, "tmin")}
                    style={styles.input}
                    step="0.01"
                  />
                </h1>
                          </div>
                          <button
                        className="btn btn-success btn-lg btn-block mt-5"
                        onClick={() => {
                          saveMoyennes();
                        }}
                      >
                        Confirm Moyenne {"trimestrielles" }
                      </button>
                      </div>
                 
          )}
        </div>
        {confirm && (
          <div>
            <button className="btn btn-success btn-lg btn-block mt-3" onClick={saveCarnet}>
              Confirm Carnet
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Smoyenne;
