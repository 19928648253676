import React from "react";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import ClassroomForm from "../../components/ClassRooms/ClassroomForm";
import Container from "../../components/Container/Container";
import { API } from "../../constants/env";
function AddUser() {
  const { register, handleSubmit, errors, reset } = useForm();
  const onSubmit = (data) => {
    data.placeNumber = +data.placeNumber; // convert placeNumber from string to int
    fetch(API.salle.create, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal(
          "Good job!",
          `La Classe ${response.name} est ajouté"`,
          "success"
        );
      })
      .catch((e) => {});
  };
  return (
    <Container
      name="Ajouter une salle"
      back={true}
      backRoute="/Salles"
      submit={true}
    >
      <ClassroomForm
        register={register({ required: true })}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
      />
    </Container>
  );
}

export default AddUser;
