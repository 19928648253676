import React, { useState } from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import AbsenceForm from "../../components/Absences/AbsenceForm";
import Container from "../../components/Container/Container";
import { API, ROUTER } from "../../constants/env";

const AddAbsence = () => {
  const { register, handleSubmit, errors, reset } = useForm();
  const [absensesList, setAbsencesList] = useState([]);
  const [punitionList, setPunitionList] = useState([]);

  const getRegisters = (classe, date, infos, all) => {
    return {
      all,
      registers: [
        ...absensesList.map((student) => {
          if (!infos)
            return {
              title: "Absence",
              date,
              classe,
              student,
            };
          return {
            title: "Absence",
            date,
            classe,
            student,
            subject: infos[0],
            teacher: infos[1],
          };
        }),
        ...punitionList.map((student) => {
          if (!infos)
            return {
              title: "Punition",
              date,
              classe,
              student,
            };
          return {
            title: "Punition",
            date,
            classe,
            student,
            subject: infos[0],
            teacher: infos[1],
          };
        }),
      ],
    };
  };

  const onSubmit = ({ classe, date, subject, all }) => {
    if (!absensesList.length && !punitionList.length) {
      return swal("Error", "Selectionnez un A/P", "error");
    }
    console.log(getRegisters(classe, date, subject?.split(" "), all));
    fetch(API.absences.create, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(
        getRegisters(classe, date, subject?.split(" "), all)
      ),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }

        return swal("Good job!", "Absence ajouter", "success");
      })
      .catch((e) => {
        return swal("Error fel request", e.message, "error");
      });
  };

  return (
    <Container
      name="Marquer l'absence et la punition"
      back={true}
      backRoute={ROUTER.ABSENCE.LIST}
      submit={true}
    >
      <AbsenceForm
        absences={absensesList}
        punitions={punitionList}
        setAbsences={setAbsencesList}
        setPunitions={setPunitionList}
        register={register()}
        registerRequired={register({ required: true })}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
      />
    </Container>
  );
};

export default AddAbsence;
