import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
const List = ({ students, absences, punitions, setAbsences, setPunitions }) => {
  const onChangeList = (checked, type, id) => {
    if (checked && type === "absences") {
      setAbsences([...absences, id]);
    }
    if (checked && type === "punitions") {
      setPunitions([...punitions, id]);
    }
    if (!checked && type === "absences") {
      setAbsences(absences.filter((ab) => ab !== id));
    }
    if (!checked && type === "punitions") {
      setPunitions(punitions.filter((ab) => ab !== id));
    }
  };
  return (
    <div>
      <h6 className="mb-9">Les Etudiants </h6>
      <ListGroup variant="flush">
        <div className="container">
          {students.map(({ firstName, lastName, id }, index) => (
            <ListGroup.Item action variant="light" as="div" key={index}>
              <div className="row">
                <div className="col-4">
                  <h6>
                    <span className="m-5">
                      {firstName} {lastName}
                    </span>
                  </h6>
                </div>
                <div className="col-4">
                  Absence{" "}
                  <input
                    onChange={(e) =>
                      onChangeList(e.target.checked, "absences", id)
                    }
                    type="checkbox"
                    className="m-1"
                    style={{ height: 15, width: 15 }}
                    defaultChecked={absences.includes(id)}
                  />
                </div>
                <div className="col-4">
                  Punition{" "}
                  <input
                    onChange={(e) =>
                      onChangeList(e.target.checked, "punitions", id)
                    }
                    type="checkbox"
                    className="m-1"
                    style={{ height: 15, width: 15 }}
                    defaultChecked={punitions.includes(id)}
                  />
                </div>
              </div>
            </ListGroup.Item>
          ))}
        </div>
      </ListGroup>
    </div>
  );
};

export default List;
