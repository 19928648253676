import moment from "moment";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { API } from "../../constants/env";
import List from "./List";

const AbsenceForm = (props) => {
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [researsh, setReseash] = useState({});
  const [subjects, setSubjects] = useState([]);
  const [all, setAll] = useState(false);

  useEffect(() => {
    fetch(API.classes.all, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        const results = response.map(({ _id, name }) => {
          return { option: name, value: _id };
        });
        setClasses(results);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    if (props.data) {
      setReseash({
        ...researsh,
        classe: props.data?.classe?._id,
        date: props.data?.date,
        subject: props.data?.subject?._id,
      });

      setStudents([
        {
          firstName: props.data?.student?.profil?.firstName,
          id: props.data?.student?._id,
          lastName: props.data?.student?.profil?.lastName,
        },
      ]);
    }
  }, [props.data]);

  useEffect(() => {
    if (researsh.classe && researsh.date && !props.data) {
      fetch(API.absences.getStudent(researsh.classe), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }

          const results = response.map(
            ({ _id, profil: { firstName, lastName } }) => {
              return { firstName, id: _id, lastName };
            }
          );

          setStudents(results);
        })
        .catch((e) => {});
    }
    if (researsh.classe && researsh.date) {
      fetch(API.absences.getSceance, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          classe: researsh.classe,
          day: moment(researsh.date).day(),
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }

          const results = response.map(
            ({
              subject: { _id, name },
              teacher: {
                _id: id,
                profil: { firstName, lastName },
              },
            }) => {
              return {
                value: `${_id} ${id}`,
                option: `${name} - ${firstName} ${lastName}`,
              };
            }
          );

          setSubjects(results);
        })
        .catch((e) => {});
    }
  }, [researsh]);

  return (
    <div className="card card-custom">
      {/*begin::Form*/}
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <label>
                Classe <span className="text-danger">*</span>
              </label>
              <select
                className="form-control"
                name="classe"
                ref={props.registerRequired}
                onChange={(e) => {
                  setReseash({ ...researsh, classe: e.target.value });
                }}
              >
                {!props.data?.classe && (
                  <option value="" disabled selected hidden>
                    Selectionnez une Matiere...
                  </option>
                )}
                {classes.map(({ option, value }, index) => {
                  if (value === props.data?.classe?._id) {
                    return (
                      <option key={index} value={value} selected>
                        {option}
                      </option>
                    );
                  } else {
                    return (
                      <option key={index} value={value}>
                        {option}
                      </option>
                    );
                  }
                })}
              </select>
              {props.errors.class && (
                <span className="text-danger">Classe est requis</span>
              )}
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>
                  Date <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="date"
                  type="date"
                  className="form-control"
                  placeholder="Entrer un date"
                  defaultValue={props.data?.date}
                  onChange={(e) => {
                    setReseash({ ...researsh, date: e.target.value });
                  }}
                />
                {props.errors?.date && (
                  <span className="text-danger">date est requis</span>
                )}
              </div>
            </div>
          </div>

          {students.length ? (
            <div>
              <div className="row">
                <div className="col-3">
                  <div>
                    <label>toute la journée ?</label>
                  </div>
                  <input
                    ref={props.register}
                    name="all"
                    type="checkbox"
                    style={{ height: 30, width: 30, marginLeft: 30 }}
                    onChange={(e) => setAll(e.target.checked)}
                    defaultChecked={all}
                  />
                </div>
                {!all && (
                  <div className="col-9">
                    <label>
                      Sceance {<span className="text-danger">*</span>}
                    </label>
                    <select
                      className="form-control mb-5"
                      name="subject"
                      ref={props.registerRequired}
                      onChange={(e) => {
                        setReseash({ ...researsh, subject: e.target.value });
                      }}
                    >
                      {!props.data?.subject && (
                        <option value="" disabled selected hidden>
                          Selectionnez un Classe...
                        </option>
                      )}
                      {subjects.map(({ option, value }, index) => {
                        if (
                          value ===
                          `${props.data?.subject?._id} ${props.data?.teacher?._id}`
                        ) {
                          return (
                            <option value={value} key={index} selected>
                              {option}
                            </option>
                          );
                        } else {
                          return (
                            <option value={value} key={index}>
                              {option}
                            </option>
                          );
                        }
                      })}
                    </select>

                    {subjects.length === 0 && (
                      <div style={{ marginBottom: 15 }}>
                        <span className="text-danger mb-2">
                          Veuillez choisir une séance {"  "}
                        </span>
                      </div>
                    )}
                    {props.errors?.subject && (
                      <span className="text-danger mb-2">
                        Sceance est requis
                      </span>
                    )}
                  </div>
                )}
              </div>
              <List
                students={students}
                absences={props.absences}
                punitions={props.punitions}
                setAbsences={props.setAbsences}
                setPunitions={props.setPunitions}
              />
            </div>
          ) : (
            <h4>Veuillez selectionner la classe et la date !!!</h4>
          )}
        </div>
        <div className="card-footer">
          <button type="submit" className="btn btn-primary mr-2">
            Valider
          </button>
          <button
            type="reset"
            onClick={() => props.reset()}
            className="btn btn-secondary"
          >
            <i className="fa fa-redo"></i>reset
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
};

export default AbsenceForm;
