import React from "react";
function ClassroomForm(props) {
  return (
    <div className="card card-custom">
      {/*begin::Form*/}
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          <div className="form-group">
            <label>
              Nom de Classe <span className="text-danger">*</span>
            </label>
            <input
              ref={props.register}
              name="name"
              type="text"
              className="form-control"
              placeholder="Enter le nom de classe"
              defaultValue={props.data ? props.data.name : ""}
            />
            {props.errors.name && (
              <span className="text-danger">le nom de classe est requis</span>
            )}
          </div>
          <div className="form-group">
            <label>
              Le type de classe (normale,laboratoire etc...){" "}
              <span className="text-danger">*</span>
            </label>
            <select className="form-control" ref={props.register} name="type">
              {["normale", "labo"].map((elem) => {
                if (props.data) {
                  if (elem === props.data.type) {
                    return (
                      <option selected value={elem}>
                        {elem}
                      </option>
                    );
                  } else {
                    return <option value={elem}>{elem}</option>;
                  }
                } else {
                  return <option value={elem}>{elem}</option>;
                }
              })}
            </select>
            {/*
<input
              ref={props.register}
              name="type"
              type="text"
              className="form-control"
              placeholder="Enter le type de salle"
              defaultValue={props.data ? props.data.type : ""}
            />
                */}

            {props.errors.type && (
              <span className="text-danger">le type de classe est requis</span>
            )}
          </div>
          <div className="form-group">
            <label>
              Nombre de places <span className="text-danger">*</span>
            </label>
            <input
              ref={props.register}
              name="placeNumber"
              type="number"
              className="form-control"
              placeholder="Enter le nombre de places"
              defaultValue={props.data ? props.data.placeNumber : ""}
            />
            {props.errors.placeNumber && (
              <span className="text-danger">
                le nombre de places est requis
              </span>
            )}
          </div>
          <div className="card-footer">
            <button type="submit" className="btn btn-primary mr-2">
              Valider
            </button>
            <button
              type="reset"
              onClick={() => props.reset()}
              className="btn btn-secondary"
            >
              <i className="fa fa-redo"></i>reset
            </button>
          </div>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default ClassroomForm;
