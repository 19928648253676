import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select from "react-select";
import swal from "sweetalert";
import { API } from "../../../../constants/env";
function AddSeanceModal(props) {
  const { register, handleSubmit, setValue, errors } = useForm();
  const [days, setDays] = useState([
    { value: "0", label: "Dimanche" },
    { value: "1", label: "Lundi" },
    { value: "2", label: "Mardi" },
    { value: "3", label: "Mercredi" },
    { value: "4", label: "Jeudi" },
    { value: "5", label: "Vendredi" },
    { value: "6", label: "Samedi" },
  ]);
  const [timePicker, setTimePicker] = useState([
    { value: "08:00", label: "08:00" },
    { value: "08:15", label: "08:15" },
    { value: "08:30", label: "08:30" },
    { value: "08:45", label: "08:45" },
    { value: "09:00", label: "09:00" },
    { value: "09:15", label: "09:15" },
    { value: "09:30", label: "09:30" },
    { value: "09:45", label: "09:45" },
    { value: "10:00", label: "10:00" },
    { value: "10:15", label: "10:15" },
    { value: "10:30", label: "10:30" },
    { value: "10:45", label: "10:45" },
    { value: "11:00", label: "11:00" },
    { value: "11:15", label: "11:15" },
    { value: "11:30", label: "11:30" },
    { value: "11:45", label: "11:45" },
    { value: "12:00", label: "12:00" },
    { value: "12:15", label: "12:15" },
    { value: "12:30", label: "12:30" },
    { value: "12:45", label: "12:45" },
    { value: "13:00", label: "13:00" },
    { value: "13:15", label: "13:15" },
    { value: "13:30", label: "13:30" },
    { value: "13:45", label: "13:45" },
    { value: "14:00", label: "14:00" },
    { value: "14:15", label: "14:15" },
    { value: "14:30", label: "14:30" },
    { value: "14:45", label: "14:45" },
    { value: "15:00", label: "15:00" },
    { value: "15:15", label: "15:15" },
    { value: "15:30", label: "15:30" },
    { value: "15:45", label: "15:45" },
    { value: "16:00", label: "16:00" },
    { value: "16:15", label: "16:15" },
    { value: "16:30", label: "16:30" },
    { value: "16:45", label: "16:45" },
    { value: "17:00", label: "17:00" },
    { value: "17:15", label: "17:15" },
    { value: "17:30", label: "17:30" },
    { value: "17:45", label: "17:45" },
    { value: "18:00", label: "18:00" },
  ]);
  const [subject, setSubject] = useState({ value: null, label: null });
  const [salle, setSalle] = useState({ value: null, label: null });
  const [classe, setClasse] = useState(null); //TODO : ToDelete ::::
  const [teacher, setTeacher] = useState({ value: null, label: null });
  const [day, setDay] = useState({ value: null, label: null });
  const [heureDebut, setHeureDebut] = useState({ value: null, label: null });
  const [heureFin, setHeureFin] = useState({ value: null, label: null });
  //Sended to the Seance Modal
  const [matieres, setMatieres] = useState([{ value: null, lable: null }]);
  const [salles, setSalles] = useState([{ value: null, lable: null }]);
  const [classes, setClasses] = useState({ value: null, lable: null }); // TODO: ToDelete::::
  const [teachers, setTeachers] = useState([{ value: null, lable: null }]);
  // Fetch functions to charge { matieres,salles,classes } with Data
  const fetchMatieresOfClass = (Class_id) => {
    fetch(API.classTimetable.SubjectList(Class_id), {
      //I will replace this by get les matieres d'un enseignant specific
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        response = response.map((elem) => ({
          value: elem._id,
          label: `${elem.name} ${elem.level}`,
        }));
        console.log("===============>>> Function <<<===============");
        console.log(
          "AddSeanceModal:inside fetchMatieresOfClass we setMatieres => ",
          response
        );
        console.log("===============>>> Function <<<===============");
        setMatieres(response);
      })
      .catch((e) => {});
  };
  const fetchSalles = () => {
    fetch(API.classTimetable.salleList, {
      //I will replace this by get les salles disponibles
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        startHour: heureDebut.value,
        endHour: heureFin.value,
        day: day.value,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          console.log(response);
          return swal("Error", response.message, "error");
        }
        response = response.map((elem) => ({
          value: elem._id,
          label: elem.name,
        }));
        setSalles(response);
        console.log("===============>>> Function <<<===============");
        console.log("AddSeanceModal:inside fetchSalles and we setSalles");
        console.log("===============>>> Function <<<===============");
      })
      .catch((e) => {});
  };
  const fetchEnseignantsOfClass = () => {
    fetch(API.teachers.all, {
      //I will replace this by get les matieres d'un enseignant specific
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      // body: JSON.stringify({
      //     startHour: heureDebut.value,
      //     endHour: heureFin.value,
      //     day:day.value
      // }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          console.log(response);
          return swal("Error", response.message, "error");
        }
        response = response.map((elem) => ({
          value: elem._id,
          label: elem.profil.firstName + " " + elem.profil.lastName,
        }));
        console.log("===============>>> Function <<<===============");
        console.log(
          "AddSeanceModal: inside fetchEnseignantsOfClass we setTeachers"
        );
        console.log("===============>>> Function <<<===============");
        setTeachers(response);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  useEffect(() => {
    console.log("AddSeanceModal.js(1)");
  }, []);
  useEffect(() => {
    if (props.show) {
      setDay(days[props.defaultValues.day]);
      setHeureDebut(
        timePicker.filter((elem) => {
          if (props.defaultValues.heureDebut == elem.value) {
            return elem;
          }
        })[0]
      );
      setHeureFin(
        timePicker.filter((elem) => {
          if (props.defaultValues.heureFin == elem.value) {
            return elem;
          }
        })[0]
      );
      fetchMatieresOfClass(props.Class.value);
      // fetchEnseignantsOfClass();
      // fetchSalles();
    } else {
      setSubject({ value: null, label: null });
      setSalle({ value: null, label: null });
      setTeacher({ value: null, label: null });
      setDay({ value: null, label: null });
      setHeureDebut({ value: null, label: null });
      setHeureFin({ value: null, label: null });
    }
  }, [props.show]);
  useEffect(() => {
    console.log("AddSeanceModal is showed ? => ", props.show);
    if (props.show && props.Class && props.Class.value) {
      console.log("===============>>> UseEffect <<<===============");
      console.log(
        "AddSeance: inside useEffect props.Enseignant=>",
        props.Enseignant,
        "we fetch fetchMatieresOfClass"
      );
      console.log("===============>>> UseEffect <<<===============");
      fetchMatieresOfClass(props.Enseignant.value);
    }
  }, [props.Class]);
  useEffect(() => {
    console.log("(3)");
    if (heureFin.value) {
      console.log("===============>>> UseEffect <<<===============");
      console.log("useEffect heureFin=>", heureFin, "we setValue");
      console.log("===============>>> UseEffect <<<===============");
      setValue("heureFin", heureFin);
    }
  }, [heureFin]);
  useEffect(() => {
    console.log("(4)");
    if (heureDebut.value) {
      console.log("===============>>> UseEffect <<<===============");
      console.log("useEffect heureDebut=>", heureDebut, "we setValue");
      console.log("===============>>> UseEffect <<<===============");
      setValue("heureDebut", heureDebut);
    }
  }, [heureDebut]);
  useEffect(() => {
    console.log("(8)");
    if (day.value) {
      console.log("===============>>> UseEffect <<<===============");
      console.log("useEffect day=>", day, "we setValue");
      console.log("===============>>> UseEffect <<<===============");
      setValue("days", day);
    }
  }, [day]);
  useEffect(() => {
    if (props.show && day.value && heureDebut.value && heureFin.value) {
      console.log("inside useEffect day,heureDebut et heureFin");
      fetchEnseignantsOfClass();
      console.log("done with fetchEnseignantsOfClass");
      fetchSalles();
      console.log("done with fetchSalles");
    }
  }, [day, heureDebut, heureFin]);
  useEffect(() => {
    console.log("(5)");
    if (subject.value) {
      console.log("===============>>> UseEffect <<<===============");
      console.log("useEffect subject=>", subject, "we setValue");
      console.log("===============>>> UseEffect <<<===============");
      setValue("subject", subject);
    }
  }, [subject]);
  useEffect(() => {
    console.log("(6)");
    if (salle.value) {
      console.log("===============>>> UseEffect <<<===============");
      console.log("useEffect salle=>", salle, "we setValue");
      console.log("===============>>> UseEffect <<<===============");
      setValue("salle", salle);
    }
  }, [salle]);
  useEffect(() => {
    console.log("(7)");
    if (teacher.value) {
      console.log("===============>>> UseEffect <<<===============");
      console.log("useEffect teacher=>", teacher, "we setValue");
      console.log("===============>>> UseEffect <<<===============");
      setValue("teacher", teacher);
    }
  }, [teacher]);

  const handleSubjectChange = (selectedOption) => {
    console.log("===============>>> HandleChange <<<===============");
    console.log("handleSubjectChange=> selectedOption", selectedOption);
    console.log("===============>>> HandleChange <<<===============");
    setSubject(selectedOption);
  };
  const handleSalleChange = (selectedOption) => {
    console.log("===============>>> HandleChange <<<===============");
    console.log("handleSalleChange=> selectedOption", selectedOption);
    console.log("===============>>> HandleChange <<<===============");
    setSalle(selectedOption);
  };
  const handleTeacherChange = (selectedOption) => {
    console.log("===============>>> HandleChange <<<===============");
    console.log("handleClasseChange=> selectedOption", selectedOption);
    console.log("===============>>> HandleChange <<<===============");
    setTeacher(selectedOption);
  };
  const handleDayChange = (selectedOption) => {
    console.log("===============>>> HandleChange <<<===============");
    console.log("handleDayChange=> selectedOption", selectedOption);
    console.log("===============>>> HandleChange <<<===============");
    setDay(selectedOption);
  };
  const handleHeureDebutChange = (selectedOption) => {
    console.log("===============>>> HandleChange <<<===============");
    console.log("handleHeureDebutChange=> selectedOption", selectedOption);
    console.log("===============>>> HandleChange <<<===============");
    setHeureDebut(selectedOption);
  };
  const handleHeureFinChange = (selectedOption) => {
    console.log("===============>>> HandleChange <<<===============");
    console.log("handleHeureFinChange=> selectedOption", selectedOption);
    console.log("===============>>> HandleChange <<<===============");
    setHeureFin(selectedOption);
  };

  useEffect(() => {
    register({ name: "subject" });
    register({ name: "salle" });
    register({ name: "teacher" });
    register({ name: "days" });
    register({ name: "heureDebut" });
    register({ name: "heureFin" });
  }, [register]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ajouter Une Seance
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(props.onAdd)}>
        <Modal.Body>
          <div className="card card-custom">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>
                      Matiere: <span className="text-danger">*</span>
                    </label>
                    <Select
                      ref={register}
                      options={matieres}
                      name="subject"
                      onChange={handleSubjectChange}
                    />
                    {errors.subject && (
                      <span className="text-danger">la matiere est requis</span>
                    )}
                  </div>
                </div>
                {/* <div className="col-6">
                                <div className="form-group">
                                    <label>
                                    Salle <span className="text-danger">*</span>
                                    </label>
                                    <Select 
                                        ref={register}
                                        options={salles} 
                                        name="salle"
                                        onChange={handleSalleChange}
                                        />
                                    {errors.subject && (
                                    <span className="text-danger">la matiere est requis</span>
                                    )}
                                </div>
                            </div> */}
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>
                      Enseignant <span className="text-danger">*</span>
                    </label>
                    <Select
                      ref={register}
                      options={teachers}
                      name="teacher"
                      onChange={handleTeacherChange}
                    />
                    {errors.subject && (
                      <span className="text-danger">la matiere est requis</span>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>
                      Jour <span className="text-danger">*</span>
                    </label>
                    <Select
                      ref={register}
                      options={days}
                      name="days"
                      onChange={handleDayChange}
                      value={day}
                    />
                    {errors.subject && (
                      <span className="text-danger">la matiere est requis</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>
                      Heure Début <span className="text-danger">*</span>
                    </label>
                    <Select
                      ref={register}
                      options={timePicker}
                      name="heureDebut"
                      onChange={handleHeureDebutChange}
                      value={heureDebut}
                    />
                    {errors.subject && (
                      <span className="text-danger">la matiere est requis</span>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>
                      Heure Fin <span className="text-danger">*</span>
                    </label>
                    <Select
                      ref={register}
                      options={timePicker}
                      name="heureFin"
                      onChange={handleHeureFinChange}
                      value={heureFin}
                    />
                    {errors.subject && (
                      <span className="text-danger">la matiere est requis</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => props.onHide(false)}>Quitter</Button>
          <Button type="submit">Ajouter</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
export default AddSeanceModal;
