import React from "react";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import ActionForm from "../../../components/Actions/ActionForm";
import Container from "../../../components/Container/Container";
import { API } from "../../../constants/env";
import moment from "moment";
function AddAction() {
  const { register, handleSubmit, errors, reset, control } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    let listClasse = [];
    if (data.listClasse) {
      data.listClasse.forEach((elem) => {
        listClasse.push(elem.value);
      });
    }

    const newData = {
      actionType: data.actionType,
      description: data.description,
      period: data.period,
      startDate: moment(data.startDate, "yyyy-MM-DD").toDate(),
      endDate: data.period
        ? moment(data.endDate, "yyyy-MM-DD").toDate()
        : moment(data.startDate, "yyyy-MM-DD").toDate(),
      listClasse,
    };

    fetch(API.actions.create, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(newData),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good job!", `L'évenement est ajoutée`, "success");
      })
      .catch((e) => {});
  };
  return (
    <Container
      name="Ajouter un événement"
      back={true}
      backRoute="/event"
      submit={true}
    >
      <ActionForm
        registerRequired={register({ required: true })}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        control={control}
      />
    </Container>
  );
}

export default AddAction;
