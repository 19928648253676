import React, { useState } from "react";
import swal from "sweetalert";
import Container from "../../../components/Container/Container";
import Table from "../../../components/Table/Table";
import { API, ROUTER } from "../../../constants/env";

const ListClub = () => {
  const [fields, __] = useState([
    { title: "Nom du club", field: "name" },
    { title: "Prix (TND)", field: "price" },
  ]);
  const [data, setData] = React.useState([]);

  const fetchData = (currentPage, count, search, setTotalPage, setTotal) => {
    fetch(
      API.club.clubList +
        `?page=${currentPage}&count=${count}&searchQuery=${search}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        setData(response.data);
        setTotalPage(response.totalPage);
        setTotal(response.total);
      })
      .catch((e) => {});
  };

  return (
    <Container
      name="Clubs"
      actionRoute={ROUTER.CLUB.CREATE}
      actionName="Ajouter un Club"
    >
      <Table
        pageName="club"
        name="Clubs"
        fields={fields}
        data={data}
        editRoute={(id) => ROUTER.CLUB.EDIT(id)}
        deleteApiRoute={API.club.remove}
        deleteMultiple={API.club.deleteMultiple}
        fetchData={fetchData}
        showSearch={true}
        stopCheckbox={true}
      />
    </Container>
  );
};

export default ListClub;
