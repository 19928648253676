import React, { useState } from "react";
import swal from "sweetalert";

import { useFieldArray, useForm } from "react-hook-form";
import SubjectForm from "../../components/Subject/SubjectForm";
import Container from "../../components/Container/Container";
import { API, ROUTER } from "../../constants/env";
import { useParams } from "react-router-dom";

function EditSubject() {
  let { id } = useParams();

  const { register, handleSubmit, errors, reset, control, setValue } = useForm({
    defaultValues: {
      objects: [],
    },
  });

  const [data, setData] = useState({});

  const { fields, append, remove, insert } = useFieldArray({
    name: "objects",
    control,
  });

  React.useEffect(() => {
    const fetchdata = async () => {
      fetch(API.subjects.info(id), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }
          response.objects.map((object, index) => insert(index, { object }));
          setData(response);
        })
        .catch((e) => {
          return swal("Error", e.message, "error");
        });
    };
    fetchdata();
  }, [id]);

  const onSubmit = (data) => {
    data.unit = data.unit?.value;
    if (data.objects) data.objects = data.objects.map((obj) => obj.object);
    console.log(data);

    fetch(API.subjects.update(id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good job!", "Matiére ajouté", "success");
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };
  return (
    <Container
      name="Modifier une matiére"
      back={true}
      backRoute={ROUTER.SUBJECTS.LIST}
      submit={true}
    >
      <SubjectForm
        registerRequired={register({ required: true })}
        register={register()}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        data={data}
        control={control}
        setValue={setValue}
        append={append}
        remove={remove}
        fields={fields}
      />
    </Container>
  );
}

export default EditSubject;
