import React, { useEffect, useState } from "react";
import { API, STATIC } from "../../constants/env";
import { Controller } from "react-hook-form";
import Select from "react-select";
import swal from "sweetalert";

const ModuleForm = (props) => {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (props.data) setCheck(props.data.prepa);
  }, [props.data]);
  /*

  useEffect(() => {
    fetch(API.subjects.all, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        response = response.map(({ _id, name, level }) => ({
          value: _id,
          label: `${level}:  ${name}`,
        }));

        setSubjects(response);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  }, []);
  useEffect(() => {
    if (props.data) {
      //
      props.setValue(
        "subjects",
        props.data.subjects?.map((subject) => ({
          value: subject._id,
          label: subject.name,
        }))
      );
    }
  }, [props.data]);
*/
  return (
    <div className="card card-custom">
      {/*begin::Form*/}
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          <div className="row">
            <div className="col-12 form-group">
              <label>
                Module <span className="text-danger">*</span>
              </label>
              <input
                ref={props.registerRequired}
                name="name"
                type="text"
                className="form-control"
                placeholder="Enter le nom du Module ..."
                defaultValue={props.data?.name}
              />
              {props.errors.name && (
                <span className="text-danger">Le nom du Module est requis</span>
              )}
            </div>
            <div className="col-8">
              <label>
                Coefficient <span className="text-danger"> *</span>
              </label>

              <input
                ref={props.registerRequired}
                name="coefficient"
                type="number"
                className="form-control"
                placeholder="La coefficient ..."
                defaultValue={props.data?.coefficient}
              />
              {props.errors?.coefficient && (
                <span className="text-danger">Le nom du Module est requis</span>
              )}
            </div>
            <div className="col-4">
              <label>
                Preparatoire <span className="text-danger"> *</span>
              </label>

              <input
                ref={props.register}
                name="prepa"
                type="checkbox"
                className="form-control"
                defaultChecked={props.data?.prepa}
                onChange={(e) => setCheck(e.target.checked)}
              />
              {props.errors?.prepa && (
                <span className="text-danger"> requis</span>
              )}
            </div>
          </div>
        </div>
        <div className="card-footer">
          <button type="submit" className="btn btn-primary mr-2">
            Valider
          </button>
          <button
            type="reset"
            onClick={() => props.reset()}
            className="btn btn-secondary"
          >
            <i className="fa fa-redo"></i>reset
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
};

export default ModuleForm;
