import React, { useState } from "react";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import StudentForm from "../../components/Student/StudentForm";
import Container from "../../components/Container/Container";
import { API } from "../../constants/env";
function AddUser() {
  const { register, handleSubmit, errors, reset, control } = useForm();
  const [data, setData] = useState(null);
  const onSubmit = (data) => {
    // data.adress = {
    //   adress: data.adress,
    //   city: data.city,
    //   zipCode: data.zipCode,
    // };
    // delete data.city;
    // delete data.zipCode;
    let parents = [];

    data.classe = data.classe.value;
    data.parent.forEach((singleParen) => parents.push(singleParen.value));
    let fd = new FormData();
    fd.append("file", data.file[0]);
    fd.append("email", data.email);
    fd.append("classe", data.classe);
    fd.append("parent", parents);
    fd.append("firstName", data.firstName);
    fd.append("lastName", data.lastName);
    fd.append("userName", data.userName);
    fd.append("password", data.password);
    fd.append("adress", data.adress);
    fd.append("phone", data.phone);
    fd.append("sexe", data.sexe);
    fd.append("country", data.country);
    fd.append("birthDate", data.birthDate);

    // console.log("============",data.file);
    // for (var value of fd.values()) {
    //   console.log(value);
    // }
    //console.log(fd);
    fetch(API.students.create, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      body: fd,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }
        return swal("Good job!", "client ajouter", "success");
      })
      .catch((e) => {});
  };
  return (
    <Container
      name="Ajouter un élève"
      back={true}
      backRoute="/students"
      submit={true}
    >
      <StudentForm
        register={register({ required: true })}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        control={control}
        data={data}
      />
    </Container>
  );
}

export default AddUser;
