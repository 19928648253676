import React from "react";
import Container from "../../components/Container/Container";
import StudentCalendar from "./StudentComponents/StudentCalendar";
function Test2() {
  return (
    <Container name="Les Emplois du temps">
      <StudentCalendar />
    </Container>
  );
}

export default Test2;
