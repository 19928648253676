import React, { useState } from "react";
import Container from "../../components/Container/Container";
import Table from "../../components/Table/Table";
import { API, ROUTER } from "../../constants/env";
import swal from "sweetalert";

const ListModule = () => {
  const fields = [
    { title: "Module", field: "name" },
    //{ title: "Niveau", field: "level" },
    { title: "Coefficient", field: "coefficient" },
    { title: "prepa", field: "prepa" },
  ];

  const fetchData = (
    currentPage,
    count,
    search,
    setTotalPage,
    setTotal,
    idClasse
  ) => {
    fetch(
      API.module.list +
        `?page=${currentPage}&count=${count}&searchQuery=${search}&filterByClasse=${idClasse}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        const result = response.data.map(
          ({ _id, coefficient, name, prepa }) => ({
            _id,
            name,
            prepa: prepa ? "oui" : "non",
            coefficient,
          })
        );
        setData(result);
        setTotalPage(response.totalPage);
        setTotal(response.total);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };

  const [data, setData] = useState([]);

  return (
    <Container
      name="Liste des Modules"
      actionRoute={ROUTER.MODULES.CREATE}
      actionName="Ajouter un Module"
    >
      <Table
        pageName="Module"
        name="Les Modules"
        fields={fields}
        data={data}
        editRoute={(id) => ROUTER.MODULES.EDIT(id)}
        deleteApiRoute={API.module.delete}
        deleteMultiple={API.module.deleteMultiple}
        fetchData={fetchData}
      />
    </Container>
  );
};

export default ListModule;
