import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Modal, Table } from "react-bootstrap";
import { STATIC } from "../../constants/env";
const ModalStudent = ({ student }) => {
  const [show, setShow] = useState(false);
  const [data, setDate] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setDate({
      picture: (
        <img
          src={STATIC.ROUTE(student.profil.picture)}
          className="rounded imgWidthThumbnail"
          alt="teacher_pic"
        />
      ),
      class: `${student?.classe?.name} : ${student?.classe?.level}`,
      firstName: student.profil.firstName,
      lastName: student.profil.lastName,
      parent: student.parent.map(({ profil }) => (
        <span
          style={{ flexWrap: "wrap" }}
        >{` ${profil.firstName} ${profil.lastName} (${profil.password}),`}</span>
      )),
      username: student.profil.userName,
      adress: student.profil.adress,
      sexe: student.profil.sexe,
    });
  }, []);
  useEffect(() => {
    setDate({
      picture: (
        <img
          src={STATIC.ROUTE(student.profil.picture)}
          className="rounded imgWidthThumbnail"
          alt="teacher_pic"
        />
      ),
      class: `${student?.classe?.name} : ${student?.classe?.level}`,
      firstName: student.profil.firstName,
      matricule: student.matricule,
      lastName: student.profil.lastName,
      parent: student.parent.map(({ profil }) => (
        <span>{` ${profil.firstName} ${profil.lastName} (${profil.password}),`}</span>
      )),

      username: student.profil.userName,
      adress: student.profil.adress,
      sexe: student.profil.sexe,
    });
  }, [student]);

  const fields = [
    { title: "Photo", field: "picture" },
    { title: "Prénom", field: "firstName" },
    { title: "Nom", field: "lastName" },
    { title: "Sexe", field: "sexe" },
    { title: "Classe", field: "class" },
    { title: "Parent", field: "parent" },
    { title: "Matricule", field: "matricule" },
    { title: "username", field: "username" },
    { title: "Addresse", field: "adress" },
  ];

  const handleDataShow = () => {
    handleShow();
  };

  const RenderShowScreen = () => {
    if (data) {
      let result = (
        <>
          <Modal.Header style={{ "align-self": "center" }}>
            <Modal.Title id="contained-modal-title-vcenter">
              {student.profil.firstName} {student.profil.lastName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body centered>
            <Card>
              <Table striped bordered hover>
                <tbody>
                  {fields.map(({ title, field }) => {
                    return (
                      <tr>
                        <td>
                          <b>{title} :</b>
                        </td>
                        <td className="text-center">{data[field]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </Modal.Body>
        </>
      );
      return result;
    } else {
      return (
        <>
          <Modal.Body>
            <Alert variant="danger">
              Désolé !!! Pas d'informations en ce moment sur cette personne
            </Alert>
          </Modal.Body>
        </>
      );
    }
  };

  return (
    <React.Fragment>
      <button className="btn btn-primary btn-sm" onClick={handleDataShow}>
        Voir Plus
      </button>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        {RenderShowScreen()}

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ModalStudent;
