import React from "react";
import Container from "../../../components/Container/Container";
import Table from "../../../components/Table/Table";
import { API, ROUTER } from "../../../constants/env";
import swal from "sweetalert";
import StudentModel from "./StudentModel";

/*
        <StudentModel
          title="Clubs"
          payments={[
            { name: "natation", price: 325, paid: true },
            { name: "football", price: 325, paid: false },
          ]}
        />*/
const StudentPay = () => {
  const fields = [
    { title: "Prénom", field: "firstName" },
    { title: "Nom", field: "lastName" },
    { title: "Classe", field: "classe" },
    { title: "Services", field: "services" },
    { title: "Clubs", field: "clubs" },
    { title: "Frais", field: "fees" },
  ];

  const [data, setData] = React.useState([]);

  const fetchData = (
    currentPage,
    count,
    search,
    setTotalPage,
    setTotal,
    idClasse
  ) => {
    fetch(
      API.bill.billsList +
        `?page=${currentPage}&count=${count}&searchQuery=${search}&filterByClasse=${idClasse}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        console.log(response.data);
        const studentBills = response.data.map(
          (
            {
              student: { profil, classe },
              paidClubs,
              paidFees,
              paidService,
              _id,
            },
            index
          ) => {
            return {
              _id,
              firstName: profil.firstName,
              lastName: profil.lastName,
              clubs: <StudentModel title="Clubs" payments={paidClubs} />,
              services: (
                <StudentModel title="Services" payments={paidService} />
              ),
              fees: <StudentModel title="Frais" payments={paidFees} />,
              classe: classe.name,
            };
          }
        );

        setData(studentBills);
        setTotalPage(response.totalPage);
        setTotal(response.total);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };

  return (
    <Container name="Pay">
      <Table
        classeApi={API.classes.all}
        pageName="pay"
        name="Payment"
        fields={fields}
        data={data}
        editRoute={(id) => ROUTER.BILLS.EDIT(id)}
        deleteApiRoute={API.fees.remove}
        deleteMultiple={API.fees.deleteMultiple}
        fetchData={fetchData}
        showSearch={true}
      />
    </Container>
  );
};

export default StudentPay;
