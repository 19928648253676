/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/aria-proptypes */
/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

function TableArchive(props) {
  const [totalPage, setTotalPage] = useState(0);
  const [idClasse, setIdClasse] = useState("");
  const [classes, setClasses] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [count, setCount] = useState(30);
  const [search, setSearch] = useState("");
  const [update, setUpdate] = useState("");
  const [termestre, setTermestre] = useState("");

  useEffect(() => {
    if (props.fetchData) {
      props.fetchData(
        currentPage,
        count,
        search,
        setTotalPage,
        setTotal,
        idClasse,
        termestre
      );
    }
  }, [count, currentPage, update, search, idClasse, termestre]);

  useEffect(() => {
    let {
      page,
      currentPage: current,
      count: c,
      idClasse,
      termestre,
    } = JSON.parse(localStorage.getItem("currentPage"));

    if (page !== props.pageName) {
      current = 1;
      c = 30;
      idClasse = "";
      localStorage.setItem(
        "currentPage",
        JSON.stringify({
          currentPage: current,
          page: props.pageName,
          count: c,
          idClasse,
          termestre,
        })
      );
    }
    if (props.classeApi) {
      fetch(props.classeApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }

          const result = response.map((elem) => ({
            option: elem.name,
            value: elem._id,
          }));
          setClasses([{ option: "Tout les Classes", value: "" }, ...result]);
        })
        .catch((e) => {
          return swal("Error", e.message, "error");
        });
    }
    setIdClasse(idClasse);
    setTermestre(termestre);
    setCount(c);
    setCurrentPage(current);
  }, []);

  useEffect(() => {
    if (props.data.length > 0) {
      let items = [];
      props.data.map(() => {
        items.push(false);
      });
      setCheckedList(items);
    }
  }, [props.data]);

  const setStorage = (champ, value) => {
    const store = JSON.parse(localStorage.getItem("currentPage"));
    localStorage.setItem(
      "currentPage",
      JSON.stringify({ ...store, [`${champ}`]: value })
    );
  };

  const handleChangeSearch = (e) => {
    setCurrentPage(1);
    setSearch(e.target.value);
    setUpdate(!update);
  };

  const goToPage = (event, i) => {
    event.preventDefault();

    if (i !== currentPage) {
      setCurrentPage(i);
      setStorage("currentPage", i);
    }
  };
  const navigate = async (event, type) => {
    event.preventDefault();
    if (type === "next" && currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
      setStorage("currentPage", currentPage + 1);
    }
    if (type === "previous" && currentPage >= 1) {
      setCurrentPage(currentPage - 1);
      setStorage("currentPage", currentPage - 1);
    }
  };
  const navigateToLast = async (event, type) => {
    event.preventDefault();
    if (type === "last") {
      setCurrentPage(totalPage);
      setStorage("currentPage", totalPage);
    }
    if (type === "first") {
      setCurrentPage(1);
      setStorage("currentPage", 1);
    }
  };

  let menuItems = [];
  for (var i = 1; i <= totalPage; i++) {
    // eslint-disable-next-line no-loop-func

    menuItems.push(i);
  }
  const restaureItem = (id) => {
    swal({
      title: "Vous etes sure ?",
      text: "Voulez vous restaurez ce fichier",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(props.retoreApiRoute, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({ id }),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.error) {
              return swal("Error", response.message, "error");
            }
            return swal("Success", "restauré avec succés", "success").then(
              () => {
                setUpdate(!update);
              }
            );
          })

          .catch((e) => swal("Erreur", e.message, "error"));
      }
    });
  };
  const handleChangeSelectAll = (e) => {
    setCheckedAll(!checkedAll);
    if (e.target.checked) {
      let items = [];
      props.data.map(() => {
        items.push(true);
      });
      setCheckedList(items);
    }
    if (e.target.checked === false) {
      let items = [];
      props.data.map(() => {
        items.push(false);
      });
      setCheckedList(items);
    }
  };
  const handleChangeSelect = (e, index) => {
    let newArr = [...checkedList];
    newArr[index] = e.target.checked;
    setCheckedList(newArr);

    if (newArr.every((value) => value === true)) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  };
  const deleteAll = () => {
    let ids = [];
    checkedList.map((elem, index) => {
      if (elem === true) {
        if (props.data) {
          ids.push(props.data[index]._id);
        }
      }
    });

    swal({
      title: "Vous etes sure ?",
      text: "Avertissement de suppresion",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(props.deleteMultiple, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({ ids: ids }),
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.error) {
              return swal("Error", response.message, "error");
            }
            return swal("Success", "suppresion avec succés", "success").then(
              () => {
                setUpdate(!update);
                setCheckedAll(false);
                checkedList.includes(false);
              }
            );
          })

          .catch((e) => swal("Erreur", e.message, "error"));
      }
    });
  };

  return (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <div className="card-title">
          <h3 className="card-label">{props.name}</h3>
        </div>
        <div className="card-toolbar">
          {/* <button type="button" className="btn btn-primary">
            export
          </button>
          
          */}
        </div>
      </div>
      <div className="card-body">
        <div className="react-bootstrap-table table-responsive">
          <table className="table table table-head-custom table-vertical-center overflow-hidden">
            <thead>
              <tr>
                {props.fields.map((elem, index) => (
                  <th className="sortable" key={index}>
                    {elem.title}
                  </th>
                ))}
                <th className="text-right pr-3">Actions</th>

                {/*<th
                    tabIndex={0}
                    aria-label="VIN Code (ID) sortable"
                    className="sortable"
                  >
                    {elem.title}
                    <span className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort">
                      <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <title>Stockholm-icons / Shopping / Sort1</title>
                        <desc>Created with Sketch.</desc>
                        <defs />
                        <g
                          id="Stockholm-icons-/-Shopping-/-Sort1"
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect id="bound" x={0} y={0} width={24} height={24} />
                          <rect
                            id="Rectangle-8"
                            fill="#000000"
                            x={4}
                            y={5}
                            width={16}
                            height={3}
                            rx="1.5"
                          />
                          <path
                            d="M7.5,11 L16.5,11 C17.3284271,11 18,11.6715729 18,12.5 C18,13.3284271 17.3284271,14 16.5,14 L7.5,14 C6.67157288,14 6,13.3284271 6,12.5 C6,11.6715729 6.67157288,11 7.5,11 Z M10.5,17 L13.5,17 C14.3284271,17 15,17.6715729 15,18.5 C15,19.3284271 14.3284271,20 13.5,20 L10.5,20 C9.67157288,20 9,19.3284271 9,18.5 C9,17.6715729 9.67157288,17 10.5,17 Z"
                            id="Combined-Shape"
                            fill="#000000"
                            opacity="0.3"
                          />
                        </g>
                      </svg>
                    </span>
                  </th> */}
              </tr>
            </thead>
            <tbody>
              {props.data.map((elem, index) => (
                <tr key={index}>
                  {props.fields.map((item) => (
                    <td key={item.field}>{elem[item.field]}</td>
                  ))}
                  <td className="text-right pr-0" style={{ minWidth: 100 }}>
                    <a
                      className="btn btn-icon btn-light btn-hover-danger btn-sm"
                      onClick={() => restaureItem(elem._id)}
                    >
                      <span className="svg-icon svg-icon-primary svg-icon-2x">
                        {/*begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-03-11-144509/theme/html/demo1/dist/../src/media/svg/icons/Code/Lock-overturning.svg*/}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x={0} y={0} width={24} height={24} />
                            <path
                              d="M7.38979581,2.8349582 C8.65216735,2.29743306 10.0413491,2 11.5,2 C17.2989899,2 22,6.70101013 22,12.5 C22,18.2989899 17.2989899,23 11.5,23 C5.70101013,23 1,18.2989899 1,12.5 C1,11.5151324 1.13559454,10.5619345 1.38913364,9.65805651 L3.31481075,10.1982117 C3.10672013,10.940064 3,11.7119264 3,12.5 C3,17.1944204 6.80557963,21 11.5,21 C16.1944204,21 20,17.1944204 20,12.5 C20,7.80557963 16.1944204,4 11.5,4 C10.54876,4 9.62236069,4.15592757 8.74872191,4.45446326 L9.93948308,5.87355717 C10.0088058,5.95617272 10.0495583,6.05898805 10.05566,6.16666224 C10.0712834,6.4423623 9.86044965,6.67852665 9.5847496,6.69415008 L4.71777931,6.96995273 C4.66931162,6.97269931 4.62070229,6.96837279 4.57348157,6.95710938 C4.30487471,6.89303938 4.13906482,6.62335149 4.20313482,6.35474463 L5.33163823,1.62361064 C5.35654118,1.51920756 5.41437908,1.4255891 5.49660017,1.35659741 C5.7081375,1.17909652 6.0235153,1.2066885 6.2010162,1.41822583 L7.38979581,2.8349582 Z"
                              fill="#000000"
                              opacity="0.3"
                            />
                            <path
                              d="M14.5,11 C15.0522847,11 15.5,11.4477153 15.5,12 L15.5,15 C15.5,15.5522847 15.0522847,16 14.5,16 L9.5,16 C8.94771525,16 8.5,15.5522847 8.5,15 L8.5,12 C8.5,11.4477153 8.94771525,11 9.5,11 L9.5,10.5 C9.5,9.11928813 10.6192881,8 12,8 C13.3807119,8 14.5,9.11928813 14.5,10.5 L14.5,11 Z M12,9 C11.1715729,9 10.5,9.67157288 10.5,10.5 L10.5,11 L13.5,11 L13.5,10.5 C13.5,9.67157288 12.8284271,9 12,9 Z"
                              fill="#000000"
                            />
                          </g>
                        </svg>
                        {/*end::Svg Icon*/}
                      </span>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-between align-items-center flex-wrap">
          {totalPage > 1 && (
            <div className="d-flex flex-wrap py-2 mr-3 ">
              <a
                className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
                onClick={(event) => navigateToLast(event, "first")}
              >
                <i className="ki ki-bold-double-arrow-back icon-xs" />
              </a>
              <a
                className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
                onClick={(event) => navigate(event, "previous")}
              >
                <i className="ki ki-bold-arrow-back icon-xs" />
              </a>
              {currentPage !== 1 && (
                <a className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">
                  ...
                </a>
              )}
              {menuItems
                .slice(
                  currentPage >= 4
                    ? currentPage >= totalPage - 1
                      ? currentPage - 4
                      : currentPage - 3
                    : 0,
                  currentPage >= 4 ? currentPage + 2 : 5
                )
                .map((elem, index) => {
                  return (
                    <a
                      className={`btn btn-icon btn-sm border-0 btn-light ${
                        elem === currentPage ? "btn-hover-primary active" : ""
                      } mr-2 my-1`}
                      onClick={(event) => goToPage(event, elem)}
                      key={index}
                    >
                      {elem}
                    </a>
                  );
                })}

              {currentPage !== totalPage && (
                <a className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">
                  ...
                </a>
              )}

              <a
                className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
                onClick={(event) => navigate(event, "next")}
              >
                <i className="ki ki-bold-arrow-next icon-xs" />
              </a>
              <a
                className="btn btn-icon btn-sm btn-light btn-hover-primary mr-2 my-1"
                onClick={(event) => navigateToLast(event, "last")}
              >
                <i className="ki ki-bold-double-arrow-next icon-xs" />
              </a>
            </div>
          )}

          <div className="d-flex align-items-center py-3">
            <select
              className="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light false"
              style={{ width: 75 }}
              onChange={(e) => {
                setStorage("count", +e.target.value);
                setCurrentPage(1);
                setStorage("currentPage", 1);
                setCount(+e.target.value);
              }}
            >
              {[3, 5, 10, 30].map((number) => {
                if (number === count)
                  return (
                    <option className="btn" selected>
                      {number}
                    </option>
                  );
                else return <option className="btn">{number}</option>;
              })}
            </select>
            <span className="react-bootstrap-table-pagination-total">
              Afficher de 1 à {count} élèments, il y a {total} élèments au total
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TableArchive;
