import React from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import Container from "../../../components/Container/Container";
import TypesForm from "../../../components/Types/TypesForm";
import { API, ROUTER } from "../../../constants/env";

const AddType = () => {
  const { register, handleSubmit, errors, reset } = useForm();
  const onSubmit = (data) => {
    fetch(API.actionTypes.create, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }

        return swal("Good job!", "Type ajouter", "success");
      })
      .catch((e) => {
        return swal("Error fel request", e.message, "error");
      });
  };
  return (
    <Container
      name="Ajouter un Type"
      back={true}
      backRoute={ROUTER.ActionTYPES.LIST}
      submit={true}
    >
      <TypesForm
        registerRequired={register({ required: true })}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
      />
    </Container>
  );
};

export default AddType;
