import React, { useState } from "react";
import swal from "sweetalert";

import { useForm } from "react-hook-form";
import ClasseForm from "../../components/Classes/ClasseForm";
import Container from "../../components/Container/Container";
import { API } from "../../constants/env";
import { useParams } from "react-router-dom";

function EditClasse() {
  let { id } = useParams();

  const { register, handleSubmit, errors, reset } = useForm();
  const [data, setData] = useState({});
  React.useEffect(() => {
    const fetchdata = async () => {
      fetch(API.classes.info(id), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }

          setData(response);
        })
        .catch((e) => {});
    };
    fetchdata();
  }, [id]);
  const onSubmit = (data) => {
    fetch(API.classes.update(id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good job!", "classe modifie", "success");
      })
      .catch((e) => {});
  };
  return (
    <Container
      name="Modifier une classe "
      back={true}
      backRoute="/classes"
      submit={true}
    >
      <ClasseForm
        register={register({ required: true })}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        data={data}
      />
    </Container>
  );
}

export default EditClasse;
