const localApi = "http://localhost:5000/";
const serverApi = "https://api.ecole-odyssee.tn/";
const serverClient = "https://sms.ecole-odyssee.tn/assets/images/";
const baseURL = process.env.NODE_ENV == "development" ? localApi : serverApi;
const staticURL =
  process.env.NODE_ENV == "development" ? localApi : serverClient;

exports.SERVER_URL =
  process.env.NODE_ENV == "development" ? localApi : serverApi;
exports.ROUTER = {
  SETTINGS: "/settings",
  RESET: "/reset",
  LOGIN: "/login",
  HOME: "/",
  USERS: {
    LIST: "/user",
    CREATE: "/user/new",
    EDIT: (id) => `/user/edit/${id}`,
  },
  CLASSES: {
    LIST: "/classes",
    CREATE: "/classe/new",
    EDIT: (id) => `/classe/edit/${id}`,
  },
  SALLES: {
    LIST: "/salles",
    CREATE: "/salle/new",
    EDIT: (id) => `/salle/edit/${id}`,
  },
  ABSENCE: {
    LIST: "/absences",
    CREATE: "/absences/new",
    EDIT: (id) => `/absences/edit/${id}`,
  },
  SUBJECTS: {
    LIST: "/subjects",
    CREATE: "/subject/new",
    EDIT: (id) => `/subject/edit/${id}`,
  },

  STUDENTS: {
    LIST: "/students",
    CREATE: "/student/new",
    EDIT: (id) => `/student/edit/${id}`,
  },

  PARENTS: {
    LIST: "/parents",
    CREATE: "/parent/new",
    EDIT: (id) => `/parent/edit/${id}`,
  },

  TEACHERS: {
    LIST: "/teachers",
    CREATE: "/teacher/new",
    EDIT: (id) => `/teacher/edit/${id}`,
  },

  EXAMENS: {
    LIST: "/examens",
    CREATE: "/examens/new",
    EDIT: (id) => `/examens/edit/${id}`,
  },

  MODULES: {
    LIST: "/modules",
    CREATE: "/modules/new",
    EDIT: (id) => `/modules/edit/${id}`,
  },
  CARNETS: {
    LIST: "/carnets",
    EDIT: (id) => `/carnets/st/${id}`,
  },
  CARNETPREPA: {
    LIST: "/carnetPrepa",
    EDIT: (id) => `/carnetPrepa/st/${id}`,
  },
  CANTINE: {
    LIST: "/cantine",
    CREATE: "/cantine/new",
    EDIT: (id) => `/cantine/edit/${id}`,
  },
  NOTIFICATION: {
    LIST: "/notification",
    CREATE: "/notification/new",
    EDIT: (id) => `/notification/edit/${id}`,
  },
  ARCHIVE_NOTIFICATION: {
    LIST: "/archive-notification",
  },

  SERVICES: {
    LIST: "/services",
    CREATE: "/services/new",
    EDIT: (id) => `/services/edit/${id}`,
  },

  ActionTYPES: {
    LIST: "/actionType",
    CREATE: "/actionType/new",
    EDIT: (id) => `/actionType/edit/${id}`,
  },
  ACTIONS: {
    LIST: "/event",
    CREATE: "/event/new",
    EDIT: (id) => `/event/edit/${id}`,
  },
  CLUB: {
    LIST: "/club",
    CREATE: "/club/new",
    EDIT: (id) => `/club/edit/${id}`,
  },

  FEES: {
    LIST: "/fees",
    CREATE: "/fees/new",
    EDIT: (id) => `/fees/edit/${id}`,
  },

  BILLS: {
    EDIT: (id) => `/payment/st/${id}`,
  },

  ERROR: "/404",
};
exports.STATIC = {
  LOGO: "/assets/images/logo.png",
  LOGO_WHITE: `${staticURL}logoSideBar.png`,
  ICON: "/assets/images/icon.png",
  TITLE: "Brasserie 529",
  DAYS: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
  LEVELS: [
    { label: "1ere", value: "1" },
    { label: "2eme", value: "2" },
    { label: "3eme", value: "3" },
    { label: "4eme", value: "4" },
    { label: "5eme", value: "5" },
    { label: "6eme", value: "6" },
    { label: "Préparatoire", value: "Prepa" },
  ],

  PERIODE: (filter = "") =>
    [
      { option: "1 er Trimestre", value: "1er Trimestre" },
      { option: "2 eme Trimestre", value: "2eme Trimestre" },
      { option: "3eme Trimestre", value: "3eme Trimestre" },
      { option: "Annuel", value: "Annuel" },
    ].filter((elem) => elem.option !== filter),

  ROUTE: (file) =>
    `${staticURL}${file.startsWith("/") ? file.substring(1) : file}`,
};
exports.API = {
  auth: {
    login: `${baseURL}admin-api/v1/auth/login`,
  },
  me: `${baseURL}admin-api/v1/me/`,

  user: {
    create: `${baseURL}admin-api/v1/users/new`,
    all: `${baseURL}admin-api/v1/user/all`,
    list: `${baseURL}admin-api/v1/users/list`,
    remove: `${baseURL}admin-api/v1/user/delete`,
    info: (id) => `${baseURL}admin-api/v1/user/info/${id}`,
    update: (id) => `${baseURL}admin-api/v1/user/edit/${id}`,
  },

  salle: {
    create: `${baseURL}admin-api/v1/classRooms/new`, // to create new salle
    list: `${baseURL}admin-api/v1/classRooms/list`, //to list all salles in database
    all: `${baseURL}admin-api/v1/classRooms/all`,
    remove: `${baseURL}admin-api/v1/classRooms/delete`, // to remove a specific salle
    info: (id) => `${baseURL}admin-api/v1/classRooms/info/${id}`, // to get info about a specific salle
    update: (id) => `${baseURL}admin-api/v1/classRooms/edit/${id}`, //to update a specific salle info
    restore: `${baseURL}admin-api/v1/classRooms/restore`, //to restore a deleted salle from database-trash
    allArchived: `${baseURL}admin-api/v1/classRooms/archive`, //to get all archived salles
    deleteMultiple: `${baseURL}admin-api/v1/classRooms/deleteMultiple`,
  },
  subjects: {
    create: `${baseURL}admin-api/v1/subjects/new`, // to create new subject
    list: `${baseURL}admin-api/v1/subjects/list`, //to list all subjects in database
    all: `${baseURL}admin-api/v1/subjects/all`,
    remove: `${baseURL}admin-api/v1/subjects/delete`, // to remove a specific subject
    info: (id) => `${baseURL}admin-api/v1/subjects/info/${id}`, // to get info about a specific subject
    update: (id) => `${baseURL}admin-api/v1/subjects/edit/${id}`, //to update a specific subject info
    restore: `${baseURL}admin-api/v1/subjects/restore`, //to restore a deleted subject from database-trash
    allArchived: `${baseURL}admin-api/v1/subjects/archive`, //to get all archived subject
    deleteMultiple: `${baseURL}admin-api/v1/subjects/deleteMultiple`,
  },
  students: {
    create: `${baseURL}admin-api/v1/students/new`, // to create new student
    list: `${baseURL}admin-api/v1/students/list`, //to list all students in database
    all: `${baseURL}admin-api/v1/students/all`,
    remove: `${baseURL}admin-api/v1/students/delete`, // to remove a specific student
    info: (id) => `${baseURL}admin-api/v1/students/info/${id}`, // to get info about a specific student
    update: (id) => `${baseURL}admin-api/v1/students/edit/${id}`, //to update a specific student info
    restore: `${baseURL}admin-api/v1/students/restore`, //to restore a deleted student from database-trash
    allArchived: `${baseURL}admin-api/v1/students/archive`, //to get all archived student
    deleteMultiple: `${baseURL}admin-api/v1/students/deleteMultiple`,
  },
  classes: {
    create: `${baseURL}admin-api/v1/classes/new`, // to create new classe
    list: `${baseURL}admin-api/v1/classes/list`, //to list all classes in database
    all: `${baseURL}admin-api/v1/classes/all`,
    remove: `${baseURL}admin-api/v1/classes/delete`, // to remove a specific classe
    info: (id) => `${baseURL}admin-api/v1/classes/info/${id}`, // to get info about a specific classe
    update: (id) => `${baseURL}admin-api/v1/classes/edit/${id}`, //to update a specific classe info
    restore: `${baseURL}admin-api/v1/classes/restore`, //to restore a deleted classe from database-trash
    allArchived: `${baseURL}admin-api/v1/classes/archive`, //to get all archived classe
    deleteMultiple: `${baseURL}admin-api/v1/classes/deleteMultiple`,
  },
  teachers: {
    create: `${baseURL}admin-api/v1/teachers/new`, // to create new teacher
    list: `${baseURL}admin-api/v1/teachers/list`, //to list all teachers in database
    all: `${baseURL}admin-api/v1/teachers/all`,
    remove: `${baseURL}admin-api/v1/teachers/delete`, // to remove a specific teacher
    info: (id) => `${baseURL}admin-api/v1/teachers/info/${id}`, // to get info about a specific teacher
    update: (id) => `${baseURL}admin-api/v1/teachers/edit/${id}`, //to update a specific teacher info
    restore: `${baseURL}admin-api/v1/teachers/restore`, //to restore a deleted teacher from database-trash
    allArchived: `${baseURL}admin-api/v1/teachers/archive`, //to get all archived teacher
    deleteMultiple: `${baseURL}admin-api/v1/teachers/deleteMultiple`,
  },
  parents: {
    create: `${baseURL}admin-api/v1/parents/new`, // to create new parent
    list: `${baseURL}admin-api/v1/parents/list`, //to list all parents in database
    all: `${baseURL}admin-api/v1/parents/all`,
    remove: `${baseURL}admin-api/v1/parents/delete`, // to remove a specific parent
    info: (id) => `${baseURL}admin-api/v1/parents/info/${id}`, // to get info about a specific parent
    update: (id) => `${baseURL}admin-api/v1/parents/edit/${id}`, //to update a specific parent info
    restore: `${baseURL}admin-api/v1/parents/restore`, //to restore a deleted parent from database-trash
    allArchived: `${baseURL}admin-api/v1/parents/archive`, //to get all archived parent
    deleteMultiple: `${baseURL}admin-api/v1/parents/deleteMultiple`,
  },
  teacherTimetable: {
    create: `${baseURL}admin-api/v1/scheduleTeacher/new`, // POST : to create new session
    update: (id) => `${baseURL}admin-api/v1/scheduleTeacher/edit/${id}`, //POST : Update a session
    remove: `${baseURL}admin-api/v1/scheduleTeacher/session/remove`, //POST : remove a session (req.body => id)
    classList: `${baseURL}admin-api/v1/scheduleTeacher/classe`, // POST : to list all classes available at that time
    salleList: `${baseURL}admin-api/v1/scheduleTeacher/classRoom`, // POST : to list all classrooms available at that time
    list: (id) => `${baseURL}admin-api/v1/scheduleTeacher/list/${id}`, //GET : get all sessions of a specific teacher
    SubjectList: (id) =>
      `${baseURL}admin-api/v1/scheduleTeacher/subjects/${id}`, //GET : get list of subjects of that specific teacher
  },
  classTimetable: {
    create: `${baseURL}admin-api/v1/scheduleClasse/new`, // POST : to create new session
    update: (id) => `${baseURL}admin-api/v1/scheduleClasse/edit/${id}`, //POST : Update a session
    remove: `${baseURL}admin-api/v1/scheduleClasse/session/remove`, //POST : remove a session (req.body => id)
    classList: `${baseURL}admin-api/v1/scheduleClasse/classe`, // POST : to list all classes available at that time
    salleList: `${baseURL}admin-api/v1/scheduleClasse/classRoom`, // POST : to list all classrooms available at that time
    list: (id) => `${baseURL}admin-api/v1/scheduleClasse/list/${id}`, //GET : get all sessions of a specific teacher
    SubjectList: (id) => `${baseURL}admin-api/v1/scheduleClasse/subjects/${id}`, //GET : get list of subjects of that specific teacher
  },

  cantine: {
    create: `${baseURL}admin-api/v1/menu/new`, //POST : add a menu
    menuList: `${baseURL}admin-api/v1/menu/list`, //GET : to list the menus
    all: `${baseURL}admin-api/v1/menu/all`,
    remove: `${baseURL}admin-api/v1/menu/delete`, //POST : remove a menu
    deleteMultiple: `${baseURL}admin-api/v1/menu/deleteMultiple`,
    update: (id) => `${baseURL}admin-api/v1/menu/edit/${id}`, //POST: update a menu
    info: (id) => `${baseURL}admin-api/v1/menu/info/${id}`,
  },

  services: {
    create: `${baseURL}admin-api/v1/services/new`, //POST : add a service
    serviceList: `${baseURL}admin-api/v1/services/list`, //GET : to list the services
    serviceAll: `${baseURL}admin-api/v1/services/all`,
    remove: `${baseURL}admin-api/v1/services/delete`, //POST : remove a services
    deleteMultiple: `${baseURL}admin-api/v1/services/deleteMultiple`,
    update: (id) => `${baseURL}admin-api/v1/services/edit/${id}`, //POST: update a services
    info: (id) => `${baseURL}admin-api/v1/services/info/${id}`,
  },

  club: {
    create: `${baseURL}admin-api/v1/clubs/new`, //POST : add a    club
    clubList: `${baseURL}admin-api/v1/clubs/list`, //GET : to list the clubs
    clubAll: `${baseURL}admin-api/v1/clubs/all`,
    remove: `${baseURL}admin-api/v1/clubs/delete`, //POST : remove a club
    deleteMultiple: `${baseURL}admin-api/v1/clubs/deleteMultiple`,
    update: (id) => `${baseURL}admin-api/v1/clubs/edit/${id}`, //POST: update a club
    info: (id) => `${baseURL}admin-api/v1/clubs/info/${id}`,
  },

  fees: {
    all: `${baseURL}admin-api/v1/fees/all`,
    create: `${baseURL}admin-api/v1/fees/new`, //POST : add a fees
    feesList: `${baseURL}admin-api/v1/fees/list`, //GET : to list the feess
    feesAll: `${baseURL}admin-api/v1/fees/all`,
    remove: `${baseURL}admin-api/v1/fees/delete`, //POST : remove a fees
    deleteMultiple: `${baseURL}admin-api/v1/fees/deleteMultiple`,
    update: (id) => `${baseURL}admin-api/v1/fees/edit/${id}`, //POST: update a fees
    info: (id) => `${baseURL}admin-api/v1/fees/info/${id}`,
  },

  bill: {
    create: `${baseURL}admin-api/v1/bills/new`, //POST : add a bills
    billsList: `${baseURL}admin-api/v1/bills/list`, //GET : to list the billss
    remove: `${baseURL}admin-api/v1/bills/delete`, //POST : remove a bills
    deleteMultiple: `${baseURL}admin-api/v1/bills/deleteMultiple`,
    update: (id) => `${baseURL}admin-api/v1/bills/edit/${id}`, //POST: update a bills
    info: (id) => `${baseURL}admin-api/v1/bills/info/${id}`,
  },

  absences: {
    getStudent: (id) => `${baseURL}admin-api/v1/classes/students/list/${id}`,
    getSceance: `${baseURL}admin-api/v1/session/list`,
    list: `${baseURL}admin-api/v1/register/list`,
    all: `${baseURL}admin-api/v1/register/all`,
    create: `${baseURL}admin-api/v1/register/new`,
    update: (id) => `${baseURL}admin-api/v1/register/edit/${id}`,
    delete: `${baseURL}admin-api/v1/register/delete`,
    deleteMultiple: `${baseURL}admin-api/v1/register/deleteMultiple`,
    info: (id) => `${baseURL}admin-api/v1/register/info/${id}`,
  },

  actionTypes: {
    list: `${baseURL}admin-api/v1/actionType/list`,
    all: `${baseURL}admin-api/v1/actionType/all`,
    create: `${baseURL}admin-api/v1/actionType/new`,
    update: (id) => `${baseURL}admin-api/v1/actionType/edit/${id}`,
    delete: `${baseURL}admin-api/v1/actionType/delete`,
    deleteMultiple: `${baseURL}admin-api/v1/actionType/deleteMultiple`,
    info: (id) => `${baseURL}admin-api/v1/actionType/info/${id}`,
  },
  actions: {
    create: `${baseURL}admin-api/v1/action/new`,
    list: `${baseURL}admin-api/v1/action/list`,
    all: `${baseURL}admin-api/v1/action/all`,
    remove: `${baseURL}admin-api/v1/action/delete`,
    deleteMultiple: `${baseURL}admin-api/v1/action/deleteMultiple`,
    update: (id) => `${baseURL}admin-api/v1/action/edit/${id}`,
    info: (id) => `${baseURL}admin-api/v1/action/info/${id}`,
  },

  notification: {
    create: `${baseURL}admin-api/v1/notifications/notify/user`,
    list: `${baseURL}admin-api/v1/notifications/list`,
    all: `${baseURL}admin-api/v1/notifications/all`,
    delete: `${baseURL}admin-api/v1/notifications/delete`,
    deleteMultiple: `${baseURL}admin-api/v1/notifications/deleteMultiple`,
    update: (id) => `${baseURL}admin-api/v1/notifications/edit/${id}`,
    info: (id) => `${baseURL}admin-api/v1/notifications/info/${id}`,
  },
  archiveNotification: {
    list: `${baseURL}admin-api/v1/notifications/archive`,
    restore: `${baseURL}admin-api/v1/notifications/restore`,
  },

  exam: {
    create: `${baseURL}admin-api/v1/exam/new`,
    list: `${baseURL}admin-api/v1/exam/list`,
    all: `${baseURL}admin-api/v1/exam/all`,
    delete: `${baseURL}admin-api/v1/exam/delete`,
    deleteMultiple: `${baseURL}admin-api/v1/exam/deleteMultiple`,
    update: (id) => `${baseURL}admin-api/v1/exam/edit/${id}`,
    info: (id) => `${baseURL}admin-api/v1/exam/info/${id}`,
  },

  module: {
    create: `${baseURL}admin-api/v1/module/new`,
    list: `${baseURL}admin-api/v1/module/list`,
    all: `${baseURL}admin-api/v1/module/all`,
    delete: `${baseURL}admin-api/v1/module/delete`,
    deleteMultiple: `${baseURL}admin-api/v1/module/deleteMultiple`,
    update: (id) => `${baseURL}admin-api/v1/module/edit/${id}`,
    info: (id) => `${baseURL}admin-api/v1/module/info/${id}`,
  },

  carnetStudent: {
    listStudents: `${baseURL}admin-api/v1/classes/notPrepa`,
    list: `${baseURL}admin-api/v1/reportCard/list`,
    info: (id) => `${baseURL}admin-api/v1/reportCard/info/${id}`,
    update: (id) => `${baseURL}admin-api/v1/reportCard/edit/${id}`,
    updateStatus: (id) => `${baseURL}admin-api/v1/reportCard/editStatus/${id}`,
  },

  carnetPrepa: {
    listPrepas: `${baseURL}admin-api/v1/classes/prepa`,
    list: `${baseURL}admin-api/v1/prepaReportCard/list`,
    info: (id, trimestre) =>
      `${baseURL}admin-api/v1/prepaReportCard/info/${id}/${trimestre}`,
    update: (id) => `${baseURL}admin-api/v1/prepaReportCard/edit/${id}`,
    updateStatus: (id) =>
      `${baseURL}admin-api/v1/prepaReportCard/editStatus/${id}`,
  },

  settings: {
    getSettings: `${baseURL}admin-api/v1/generalSetting/load`,
    update: `${baseURL}admin-api/v1/generalSetting/update`,
  },

  overAllAverge: {
    get: (id) => `${baseURL}admin-api/v1/overallAverage/info/${id}`,
    update: (id) => `${baseURL}admin-api/v1/overallAverage/edit/${id}`,
    updateMoyT: (id) =>
      `${baseURL}admin-api/v1/overallAverage/updateMoyT/${id}`,
  },

  generatePdf: {
    student: (id) => `${baseURL}admin-api/v1/reportCard/carnetPdf/${id}`,
    classe: (id) => `${baseURL}admin-api/v1/classes/classePdf/${id}`,
    prepa: (id, tris) =>
      `${baseURL}admin-api/v1/prepaReportCard/carnetPdf/${id}/${tris}`,
  },
};
