import React from "react";
import swal from "sweetalert";
import { useForm, useFieldArray } from "react-hook-form";
import CantineForm from "../../components/Cantine/CantineForm";
import Container from "../../components/Container/Container";
import { API } from "../../constants/env";

const AddCantine = () => {
  const { register, handleSubmit, errors, reset, control } = useForm();
  const { fields, append, remove } = useFieldArray({
    name: "meal",
    control,
  });
  const onSubmit = (data) => {
    if (!data.meal) return swal("Failed!", "ajouter des Menus", "error");
    data.meal = data.meal.map((el) => el.dej);
    fetch(API.cantine.create, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }
        return swal("Good job!", "menu ajouter", "success");
      })
      .catch((e) => {});
  };
  return (
    <Container
      name="Ajouter un Menu"
      back={true}
      backRoute="/cantine"
      submit={true}
    >
      <CantineForm
        fields={fields}
        append={append}
        remove={remove}
        registerRequired={register({ required: true })}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
      />
    </Container>
  );
};

export default AddCantine;
