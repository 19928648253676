import React, { useState } from "react";
import Container from "../../components/Container/Container";
import { API, ROUTER } from "../../constants/env";
import swal from "sweetalert";
import NotifForm from "../../components/Notifications/NotifForm";
import { useForm } from "react-hook-form";
import Loader from "../../components/Loading/loader";

const AddNotif = () => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    setValue,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const onSubmit = ({ file, value, title, subTitle, date, body, type }) => {
    const fd = new FormData();
    const values = value?.map(({ value }) => value);
    for (const elem of files) {
      fd.append("files", elem.file);
    }
    if (type !== "All")
      for (var i = 0; i < values.length; i++) {
        fd.append("value[]", values[i]);
      }
    //console.log(value);
    fd.append("type", type);
    fd.append("title", title);
    fd.append("subTitle", subTitle);
    fd.append("body", body);
    fd.append("date", date);
    // console.log(fd);
    setLoading(true);
    fetch(API.notification.create, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      body: fd,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          setLoading(false);
          return swal("Error in the response", response.message, "error");
        }
        setLoading(false);
        return swal("Good job!", "Notification ajouter", "success");
      })
      .catch((e) => {
        setLoading(false);
        return swal(
          "Error when we try to send a request",
          e.message + "\n Please Check your internet connection",
          "error"
        );
      });
  };
  return (
    <Container
      name="Ajouter Notification"
      back={true}
      backRoute={ROUTER.NOTIFICATION.LIST}
      submit={true}
    >
      {loading && <Loader />}
      {!loading && (
        <NotifForm
          register={register()}
          registerRequired={register({ required: true })}
          errors={errors}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          reset={reset}
          control={control}
          setValue={setValue}
          files={files}
          setFiles={setFiles}
        />
      )}
    </Container>
  );
};

export default AddNotif;
