import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import Container from "../../components/Container/Container";
import ExamForm from "../../components/Exam/ExamForm";
import { API, ROUTER } from "../../constants/env";
import { useHistory } from "react-router-dom";
const EditExam = () => {
  const { id } = useParams();
  const { register, handleSubmit, errors, reset, control, setValue } =
    useForm();
  const [data, setData] = useState(null);
  const history = useHistory();
  useEffect(() => {
    fetch(API.exam.info(id), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }
        console.log(response);
        response.classe = response.classe.map((elem) => ({
          value: elem._id,
          label: elem.name,
        }));
        setData(response);
      })
      .catch((e) => {
        return swal("Error fel request", e.message, "error");
      });
  }, []);

  const onSubmit = (data) => {
    if (data.period) {
      data.startDate = data.date;
      data.date = null;
      data.startHour = null;
      data.endHour = null;
    } else {
      if (data.startDate) data.date = data.startDate;
      data.startDate = null;
      data.endDate = null;
    }
    console.log(data);
    data.classe = data.classe.map((elem) => elem.value);

    fetch(API.exam.update(id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }

        return swal("Good job!", "Type modifier", "success").then(() =>
          history.push(ROUTER.EXAMENS.LIST)
        );
      })
      .catch((e) => {
        return swal("Error fel request", e.message, "error");
      });
  };
  return (
    <Container
      name="Modifier un Examen"
      back={true}
      backRoute={ROUTER.EXAMENS.LIST}
      submit={true}
    >
      <ExamForm
        data={data}
        registerRequired={register({ required: true })}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        control={control}
        setValue={setValue}
      />
    </Container>
  );
};

export default EditExam;
