import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import swal from "sweetalert";
import Select from "react-select";
import AddSeanceModal from "./libs/AddSeanceModal";
import EditSeanceModal from "./libs/EditSeanceModal";
import { API } from "../../../constants/env";
import { Modal, Button } from "react-bootstrap";

function TeacherCalendar(props) {
  const [defaultValues, setDefaultValues] = useState(null);
  const [headerToolbar, setHeaderToolbar] = useState(false);
  const [showAddSeanceModal, setShowAddSeanceModal] = useState(false);
  const [showEditSeanceModal, setShowEditSeanceModal] = useState(false);
  const [calendarAPI, setCalendarAPI] = useState(null);
  const [Teacher, setTeacher] = useState([]); //contains list of teachers
  const [Enseignant, setEnseignant] = useState({ value: null, label: null });
  const [data, setData] = useState([]);
  const [toDeleteEvent, settoDeleteEvent] = useState("");
  useEffect(() => {
    console.log("TeacherCalendar");
    SelectTeacher();
  }, []);
  useEffect(() => {
    console.log("Enseignant is valid => ", Enseignant.value);
    if (Enseignant.value) {
      console.log(
        "TeacherCalendar:Enseignant.value is true => call LoadData L29"
      );
      LoadData(Enseignant.value);
    }
  }, [Enseignant]);

  const handleSelectChangeList = (info) => {
    setEnseignant(info);
    console.log(
      "TeacherCalendar:handleSelectChangeList =>>> setEnseignant L36"
    );
  };
  const LoadData = (id) => {
    fetch(API.teacherTimetable.list(id), {
      //I will replace this by get Teacher TimeTable Seances
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        // console.log("===== We are in the right path=====");
        // console.log(response);
        if (response.error) {
          console.log(response);
          return swal("Error", response.message, "error");
        }

        response = response.map((elem) => ({
          id: elem._id,
          title: `${elem.subject.name} ${elem.subject.level}`, //${elem.subject.level},
          daysOfWeek: [elem.day],
          startTime: elem.startHour.substr(elem.startHour.search("T") + 1, 5),
          endTime: elem.endHour.substr(elem.endHour.search("T") + 1, 5),
          classe: elem.classe.name+" "+elem.classe.level, // + " " + elem.classe.level,
          //Salle: elem.classRoom.name,
        }));
        setData(response);
        console.log("TeacherCalendar:inside LoadData: setData L65");
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  const columnHeaderText = (date) => {
    // To Show Week Days in French
    switch (date.date.getDay()) {
      case 0:
        return "Dimanche";
      case 1:
        return "Lundi";
      case 2:
        return "Mardi";
      case 3:
        return "Mercredi";
      case 4:
        return "Jeudi";
      case 5:
        return "Vendredi";
      case 6:
        return "Samedi";
      default:
        return "Something is wrong";
    }
  };
  const SelectTeacher = () => {
    fetch(API.teachers.all, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          console.log(response);
          return swal("Error", response.message, "error");
        }
        response = response.map(({ _id, profil }) => {
          return {
            value: _id,
            label: profil.firstName + " " + profil.lastName,
          };
        });
        setTeacher(response);
        console.log("TeacherCalendar:inside SelectTeacher => setTeacher L100");
      })
      .catch((e) => {});
  };
  const CustomEventRender = (eventInfo) => {
    return (
      <>
        <p>
          {eventInfo.event.title}
          <br />
          {eventInfo.event.extendedProps.classe}
          {/*eventInfo.event.extendedProps.Salle*/}
          <br />
          {eventInfo.timeText}
        </p>
      </>
    );
  };
  //////////////////////////////////////////////////////////////////////
  //  ===>>> CRUD EVENT <<<===  //
  const addEvent = (addedData) => {
    /* inside this function we will add the seance so we need to:
            -save to data to the DB if it's OK
            -save the data to local storage
            -close the model */
    fetch(API.teacherTimetable.create, {
      //I will replace this by get les salles disponibles
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        day: addedData.days.value,
        startHour: addedData.heureDebut.value,
        endHour: addedData.heureFin.value,
        teacher: Enseignant.value,
        classe: addedData.classe.value,
        subject: addedData.subject.value,
        //classRoom: addedData.salle.value,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          console.log(response);
          return swal("Error", response.message, "error");
        }
        console.log("addEvent -> response", response);
        console.log("addEvent -> addedData", addedData);
        if (response._id) {
          setShowAddSeanceModal(false);
          LoadData(Enseignant.value);
          // if(calendarAPI){ //TODO : TO Delete later ::::
          //     // calendarAPI.addEvent({
          //     //     id:response._id,
          //     //     title: addedData.subject.label,
          //     //     daysOfWeek:[response.day],
          //     //     startTime: addedData.heureDebut.value,
          //     //     endTime: addedData.heureFin.value,
          //     //     classe:addedData.classe.label,
          //     //     Salle:addedData.salle.label,
          //     // })
          //     // let newEvents = data;
          //     // newEvents.push({
          //     //     id:response._id,
          //     //     title: addedData.subject.label,
          //     //     daysOfWeek:[response.day],
          //     //     startTime: addedData.heureDebut.value,
          //     //     endTime: addedData.heureFin.value,
          //     //     classe:addedData.classe.label,
          //     //     Salle:addedData.salle.label,
          //     // })
          //     // setData(newEvents);
          // }else{
          //     alert("Something is Wrong chech calendarAPI in the state");
          // }
        } else {
          return swal("Error", "Something is wrong with your server", "error");
        }
      })
      .catch((e) => {});
  };
  const editEvent = (adData) => {
    /* inside this function we will add the seance so we need to:
            -save to data to the DB if it's OK
            -save the data to local storage
            -close the model */
    console.log("@@@@@@@ FROM editingEvent @@@@@@@");
    console.log("adData=>", adData);
    fetch(API.teacherTimetable.update(adData.id), {
      //I will replace this by get les salles disponibles
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        day: adData.days.value,
        startHour: adData.heureDebut.value,
        endHour: adData.heureFin.value,
        teacher: Enseignant.value,
        classe: adData.classe.value,
        subject: adData.subject.value,
        // classRoom: adData.salle.value,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          console.log(response);

          return swal("Error", response.message, "error");
        }
        console.log("response=>", response);
        if (response._id) {
          toDeleteEvent.remove();
          LoadData(Enseignant.value);
          // if(calendarAPI){ TODO : to delete later
          //     calendarAPI.addEvent({
          //         id:response._id,
          //         title: adData.subject.label,
          //         daysOfWeek:[adData.days.value],
          //         startTime: adData.heureDebut.value,
          //         endTime: adData.heureFin.value,
          //         classe:adData.classe.label,
          //         Salle:adData.salle.label,
          //     })
          //     let newEvents = data;
          //     newEvents.push({
          //         id:response._id,
          //         title: adData.subject.label,
          //         daysOfWeek:[adData.days.value],
          //         startTime: adData.heureDebut.value,
          //         endTime: adData.heureFin.value,
          //         classe:adData.classe.label,
          //         Salle:adData.salle.label,
          //     })
          //     setData(newEvents);
          // }else{
          //     alert("Something is Wrong chech calendarAPI in the state");
          // }
          setShowEditSeanceModal(false);
        } else {
          return swal("Error", "Something is wrong with your server", "error");
        }
      })
      .catch((e) => {});
  };
  const deleteEvent = (data) => {
    /* inside this function we will add the seance so we need to:
            -delete the event from DB if it's OK
            -delete the data to local storage
            -close the model */
    console.log("@@@@@@@ FROM deletingEvent @@@@@@@");
    console.log(data);
    fetch(API.teacherTimetable.remove, {
      //I will replace this by get les salles disponibles
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        id: data,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          console.log(response);

          return swal("Error", response.message, "error");
        }
        console.log("@@@@@ response @@@@@", response);
        if (response._id) {
          toDeleteEvent.remove();
          setShowEditSeanceModal(false);
        } else {
          return swal("Error", "Something is wrong with your server", "error");
        }
      })
      .catch((e) => {});
  };
  //  ===>>> CRUD EVENT <<<===  //
  //))))) Handle Types Of Click (((((
  const handleDateClick = (selectInfo) => {
    if (!Enseignant.value) {
      swal("Attention", "Veuillez selectionner un enseignant", "info");
      return;
    }
    setCalendarAPI(selectInfo.view.calendar);
    let durationSession = 2;
    setDefaultValues({
      day: selectInfo.date.getDay(),
      heureDebut: `${
        selectInfo.date.getHours() < 10
          ? "0" + selectInfo.date.getHours()
          : selectInfo.date.getHours()
      }:${
        selectInfo.date.getMinutes() === 0 ? "00" : selectInfo.date.getMinutes()
      }`,
      heureFin: `${
        selectInfo.date.getHours() + durationSession < 10
          ? "0" + (selectInfo.date.getHours() + durationSession)
          : selectInfo.date.getHours() + durationSession
      }:${
        selectInfo.date.getMinutes() === 0 ? "00" : selectInfo.date.getMinutes()
      }`,
    });
    setShowAddSeanceModal(true);
  };
  const handleEventClick = (clickInfo) => {
    if (!Enseignant.value) {
      swal("Attention", "Veuillez selectionner un enseignant", "info");
      return;
    }
    settoDeleteEvent(clickInfo.event);
    setCalendarAPI(clickInfo.view.calendar);
    let startTime = new Date(
      clickInfo.event._def.recurringDef.typeData.startTime.milliseconds -
        3600000
    );
    let endTime = new Date(
      clickInfo.event._def.recurringDef.typeData.endTime.milliseconds - 3600000
    );
    let defaultValues = {
      id: clickInfo.event._def.publicId,
      title: clickInfo.event._def.title,
      daysOfWeek: clickInfo.event._def.recurringDef.typeData.daysOfWeek,
      startTime:
        (startTime.getHours() < 10
          ? "0" + startTime.getHours()
          : startTime.getHours()) +
        ":" +
        (startTime.getMinutes() === 0 ? "00" : startTime.getMinutes()),
      endTime:
        (endTime.getHours() < 10
          ? "0" + endTime.getHours()
          : endTime.getHours()) +
        ":" +
        (endTime.getMinutes() === 0 ? "00" : endTime.getMinutes()),
      classe: clickInfo.event._def.extendedProps.classe,
      //Salle: clickInfo.event._def.extendedProps.Salle,
    };
    setDefaultValues(defaultValues);
    console.log("handleEventClick -> defaultValues", defaultValues);
    /* TODO: get the necessary information to send them to the modal form
        we need that for default values of the form */
    setShowEditSeanceModal(true);
  };
  //))))) Handle Types Of Click (((((
  /* Just for testing purpuses */
  const handleEvent = (eventInfo) => {
    console.log("handleEvent is Called");
    console.log("=====================");
    console.log(eventInfo.view.calendar);
  };
  return (
    <div className="card card-custom">
      <div className="card-header">
        <div className="card-title">
          <h3 className="card-label">
            {Enseignant.label
              ? `L'emploi de ${Enseignant.label}`
              : "Choisir l'emploi de l'enseignant(e)"}
          </h3>
        </div>
        <div className="Select-timetable">
          <Select options={Teacher} onChange={handleSelectChangeList} />
        </div>
      </div>
      <div className="card-body">
        <FullCalendar
          timeZone="local"
          initialView="timeGridWeek"
          allDaySlot={false}
          height="auto"
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          // editable={false}
          // selectable={false}
          // selectMirror={true}
          businessHours={{
            // days of week. an array of zero-based day of week integers (0=Sunday)
            daysOfWeek: [1, 2, 3, 4, 5, 6], // Monday - Thursday
            startTime: "08:00", // a start time
            endTime: "18:00", // an end time
          }}
          dayHeaderContent={columnHeaderText}
          slotMinTime={"08:00:00"}
          slotMaxTime={"18:00:00"}
          headerToolbar={headerToolbar}
          events={data}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          eventContent={CustomEventRender}
        />
        {/* START AddSeanceModal */}

        {/* END AddSeanceModal */}
        <AddSeanceModal
          show={showAddSeanceModal}
          onHide={setShowAddSeanceModal}
          onAdd={addEvent}
          //Matieres={matieres}
          //Salles={salles}
          //Classes={classes}
          Enseignant={Enseignant}
          defaultValues={defaultValues}
        />
        <EditSeanceModal
          show={showEditSeanceModal}
          onHide={setShowEditSeanceModal}
          onEdit={editEvent}
          onDelete={deleteEvent}
          //Matieres={matieres}
          //Salles={salles}
          //Classes={classes}
          Enseignant={Enseignant}
          defaultValues={defaultValues}
        />
      </div>
    </div>
  );
}

export default TeacherCalendar;
