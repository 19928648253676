import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { useForm, useFieldArray } from "react-hook-form";
import CantineForm from "../../components/Cantine/CantineForm";
import Container from "../../components/Container/Container";
import { API } from "../../constants/env";
import { useParams } from "react-router-dom";

function EditCantine() {
  const { id } = useParams();

  const [data, setData] = useState({});

  const onSubmit = (data) => {
    if (!data.meal) return swal("Failed!", "ajouter des Menus", "error");
    data.meal = data.meal.map((el) => el.dej);
    fetch(API.cantine.update(id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good job!", "Menu modifie", "success");
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };
  const { register, handleSubmit, errors, reset, control } = useForm({
    defaultValues: {
      meal: [],
    },
  });

  const { fields, append, remove, insert } = useFieldArray({
    name: "meal",
    control,
  });

  useEffect(() => {
    const fetchdata = async () => {
      fetch(API.cantine.info(id), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }
          response.meal.map((dej, index) => insert(index, { dej }));
          setData(response);
        })
        .catch((e) => {
          return swal("Error", e.message, "error");
        });
    };
    fetchdata();
  }, []);

  return (
    <Container
      name="Modifier un Menu"
      back={true}
      backRoute="/cantine"
      submit={true}
    >
      <CantineForm
        fields={fields}
        append={append}
        remove={remove}
        registerRequired={register({ required: true })}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        data={data}
      />
    </Container>
  );
}

export default EditCantine;
