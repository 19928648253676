import React from "react";
import moment from "moment";
import { STATIC } from "../../constants/env";
import { Table } from "react-bootstrap";

const BillTable = ({ payments, fieldName, deleteBill }) => {
  const champs = [
    { title: "Nom", field: "name" },
    { title: "Prix (TND)", field: "price" },
    { title: "Periode", field: "period" },
    { title: "Date", field: "created_at" },
  ];

  return (
    <Table
      borderless
      cellSpacing={5}
      className="m-auto w-75"
      size="lg"
      centered
    >
      <thead>
        <tr>
          {champs.map(({ title, field }, index) => (
            <th key={index}>{title}</th>
          ))}
          {deleteBill && <th>Action</th>}
        </tr>
      </thead>
      <tbody>
        {payments?.map((obj, index) => (
          <tr key={index}>
            {champs.map(({ field }, index) => {
              if (field === "created_at")
                return (
                  <td key={index}>
                    {moment(obj[field] ? obj[field] : obj._id[field]).format(
                      "MMM Do YY"
                    )}
                  </td>
                );
              if (field === "period") {
                return (
                  <td key={index}>
                    {
                      STATIC.PERIODE().find((per) => per.value === obj[field])
                        .option
                    }
                  </td>
                );
              }
              return (
                <td key={index}>{obj[field] ? obj[field] : obj._id[field]}</td>
              );
            })}
            {deleteBill && (
              <td>
                <button
                  className="btn btn-icon btn-light btn-hover-danger"
                  onClick={() => deleteBill(obj, fieldName)}
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      <title>Supprimer</title>
                      <desc>Created with Sketch.</desc>
                      <defs />
                      <g
                        id="Stockholm-icons-/-General-/-Trash"
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect id="bound" x={0} y={0} width={24} height={24} />
                        <path
                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                          id="round"
                          fill="#000000"
                          fillRule="nonzero"
                        />
                        <path
                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                          id="Shape"
                          fill="#000000"
                          opacity="0.3"
                        />
                      </g>
                    </svg>
                  </span>
                </button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default BillTable;
