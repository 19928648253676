import React from "react";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import ParentForm from "../../components/Parent/ParentForm";
import Container from "../../components/Container/Container";
import { API } from "../../constants/env";
function AddUser() {
  const { register, handleSubmit, errors, reset } = useForm();
  const onSubmit = (data) => {
    // data.adress = {
    //   adress: data.adress,
    //   city: data.city,
    //   zipCode: data.zipCode,
    // };
    // delete data.city;
    // delete data.zipCode;
    let fd = new FormData();
    if (data.file.length > 0) {
      fd.append("file", data.file[0]);
    }
    fd.append("email", data.email);
    fd.append("firstName", data.firstName);
    fd.append("lastName", data.lastName);
    fd.append("userName", data.userName);
    fd.append("password", data.password);
    fd.append("adress", data.adress);
    fd.append("phone", data.phone);
    fd.append("sexe", data.sexe);
    fd.append("country", data.country);
    fd.append("numCin", data.numCin);
    fd.append("profession", data.profession);

    fetch(API.parents.create, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      body: fd,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }
        return swal("Good job!", "Parent ajouté", "success");
      })
      .catch((e) => {});
  };
  return (
    <Container
      name="Ajouter un parent"
      back={true}
      backRoute="/parents"
      submit={true}
    >
      <ParentForm
        registerRequired={register({ required: true })}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
      />
    </Container>
  );
}

export default AddUser;
