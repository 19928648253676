import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Login from "./pages/Login/Login";
import SideBar from "./components/SideBar/SideBar";
import TopBar from "./components/TopBar/TopBar";
import Footer from "./components/Footer/Footer";
import { routes } from "./constants/routes";
import SubHeader from "./components/SubHeader/SubHeader";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: localStorage.getItem("token"),
    };
    console.log("env => ", process.env);
    localStorage.setItem(
      "currentPage",
      JSON.stringify({
        currentPage: 1,
        page: "",
        count: 30,
        idClasse: "",
      })
    );
  }

  render() {
    return this.state.authenticated ? (
      <BrowserRouter>
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <SideBar />
            {/*<!--begin::Wrapper-->*/}
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <TopBar />
              <SubHeader /> {/**TODO : No Need To This */}
              <Switch>
                {routes.map((elem, index) => {
                  return (
                    <Route
                      key={index}
                      path={elem.path}
                      exact={elem.exact}
                      component={elem.main}
                    />
                  );
                })}
              </Switch>
              <Footer />
            </div>
            {/*<!--end::Wrapper-->*/}
            {/* <UserPanel logout={logout}/> */}
          </div>
        </div>
      </BrowserRouter>
    ) : (
      <div
        id="kt_body"
        className="header-fixed header-mobile-fixed subheader-enabled subheader-fixed subheader-mobile-fixed aside-enabled aside-fixed aside-minimize-hoverable page-loading"
      >
        <BrowserRouter forceRefresh={true}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route render={() => <Redirect to="/login" />} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
