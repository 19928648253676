import React from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import Container from "../../components/Container/Container";
import ExamForm from "../../components/Exam/ExamForm";
import { API, ROUTER } from "../../constants/env";

const AddExam = () => {
  const { register, handleSubmit, errors, reset, control } = useForm();

  const onSubmit = (data) => {
    if (data.period) {
      data.startDate = data.date;
      data.date = null;
      data.startHour = null;
      data.endHour = null;
    } else {
      data.startDate = null;
      data.endDate = null;
    }
    data.levels = data.levels.map((elem) => elem.value);
    //console.log(data);

    fetch(API.exam.create, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel Srv", response.message, "error");
        }

        return swal("Good job!", "examen ajoutée", "success");
      })
      .catch((e) => {
        return swal("Error fel request", e.message, "error");
      });
  };

  return (
    <Container
      name="Ajouter un Examen"
      back={true}
      backRoute={ROUTER.EXAMENS.LIST}
      submit={true}
    >
      <ExamForm
        registerRequired={register({ required: true })}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        control={control}
      />
    </Container>
  );
};

export default AddExam;
