import React, { useEffect, useState } from "react";

const SettingsForm = (props) => {
  const [check, setCheck] = useState();
  const [checkPrepa, setCheckPrepa] = useState();
  console.log(props.data);
  useEffect(() => {
    props?.data?.showCard === "false" && setCheck() && setCheckPrepa();
    props?.data?.showCard === "prepa" && setCheckPrepa(true);
    props?.data?.showCard === "primary" && setCheck(true);
    props?.data?.showCard === "all" && setCheck(true) && setCheckPrepa(true);
  }, [props.data]);
  return (
    <div className="card card-custom">
      {/*begin::Form*/}

      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          <div className="form-group">
            <label>
              - Afficher les Carnets primary pour les parents?{" "}
              <span className="text-danger"> </span>
            </label>
            <input
              ref={props.register}
              name="showCard"
              type="checkBox"
              className="form-group"
              style={{ width: 20, height: 20, marginLeft: 40 }}
              onChange={(e) => setCheck(e.target.checked)}
              defaultChecked={check}
            />
          </div>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label>
              - Afficher les Carnets prepa pour les parents?{" "}
              <span className="text-danger"> </span>
            </label>
            <input
              ref={props.register}
              name="showCardPrepa"
              type="checkBox"
              className="form-group"
              style={{ width: 20, height: 20, marginLeft: 40 }}
              onChange={(e) => setCheckPrepa(e.target.checked)}
              defaultChecked={checkPrepa}
            />
          </div>
        </div>
        <div className="card-footer">
          <button type="submit" className="btn btn-primary mr-2">
            Valider
          </button>
        </div>
      </form>

      {/*end::Form*/}
    </div>
  );
};

export default SettingsForm;
