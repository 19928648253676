import React, { useEffect, useState } from "react";
import { API, STATIC } from "../../constants/env";
import swal from "sweetalert";
import moment from "moment";
import Select from "react-select";
import { Controller } from "react-hook-form";
const ExamForm = (props) => {
  const [levels, setLevels] = useState([
    {
      value: "Prepa1",
      label: "Prepa1",
    },
    {
      value: "Prepa2",
      label: "Prepa2",
    },
    {
      value: "Prepa3",
      label: "Prepa3",
    },
    {
      value: "Prepa4",
      label: "Prepa4",
    },
    {
      value: "1erA",
      label: "1erA",
    },
    {
      value: "1erB",
      label: "1erB",
    },
    {
      value: "1erC",
      label: "1erC",
    },
    {
      value: "1erD",
      label: "1erD",
    },
    {
      value: "1erE",
      label: "1erE",
    },
    {
      value: "1erF",
      label: "1erF",
    },
    {
      value: "2emeA",
      label: "2emeA",
    },
    {
      value: "2emeB",
      label: "2emeB",
    },
    {
      value: "2emeC",
      label: "2emeC",
    },
    {
      value: "2emeD",
      label: "2emeD",
    },
    {
      value: "2emeE",
      label: "2emeE",
    },
    {
      value: "2emeF",
      label: "2emeF",
    },
    {
      value: "3emeA",
      label: "3emeA",
    },
    {
      value: "3emeB",
      label: "3emeB",
    },
    {
      value: "3emeC",
      label: "3emeC",
    },
    {
      value: "3emeD",
      label: "3emeD",
    },
    {
      value: "3emeE",
      label: "3emeE",
    },
    {
      value: "4emeA",
      label: "4emeA",
    },
    {
      value: "4emeB",
      label: "4emeB",
    },
    {
      value: "4emeC",
      label: "4emeC",
    },
    {
      value: "4emeD",
      label: "4emeD",
    },
    {
      value: "4emeE",
      label: "4emeE",
    },
    {
      value: "5emeA",
      label: "5emeA",
    },
    {
      value: "5emeB",
      label: "5emeB",
    },
    ,
    {
      value: "5emeC",
      label: "5emeC",
    },
    ,
    {
      value: "5emeD",
      label: "5emeD",
    },
    ,
    {
      value: "5emeE",
      label: "5emeE",
    },
    ,
    {
      value: "6emeA",
      label: "6emeA",
    },
    ,
    {
      value: "6emeB",
      label: "6emeB",
    },
    ,
    {
      value: "6emeC",
      label: "6emeC",
    },
    ,
    {
      value: "6emeD",
      label: "6emeD",
    },
    {
      value: "6emeE",
      label: "6emeE",
    },
  ]);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [period, setPeriod] = useState(false);

  useEffect(() => {
    const fetchClasseData = () => {
      fetch(API.classes.all, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }
          response = response.map((classe) => ({
            value: classe._id,
            label: classe.name,
          }));
          setClasses(response);
        })
        .catch((e) => {
          return swal("Error", e.message, "error");
        });
      fetch(API.subjects.all, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }
          response = response.map((subject) => ({
            value: subject._id,
            option: `${subject.level}:  ${subject.name}`,
          }));
          setSubjects(response);
        })
        .catch((e) => {
          return swal("Error", e.message, "error");
        });
    };

    fetchClasseData();
  }, []);

  useEffect(() => {
    if (props.data) {
      setPeriod(props.data.period);
    }
  }, [props.data]);
  useEffect(() => {
    if (props.data) {
      if (props.data.classe) {
        props.setValue(
          "classe",
          props.data?.classe
            ? props.data.classe.map((elem) => ({
                value: elem.value,
                label: elem.label,
              }))
            : {}
        );
      }
    }
  }, [props]);

  return (
    <div className="card card-custom">
      {/*begin::Form*/}
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          <div className="row">
            <div className="col-4 form-group">
              <label>
                La Matiére <span className="text-danger"> *</span>
              </label>
              <select
                className="form-control"
                ref={props.register}
                name="subject"
                placeholder="Selectionnez une classe"
              >
                {subjects.map(({ option, value }, index) => {
                  if (props.data) {
                    if (value === props.data.subject._id) {
                      return (
                        <option selected value={value} key={index}>
                          {option}
                        </option>
                      );
                    } else {
                      return (
                        <option value={value} key={index}>
                          {option}
                        </option>
                      );
                    }
                  } else {
                    return (
                      <option value={value} key={index}>
                        {option}
                      </option>
                    );
                  }
                })}
              </select>
            </div>

            <div className="col-4 form-group">
              <label>
                Niveau <span className="text-danger"> *</span>
              </label>

              <Controller
                control={props.control}
                as={
                  <Select
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                }
                options={levels}
                name="levels"
                isClearable
                isMulti
              />
            </div>
            <div className="col-4">
              <label>
                Triméstre <span className="text-danger"> *</span>
              </label>
              <select
                className="form-control"
                ref={props.register}
                name="semester"
                placeholder="Selectionnez une Semester"
              >
                {STATIC.PERIODE("Annuel").map(({ option, value }, index) => {
                  if (props.data) {
                    if (value === props.data?.semester) {
                      return (
                        <option selected value={value} key={index}>
                          {option}
                        </option>
                      );
                    } else {
                      return (
                        <option value={value} key={index}>
                          {option}
                        </option>
                      );
                    }
                  } else {
                    return (
                      <option value={value} key={index}>
                        {option}
                      </option>
                    );
                  }
                })}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-2">
              <label>Par period ?</label>
              <input
                onChange={(e) => setPeriod(e.currentTarget.checked)}
                ref={props.register}
                name="period"
                type="checkbox"
                style={{ width: 30, height: 30, marginLeft: 30 }}
                className="form-control"
                defaultChecked={props.data?.period}
              />
            </div>
            <div className="form-group col-5">
              <label>
                {period ? "Start" : "Date"}
                <span className="text-danger"> *</span>
              </label>
              <input
                ref={props.registerRequired}
                name="date"
                type="date"
                className="form-control"
                placeholder="Entrer une date"
                defaultValue={
                  props.data
                    ? moment(
                        period ? props.data.startDate : props.data.date
                      ).format("yyyy-MM-DD")
                    : ""
                }
              />
              {props.errors?.date && (
                <span className="text-danger">
                  {period ? "Start" : "Date"} est requis
                </span>
              )}
            </div>
            {period && (
              <div className="form-group col-5">
                <label>
                  End
                  <span className="text-danger"> *</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="endDate"
                  type="date"
                  className="form-control"
                  placeholder="Entrer une date"
                  defaultValue={
                    props.data
                      ? moment(props.data.endDate).format("yyyy-MM-DD")
                      : ""
                  }
                />
                {props.errors?.endDate && (
                  <span className="text-danger">End est requis</span>
                )}
              </div>
            )}
            {!period && (
              <React.Fragment>
                <div className="form-group col-2">
                  <label>
                    De
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    ref={props.registerRequired}
                    name="startHour"
                    type="text"
                    className="form-control"
                    placeholder="hh  :  mm"
                    defaultValue={props.data?.startHour}
                  />
                  {props.errors?.startHour && (
                    <span className="text-danger">Requis</span>
                  )}
                </div>
                <div className="form-group col-2">
                  <label>
                    Jusqu'à
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    ref={props.registerRequired}
                    name="endHour"
                    type="text"
                    className="form-control"
                    placeholder="hh  :  mm"
                    defaultValue={props.data?.endHour}
                  />
                  {props.errors?.endHour && (
                    <span className="text-danger">Requis</span>
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>

        <div className="card-footer">
          <button type="submit" className="btn btn-primary mr-2">
            Valider
          </button>
          <button
            type="reset"
            onClick={() => props.reset()}
            className="btn btn-secondary"
          >
            <i className="fa fa-redo"></i>reset
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
};

export default ExamForm;
