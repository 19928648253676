import { translateRect } from "@fullcalendar/react";
import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = () => {
  return (
    <div
      className="card card-custom"
      style={{ height: "80vh", position: "relative" }}
    >
      <div
        style={{
          position: "absolute",
          top: " 50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <Spinner
          animation="border"
          role="status"
          variant="primary"
          className="mx-10"
        ></Spinner>
        <h6>Please wait...</h6>
      </div>
    </div>
  );
};

export default Loader;
