/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Container from "../../components/Container/Container";
import Table from "../../components/Table/Table";
import { API, ROUTER } from "../../constants/env";
import swal from "sweetalert";

function ListUsers() {
  const fields = [
    { title: "Nom de matiere", field: "name" },
    { title: "Coefficient", field: "coefficient" },
    { title: "Niveau", field: "level" },
  ];
  const [data, setData] = React.useState([]);
  const fetchData = (
    currentPage,
    count,
    search,
    setTotalPage,
    setTotal,
    idClasse,
    termestre,
    level
  ) => {
    fetch(
      API.subjects.list +
        `?page=${currentPage}&count=${count}&searchQuery=${search}&level=${level}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        const result = response.data.map((elem) => ({
          _id: elem._id,
          name: elem.name,
          coefficient: elem.coefficient,
          level: elem.level.map((elem, i) => [
            i > 0 && ", ",
            <span>{elem} </span>,
          ]),
        }));
        setData(result);
        setTotalPage(response.totalPage);
        setTotal(response.total);
      })
      .catch((e) => {});
  };

  return (
    <Container
      name="Liste des matières"
      actionRoute={ROUTER.SUBJECTS.CREATE}
      actionName="Ajouter une matière"
    >
      <Table
        pageName="sub"
        name="Les Matières"
        fields={fields}
        data={data}
        editRoute={(id) => ROUTER.SUBJECTS.EDIT(id)}
        deleteApiRoute={API.subjects.remove}
        deleteMultiple={API.subjects.deleteMultiple}
        fetchData={fetchData}
        showSearch={true}
        Level={true}
      />
    </Container>
  );
}

export default ListUsers;
