/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import swal from "sweetalert";
import { API, STATIC } from "../../constants/env";
import { Button, Modal, Alert, Card, Table } from "react-bootstrap";
function ModalSeeMore({ id }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [data, setData] = useState({});
  const fields = [
    { title: "Photo du parent", field: "picture" },
    { title: "Prénom", field: "firstName" },
    { title: "Nom", field: "lastName" },
    { title: "Email", field: "email" },
    { title: "enfant(s)", field: "children" },
    { title: "Username", field: "userName" },
    { title: "Mot de passe", field: "password" },
    { title: "Tél.", field: "phone" },
    { title: "Adresse", field: "adress" },
    { title: "Sexe", field: "sexe" },
    { title: "CIN", field: "numCin" },
    { title: "Profession", field: "profession" },
  ];
  const fetchData = () => {
    fetch(API.parents.info(id), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        console.log(response);

        response = {
          picture: (
            <img
              alt="parent_pic"
              src={STATIC.ROUTE(response.profil.picture)}
              className="rounded imgWidthThumbnail"
            />
          ),
          firstName: response.profil.firstName,
          children: response.childrens?.reduce((src, child) => {
            return `${src} ${child.profil.firstName} ${child.profil.lastName} ,`;
          }, ""),
          lastName: response.profil.lastName,
          email: response.email,
          userName: response.profil.userName,
          phone: response.profil.phone,
          adress: response.profil.adress,
          sexe: response.profil.sexe,
          password: response.profil.password,
          numCin: response.numCin,
          profession: response.profession,
        };

        setData(response);
      })
      .catch((e) => {});
  };
  // End Fetch
  const handleDataShow = () => {
    fetchData();
    handleShow();
  };
  const RenderShowScreen = (data, fields) => {
    if (data) {
      let result = (
        <>
          <Modal.Header style={{ "align-self": "center" }}>
            <Modal.Title id="contained-modal-title-vcenter">
              {data.sexe === "femme"
                ? `Madame ${data.firstName} ${data.lastName}`
                : `Monsieur ${data.firstName} ${data.lastName}`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body centered>
            <Card>
              <Table striped bordered hover>
                <tbody>
                  {fields.map(({ title, field }) => {
                    return (
                      <tr>
                        <td>
                          <b>{title} :</b>
                        </td>
                        <td className="text-center">{data[field]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </Modal.Body>
        </>
      );
      return result;
    } else {
      return (
        <>
          <Modal.Body>
            <Alert variant="danger">
              Désolé !!! Pas d'informations en ce moment sur cette personne
            </Alert>
          </Modal.Body>
        </>
      );
    }
  };
  return (
    <>
      <Button variant="btn btn-primary mr-2" onClick={handleDataShow}>
        {`Voir Plus`}
      </Button>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        {RenderShowScreen(data, fields)}

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalSeeMore;
