import React, { useState } from "react";
import Container from "../../components/Container/Container";
import Table from "../../components/Table/Table";
import { API, STATIC, ROUTER } from "../../constants/env";
import swal from "sweetalert";
import ModalStudent from "./ModalStudent";

function ListStudents() {
  const fields = [
    { title: "Photo", field: "picture" },
    { title: "Prénom", field: "firstName" },
    { title: "Nom", field: "lastName" },
    { title: "Matricule", field: "matricule" },
    { title: "Classe", field: "classe" },
    { title: "Parent", field: "parent" },
    { title: "Adresse", field: "adress" },
    { title: "Voir plus.", field: "voirPlus" },
  ];
  const [data, setData] = useState([]);

  const fetchData = (
    currentPage,
    count,
    search,
    setTotalPage,
    setTotal,
    idClasse
  ) => {
    fetch(
      API.students.list +
        `?page=${currentPage}&count=${count}&searchQuery=${search}&filterByClasse=${idClasse}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        const result = response.data.map((elem) => ({
          _id: elem._id,
          picture: (
            <img
              alt="student_pic"
              src={STATIC.ROUTE(elem.profil.picture)}
              className="rounded imgWidthThumbnail"
            />
          ),
          firstName: elem.profil.firstName,
          lastName: elem.profil.lastName,
          matricule: elem.matricule ? elem.matricule : "",
          classe: elem.classe ? elem.classe.name : "**Non défini**",
          parent: elem.parent
            ? elem.parent.map(({ profil }) => {
                return `${profil.firstName}, `;
              })
            : "**Non défini**",
          voirPlus: <ModalStudent student={elem} />,
          adress: elem.profil.adress,
        }));
        setData(result);
        setTotalPage(response.totalPage);
        setTotal(response.total);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };
  return (
    <Container
      name="Liste des élèves"
      actionRoute={ROUTER.STUDENTS.CREATE}
      actionName="Ajouter un élève"
    >
      <Table
        pageName="std"
        name="Les élèves"
        classeApi={API.classes.all}
        fields={fields}
        data={data}
        editRoute={(id) => ROUTER.STUDENTS.EDIT(id)}
        deleteApiRoute={API.students.remove}
        deleteMultiple={API.students.deleteMultiple}
        fetchData={fetchData}
        showSearch={true}
      />
    </Container>
  );
}

export default ListStudents;
