import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import AbsenceForm from "../../components/Absences/AbsenceForm";
import Container from "../../components/Container/Container";
import { API, ROUTER } from "../../constants/env";

const EditAbsence = () => {
  const { register, handleSubmit, errors, reset } = useForm();
  const [absensesList, setAbsencesList] = useState([]);
  const [punitionList, setPunitionList] = useState([]);
  const [reg, setReg] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetch(API.absences.info(id), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }
        const date = new Date(response.date);
        response.date =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) +
          "-" +
          (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());

        response.title === "Absence"
          ? setAbsencesList([response.student._id])
          : setPunitionList([response.student._id]);

        setReg(response);
      })
      .catch((e) => {
        return swal("Error fel request", e.message, "error");
      });
  }, []);

  const onSubmit = ({ classe, date, subject }) => {
    if (absensesList.length && punitionList.length) {
      return swal("Error fel request", "Choississez une seule option", "error");
    }
    const infos = subject.split(" ");
    const body = {
      title: absensesList[0] ? "Absence" : "Punition",
      date,
      subject: infos[0],
      classe,
      teacher: infos[1],
      student: absensesList[0] ? absensesList[0] : punitionList[0],
    };

    fetch(API.absences.update(reg._id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }
        return swal("Good job!", "updated Suss", "success");
      })
      .catch((e) => {
        return swal("Error fel request", e.message, "error");
      });
  };

  return (
    <Container
      name="Modifier  A/P"
      back={true}
      backRoute={ROUTER.ABSENCE.LIST}
      submit={true}
    >
      <AbsenceForm
        absences={absensesList}
        punitions={punitionList}
        setAbsences={setAbsencesList}
        setPunitions={setPunitionList}
        register={register()}
        registerRequired={register({ required: true })}
        errors={errors}
        data={reg}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
      />
    </Container>
  );
};

export default EditAbsence;
