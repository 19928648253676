import React from "react";

function UserForm(props) {
  return (
    <div className="card card-custom">
      {/*begin::Form*/}
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          <div className="form-group">
            <label>
              Email address <span className="text-danger">*</span>
            </label>
            <input
              ref={props.register}
              name="email"
              type="email"
              className="form-control"
              placeholder="Enter email"
            />
            {props.errors.username && (
              <span className="text-danger">username required</span>
            )}
          </div>
          <div className="form-group">
            <label>
              First Name <span className="text-danger">*</span>
            </label>
            <input
              ref={props.register}
              name="fname"
              type="text"
              className="form-control"
              placeholder="Enter fname"
            />
            {props.errors.fname && (
              <span className="text-danger">fname required</span>
            )}
          </div>
          <div className="form-group">
            <label>
              Last Name <span className="text-danger">*</span>
            </label>
            <input
              ref={props.register}
              name="lname"
              type="text"
              className="form-control"
              placeholder="Enter last name"
            />
            {props.errors.lname && (
              <span className="text-danger">lname required</span>
            )}
          </div>
          <div className="form-group">
            <label>
              website <span className="text-danger">*</span>
            </label>
            <input
              ref={props.register}
              name="website"
              type="text"
              className="form-control"
              placeholder="Enter wehsite"
            />
            {props.errors.website && (
              <span className="text-danger">website required</span>
            )}
          </div>
          <div className="form-group">
            <label>
              address <span className="text-danger">*</span>
            </label>
            <input
              ref={props.register}
              name="address"
              type="text"
              className="form-control"
              placeholder="Enter adress"
            />
            {props.errors.address && (
              <span className="text-danger">address required</span>
            )}
          </div>
        </div>
        <div className="card-footer">
          <button type="submit" className="btn btn-primary mr-2">
            Valider
          </button>
          <button
            type="reset"
            onClick={() => props.reset()}
            className="btn btn-secondary"
          >
            <i className="fa fa-redo"></i>reset
          </button>
        </div>
        ;
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default UserForm;
