import React, { useEffect, useState } from "react";

const TypesForm = (props) => {
  const [defaultColor, setDefaultColor] = useState("#2980b9");

  useEffect(() => {
    if (props.data) {
      setDefaultColor(props.data.color);
    }
  }, [props.data]);
  return (
    <div className="card card-custom">
      {/*begin::Form*/}
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          <div className="row">
            <div className="form-group col-6">
              <label>
                Type
                <span className="text-danger"> *</span>
              </label>
              <input
                ref={props.registerRequired}
                name="title"
                type="text"
                defaultValue={props.data?.title}
                className="form-control"
                placeholder="Enter un type"
              />
              {props.errors.type && (
                <span className="text-danger">Type est requis</span>
              )}
            </div>
            <div className="col-4">
              <div>
                <label>
                  Spécifique selon la classe ?
                </label>
              </div>
              <input
                ref={props.register}
                name="perClasse"
                type="checkbox"
                className="m-1"
                defaultChecked={props.data?.perClasse}
                style={{ height: 30, width: 30 }}
              />
            </div>
          </div>

          <div className="form-group">
            <label>
              Selectionnez une couleur
              <span className="text-danger"> *</span>
            </label>
            <input
              onChange={(e) => setDefaultColor(e.target.value)}
              ref={props.registerRequired}
              name="color"
              type="color"
              value={defaultColor}
              style={{ padding: 0, width: 50, height: 50, borderRadius:0 }}
              className="form-control"
              placeholder="Enter un type"
            />
            {props.errors?.color && (
              <span className="text-danger">Couleur est requis</span>
            )}
          </div>
        </div>
        <div className="card-footer">
          <button type="submit" className="btn btn-primary mr-2">
            Valider
          </button>
          <button
            type="reset"
            onClick={() => props.reset()}
            className="btn btn-secondary"
          >
            <i className="fa fa-redo"></i>reset
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
};

export default TypesForm;
