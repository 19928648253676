import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import Container from "../../../components/Container/Container";
import TypesForm from "../../../components/Types/TypesForm";
import { API, ROUTER } from "../../../constants/env";

const EditType = () => {
  const { id } = useParams();
  const { register, handleSubmit, errors, reset } = useForm();

  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(API.actionTypes.info(id), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }

        setData(response);
      })
      .catch((e) => {
        return swal("Error fel request", e.message, "error");
      });
  }, []);

  const onSubmit = (data) => {
    fetch(API.actionTypes.update(id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }

        return swal("Good job!", "Type modifier", "success");
      })
      .catch((e) => {
        return swal("Error fel request", e.message, "error");
      });
  };

  return (
    <Container
      name="Modifier un Type"
      back={true}
      backRoute={ROUTER.ActionTYPES.LIST}
      submit={true}
    >
      <TypesForm
        data={data}
        registerRequired={register({ required: true })}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
      />
    </Container>
  );
};

export default EditType;
