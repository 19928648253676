import React, { useEffect, useState } from "react";
import Container from "../../components/Container/Container";
import TableArchive from "../../components/TableArchive/TableArchive";
import { API, ROUTER } from "../../constants/env";
import swal from "sweetalert";

const ArchiveNotification = () => {
  const fields = [
    { title: "titre", field: "title" },
    { title: "sous-titre", field: "subtitle" },
    { title: "description", field: "body" },
    { title: "date", field: "date" },
    { title: "destinataire", field: "receiver" },
  ];

  const [data, setData] = useState([]);

  const fetchData = (currentPage, count, search, setTotalPage, setTotal) => {
    fetch(
      API.archiveNotification.list +
        `?page=${currentPage}&count=${count}&searchQuery=${search}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        const result = response.data.map((elem) => ({
          _id: elem._id,
          title: elem.title,
          subtitle: elem.subTitle,
          body: elem.body,
          date: elem.date,
          receiver: getReceiver(elem),
        }));
        setData(result);
        setTotalPage(response.totalPage);
        setTotal(response.total);
      })
      .catch((e) => {
        swal("Error", e.message, "error");
      });
  };

  const getReceiver = ({ type, classes, students, levels }) => {
    switch (type) {
      case "éléves":
        return students.reduce((str, student) => {
          return `${str} ${student.profil.firstName} ${student.profil.lastName},`;
        }, "éléves :");
      case "niveaux":
        return levels.reduce((str, level) => {
          return `${str} ${
            level === "Prepa"
              ? "Préparatoire"
              : level === "1"
              ? level + "ere"
              : level + "eme"
          },`;
        }, "Niveaux :");
      case "classes":
        return classes.reduce((str, classe) => {
          return `${str} ${classe.name},`;
        }, "Classes :");
      default:
        return "Tout l'école";
    }
  };

  return (
    <Container
      name="Archive Notifications"
      back={true}
      backRoute={ROUTER.NOTIFICATION.LIST}
    >
      <TableArchive
        name="Liste des notifications archivées"
        fields={fields}
        pageName="notif"
        data={data}
        fetchData={fetchData}
        showSearch={true}
        retoreApiRoute={API.archiveNotification.restore}
      />
    </Container>
  );
};

export default ArchiveNotification;
