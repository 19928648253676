import React,{useState} from "react";
import swal from "sweetalert";
import Container from "../../components/Container/Container";
import TeacherCalendar from './TeacherComponents/TeacherCalendar';
import {API} from "../../constants/env";

function Test1() {
  return (
    <Container name="Les Emplois du temps">
      <TeacherCalendar />
    </Container>
  );
}

export default Test1;
