import "moment/locale/fr";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import swal from "sweetalert";
import { API, STATIC } from "../../constants/env";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
const NotifForm = (props) => {
  const NotifReceiver = [
    { option: "Toute l'école", value: "All" },
    { option: "Classes", value: "classes" },
    { option: "Niveaux", value: "niveaux" },
    { option: "éléves", value: "éléves" },
  ];

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [receiver, setReceiver] = useState("All");
  const [file, setFile] = useState(null);

  const setDate = (api) => {
    setLoading(!loading);
    fetch(api, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          setLoading(!loading);
          return swal("Error", response.message, "error");
        }

        response = response.map(({ _id, name, profil }) => ({
          value: _id,
          label: `${profil ? profil.firstName + " " + profil.lastName : name}`,
        }));

        setList(response);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(!loading);
        return swal("Error", e.message, "error");
      });
  };
  useEffect(() => {
    switch (receiver) {
      case "classes":
        setDate(API.classes.all);
        if (props.data && props.data.type === receiver) fillTable("classes");
        else {
          props.setValue("value", null);
        }
        break;
      case "éléves":
        setDate(API.students.all);
        if (props.data && props.data.type === receiver) fillTable("students");
        else {
          props.setValue("value", null);
          //ae
        }
        break;
      case "niveaux":
        if (props.data && props.data.type === receiver) fillTable("levels");
        else {
          props.setValue("value", null);
        }
        setList(
          STATIC.LEVELS.map(({ label, value }) => {
            return { label, value };
          })
        );
        break;
      default:
        break;
    }
  }, [receiver]);

  const fillTable = (dest) => {
    props.setValue(
      "value",
      props.data[dest].map((res) => ({
        value: res._id ? res._id : res,
        label: `${
          res.profil
            ? res.profil.firstName + " " + res.profil.lastName
            : res.name
            ? res.name
            : STATIC.LEVELS.find((level) => level.value === res).label
        }`,
      }))
    );
  };
  useEffect(() => {
    if (props.data) {
      setReceiver(props.data.type);
    }
  }, [props]);

  const getDate = () => {
    const mom = moment(props.data?.date || new Date()).locale("fr");
    return `${mom.format("dddd").substr(0, 3)} ${mom.format("ll")}`;
  };
  const getLists = () => {
    if (receiver !== "All")
      return (
        <div className="form-group">
          <label>
            Les {receiver} <span className="text-danger">*</span>
          </label>
          <Controller
            control={props.control}
            as={
              <Select
                className="basic-multi-select"
                classNamePrefix="select"
                isMulti
                isDisabled={loading}
                isLoading={loading}
              />
            }
            options={list}
            onChange={([selected]) => {
              return { value: selected };
            }}
            name="value"
            isClearable
            isMulti
            rules={{ required: true }}
            errors={props.errors.list}
          />
          {props.errors.value && (
            <span className="text-danger">list est requis</span>
          )}
        </div>
      );
  };

  return (
    <div className="card card-custom">
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <label>
                Pour qui?
                <span className="text-danger"> *</span>
              </label>
              <select
                className="form-control"
                ref={props.register}
                name="type"
                placeholder="Selectionnez..."
                onChange={(e) => {
                  setReceiver(e.target.value);
                }}
              >
                {NotifReceiver.map(({ option, value }, index) => {
                  if (props.data) {
                    if (value === props.data.type) {
                      return (
                        <option selected value={value} key={index}>
                          {option}
                        </option>
                      );
                    } else {
                      return (
                        <option value={value} key={index}>
                          {option}
                        </option>
                      );
                    }
                  } else {
                    return (
                      <option value={value} key={index}>
                        {option}
                      </option>
                    );
                  }
                })}
              </select>
            </div>
            <div className="col-6">{getLists()}</div>

            <div className="col-6">
              <div className="form-group">
                <label>
                  Titre <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="title"
                  type="text"
                  className="form-control"
                  placeholder="Enter le Titre"
                  defaultValue={props.data?.title}
                />
                {props.errors.title && (
                  <span className="text-danger">Le Titre est requis</span>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>
                  Date <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="date"
                  type="text"
                  className="form-control"
                  placeholder="Enter le date"
                  defaultValue={getDate()}
                />
                {props.errors.date && (
                  <span className="text-danger">Date est requis</span>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>
                  Sous titre <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="subTitle"
                  type="text"
                  className="form-control"
                  placeholder="Enter le sous titre"
                  defaultValue={props.data?.subTitle}
                />
                {props.errors.subTitle && (
                  <span className="text-danger">Sous titre est requis</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label>
                Fichiers <span className="text-danger">*</span>
              </label>
              <FilePond
                files={props.files}
                allowReorder={true}
                allowMultiple={true}
                onupdatefiles={props.setFiles}
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              />
            </div>
          </div>
          <div className="form-group">
            <label>
              Description <span className="text-danger">*</span>
            </label>
            <textarea
              ref={props.registerRequired}
              name="body"
              type="text"
              className="form-control"
              placeholder="Enter le description"
              defaultValue={props.data?.body}
            />
            {props.errors.body && (
              <span className="text-danger">Description est requis</span>
            )}
          </div>
        </div>

        <div className="card-footer">
          <button type="submit" className="btn btn-primary mr-2">
            Valider
          </button>
          <button
            type="reset"
            onClick={() => {
              setReceiver("All");
              props.reset();
            }}
            className="btn btn-secondary"
          >
            <i className="fa fa-redo"></i>reset
          </button>
        </div>
      </form>
    </div>
  );
};

export default NotifForm;
