import React, { useState } from "react";

function ClasseForm(props) {
  // const [levels, setLevels] = useState([
  //   "Prepa1",
  //   "Prepa2",
  //   "Prepa3",
  //   "Prepa4",
  //   "1erA",
  //   "1erB",
  //   "1erC",
  //   "1erD",
  //   "1erE",
  //   "1erF",
  //   "2emeA",
  //   "2emeB",
  //   "2emeC",
  //   "2emeD",
  //   "2emeE",
  //   "2emeF",
  //   "3emeA",
  //   "3emeB",
  //   "3emeC",
  //   "3emeD",
  //   "3emeE",
  //   "4emeA",
  //   "4emeB",
  //   "4emeC",
  //   "4emeD",
  //   "4emeE",
  //   "5emeA",
  //   "5emeB",
  //   "5emeC",
  //   "5emeD",
  //   "5emeE",
  //   "6emeA",
  //   "6emeB",
  //   "6emeC",
  //   "6emeD",
  //   "6emeE"
  // ]);
  const [levels, setLevels] = useState([
    "Prepa",
    "1er",

    "2eme",

    "3eme",

    "4eme",

    "5eme",

    "6eme",
  ]);
  return (
    <div className="card card-custom">
      {/*begin::Form*/}
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          <div className="form-group">
            <label>
              Nom du classe <span className="text-danger">*</span>
            </label>
            <input
              ref={props.register}
              name="name"
              type="text"
              className="form-control"
              placeholder="Nom du classe"
              defaultValue={props.data ? props.data.name : ""}
            />
            {props.errors.name && (
              <span className="text-danger">Nom du classe est requis</span>
            )}
          </div>
          <div className="form-group">
            <label>
              Selectionnez le niveau du classe
              <span className="text-danger">*</span>
            </label>

            <select className="form-control" ref={props.register} name="level">
              {levels.map((elem) => {
                if (props.data) {
                  if (elem === props.data.level) {
                    return (
                      <option selected value={elem}>
                        {elem}
                      </option>
                    );
                  } else {
                    return <option value={elem}>{elem}</option>;
                  }
                } else {
                  return <option value={elem}>{elem}</option>;
                }
              })}
            </select>
          </div>
        </div>
        <div className="card-footer">
          <button type="submit" className="btn btn-primary mr-2">
            Valider
          </button>
          <button
            type="reset"
            onClick={() => props.reset()}
            className="btn btn-secondary"
          >
            <i className="fa fa-redo"></i>reset
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default ClasseForm;
