import React, { useState } from "react";
import swal from "sweetalert";

import { useForm } from "react-hook-form";
import TeacherForm from "../../components/Teacher/TeacherForm";
import Container from "../../components/Container/Container";
import { API, ROUTER } from "../../constants/env";
import { useParams } from "react-router-dom";

function EditTeacher() {
  let { id } = useParams();

  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    setValue,
  } = useForm();
  const [data, setData] = useState({});
  React.useEffect(() => {
    const fetchdata = async () => {
      fetch(API.teachers.info(id), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }

          setData(response);
        })
        .catch((e) => {});
    };
    fetchdata();
  }, [id]);
  const onSubmit = (data) => {
    // data.adress = {
    //   adress: data.adress,
    //   city: data.city,
    //   zipCode: data.zipCode,
    // };
    data.subjects = data.subjects.map(({ value }) => value);
    let fd = new FormData();
    if (data.file.length > 0) {
      fd.append("file", data.file[0]);
    }
    fd.append("email", data.email);
    fd.append("firstName", data.firstName);
    fd.append("lastName", data.lastName);
    fd.append("userName", data.userName);
    fd.append("password", data.password);
    fd.append("adress", data.adress);
    fd.append("phone", data.phone);
    fd.append("sexe", data.sexe);
    //fd.append("country", data.country);
    fd.append("numCin", data.numCin);
    for (let i = 0; i < data.subjects.length; i++) {
      fd.append("subjects[]", data.subjects[i]);
    }
    console.log("submited data is => \n", fd);
    fetch(API.teachers.update(id), {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      body: fd,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good job!", "Enseignant(e) modifie", "success");
      })
      .catch((e) => {});
  };
  return (
    <Container
      name="Modifier un(e) enseignant(e)"
      back={true}
      backRoute={ROUTER.TEACHERS.LIST}
      submit={true}
    >
      <TeacherForm
        registerRequired={register({ required: false })}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        control={control}
        data={data}
        setValue={setValue}
      />
    </Container>
  );
}

export default EditTeacher;
