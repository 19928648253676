import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import Container from "../../../components/Container/Container";
import ActionForm from "../../../components/Actions/ActionForm";
import { API, ROUTER } from "../../../constants/env";
import moment from "moment";
const EditAction = () => {
  const { id } = useParams();
  const { register, handleSubmit, errors, reset, control } = useForm();

  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(API.actions.info(id), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }

        setData(response);
      })
      .catch((e) => {
        return swal("Error fel request", e.message, "error");
      });
  }, []);

  const onSubmit = (data) => {
    let listClasse = [];
    if (data.listClasse) {
      data.listClasse.forEach((elem) => {
        listClasse.push(elem.value);
      });
    }

    const newData = {
      actionType: data.actionType,
      description: data.description,
      period: data.period,
      startDate: moment(data.startDate, "yyyy-MM-DD").toDate(),
      endDate: data.period
        ? moment(data.endDate, "yyyy-MM-DD").toDate()
        : moment(data.startDate, "yyyy-MM-DD").toDate(),
      listClasse,
    };
    fetch(API.actions.update(id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(newData),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }

        return swal("Good job!", "Evenement modifié", "success");
      })
      .catch((e) => {
        return swal("Error fel request", e.message, "error");
      });
  };

  return (
    <Container
      name="Modifier un evenement"
      back={true}
      backRoute={ROUTER.ACTIONS.LIST}
      submit={true}
    >
      <ActionForm
        data={data}
        registerRequired={register({ required: true })}
        register={register}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        control={control}
      />
    </Container>
  );
};

export default EditAction;
