/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import Container from "../../components/Container/Container";
import Table from "../../components/Table/Table";
import { API, ROUTER } from "../../constants/env";
import swal from "sweetalert";
function ListUsers() {
  const fields = [
    { title: "Nom de salle", field: "name" },
    { title: "Type", field: "type" },
    { title: "Nombre de Places", field: "placeNumber" },
  ];
  const [data, setData] = useState([]);

  const fetchData = (currentPage, count, search, setTotalPage, setTotal) => {
    fetch(
      API.salle.list +
        `?page=${currentPage}&count=${count}&searchQuery=${search}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        setData(response.data);
        setTotalPage(response.totalPage);
        setTotal(response.total);
      })
      .catch((e) => {});
  };

  return (
    <Container
      name="Liste des salles"
      actionRoute="/salle/new"
      actionName="Ajouter une salle"
    >
      <Table
        pageName="salle"
        name="Les Salles"
        fields={fields}
        data={data}
        editRoute={(id) => ROUTER.SALLES.EDIT(id)}
        deleteApiRoute={API.salle.remove}
        deleteMultiple={API.salle.deleteMultiple}
        fetchData={fetchData}
        showSearch={true}
      />
    </Container>
  );
}

export default ListUsers;
