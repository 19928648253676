import React, { useState, useEffect } from "react";
import { API, STATIC } from "../../constants/env";
import swal from "sweetalert";
import Select from "react-select";
import { Controller } from "react-hook-form";

function TeacherForm(props) {
  const [imgData, setImgData] = useState(null);
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  useEffect(() => {
    if (typeof props !== "undefined" && Object.keys(props.data).length !== 0) {
      if (props.data.subjects) {
        props.setValue(
          "subjects",
          props.data?.subjects?.map(({ _id, name, level }) => ({
            value: _id,
            label: `${name}:${level}`,
          }))
        );
      }
    }
  }, [props]);
  const [subjects, setSubjects] = useState([]);
  useEffect(() => {
    const fetchData = () => {
      fetch(API.subjects.all, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }
          response = response.map(({ _id, name, level }) => ({
            value: _id,
            label: name + ":" + level,
          }));
          setSubjects(response);
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);
  return (
    <div className="card card-custom">
      {/*begin::Form*/}
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          {/* START Prénom + Nom */}
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>
                  Prénom d'enseignant(e) <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="firstName"
                  type="text"
                  className="form-control"
                  placeholder="Enter le Prénom"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.firstName
                        : ""
                      : ""
                  }
                />
                {props.errors.firstName && (
                  <span className="text-danger">
                    le Prénom d'enseignant(e) est requis
                  </span>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>
                  Nom d'enseignant(e) <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="lastName"
                  type="text"
                  className="form-control"
                  placeholder="Enter le Nom"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.lastName
                        : ""
                      : ""
                  }
                />
                {props.errors.lastName && (
                  <span className="text-danger">
                    Le Nom d'enseignant(e) est requis
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* END Prénom + Nom */}
          {/* START Email + Username + Mot de passe */}
          <div className="row">
            <div className="col-5">
              <div className="form-group">
                <label>
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Enter l'addresse Email"
                  defaultValue={props.data ? props.data.email : ""}
                />
                {props.errors.email && (
                  <span className="text-danger">
                    l'addresse Email est requis
                  </span>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>
                  Username <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="userName"
                  type="text"
                  className="form-control"
                  placeholder="Enter le Username"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.userName
                        : ""
                      : ""
                  }
                />
                {props.errors.userName && (
                  <span className="text-danger">le Username est requis</span>
                )}
              </div>
            </div>

            <div className="col-4">
              <div className="form-group">
                <label>
                  Mot de passe <span className="text-danger">*</span>
                </label>
                <input
                  defaultValue={props.data?.profil?.password}
                  ref={props.registerRequired}
                  name="password"
                  type="text"
                  className="form-control"
                  placeholder="Enter le mot de passe"
                />
                {props.errors.password && (
                  <span className="text-danger">
                    le mot de passe est requis
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* END Email + Username + Mot de passe */}

          {/* START Address */}
          {/* <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label>
                  Adresse <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="adress"
                  type="text"
                  className="form-control"
                  placeholder="Enter l'Addresse"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.adress
                          ? props.data.profil.adress.adress
                          : ""
                        : ""
                      : ""
                  }
                />
                {props.errors.adress && (
                  <span className="text-danger">l'Addresse est requis</span>
                )}
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>
                  Ville <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="city"
                  type="text"
                  className="form-control"
                  placeholder="Enter le nom de la ville"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.adress
                          ? props.data.profil.adress.city
                          : ""
                        : ""
                      : ""
                  }
                />
                {props.errors.city && (
                  <span className="text-danger">
                    le nom de la ville est requis
                  </span>
                )}
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>
                  Code Postal <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="zipCode"
                  type="number"
                  className="form-control"
                  placeholder="Enter le code postal"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.adress
                          ? props.data.profil.adress.zipCode
                          : ""
                        : ""
                      : ""
                  }
                />
                {props.errors.zipCode && (
                  <span className="text-danger">le code postal est requis</span>
                )}
              </div>
            </div>
          </div> */}
          {/* END Address */}
          {/* START Tel + Sexe + Pay d'origine */}
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label>
                  Tél. <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="phone"
                  type="phone"
                  className="form-control"
                  placeholder="Enter le numero de télephone"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.phone
                        : ""
                      : ""
                  }
                />
                {props.errors.phone && (
                  <span className="text-danger">
                    le numero de télephone est requis
                  </span>
                )}
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>
                  Le sexe <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  ref={props.register}
                  name="sexe"
                >
                  {["Mâle", "Femelle"].map((elem) => {
                    if (props.data) {
                      if (elem === props.data.profil?.sexe) {
                        return (
                          <option selected value={elem}>
                            {elem}
                          </option>
                        );
                      } else {
                        return <option value={elem}>{elem}</option>;
                      }
                    } else {
                      return <option value={elem}>{elem}</option>;
                    }
                  })}
                </select>
                {props.errors.sexe && (
                  <span className="text-danger">Le sexe est requis</span>
                )}
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>
                  Adresse <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="adress"
                  type="text"
                  className="form-control"
                  placeholder="Enter l'Addresse"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.adress
                          ? props.data.profil.adress
                          : ""
                        : ""
                      : ""
                  }
                />
                {props.errors.adress && (
                  <span className="text-danger">l'Addresse est requis</span>
                )}
              </div>
              {/* <div className="form-group">
                <label>
                  Pays d'origine <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="country"
                  type="text"
                  className="form-control"
                  placeholder="Enter la Pays d'origine"
                  defaultValue={
                    props.data
                      ? props.data.profil
                        ? props.data.profil.country
                        : ""
                      : ""
                  }
                />
                {props.errors.country && (
                  <span className="text-danger">
                    la Pays d'origine est requis
                  </span>
                )}
              </div> */}
            </div>
          </div>
          {/* END Tel + Sexe + Pay d'origine  */}
          {imgData !== null ? (
            <img className="image-wrapper" src={imgData} alt="img" />
          ) : (
            props.data && (
              <div className="row">
                <div className="col-12">
                  <div
                    className="image-wrapper"
                    style={{
                      backgroundImage: `url(${STATIC.ROUTE(
                        props.data
                          ? props.data.profil
                            ? props.data.profil.picture
                            : ""
                          : ""
                      )})`,
                    }}
                  ></div>
                </div>
              </div>
            )
          )}

          {/* START numCin + photo de profile  */}
          <div className="row">
            <div className="col-12">
              {/*
<div className="form-group">
                <label>
                  La photo de profile <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="file"
                  type="file"
                  className="form-control-file"
                  placeholder="Enter le nombre de places"
                />
                {props.errors.file && (
                  <span className="text-danger">
                    La photo de profile est requis
                  </span>
                )}
              </div>
                */}
              <div className="form-group">
                <label>La photo de profil</label>
                <div />
                <div className="custom-file">
                  <input
                    ref={props.register}
                    type="file"
                    className="custom-file-input"
                    name="file"
                    onChange={onChangePicture}
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    Choose file
                  </label>
                </div>
                {props.errors.file && (
                  <span className="text-danger">
                    La photo de profil est requis
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label>
                  Numero CIN <span className="text-danger">*</span>
                </label>
                <input
                  ref={props.registerRequired}
                  name="numCin"
                  type="number"
                  className="form-control"
                  placeholder="Enter le Numero CIN"
                  defaultValue={props.data ? props.data.numCin : ""}
                />
                {props.errors.numCin && (
                  <span className="text-danger">le Numero CIN est requis</span>
                )}
              </div>
            </div>
          </div>

          {/* END numCin + photo de profile  */}

          {/* START Parent + Classe  */}
          <div className="row">
            <div className="mx-auto col-12">
              <div className="form-group">
                <label>
                  Les Matieres <span className="text-danger">*</span>
                </label>
                {/* Select-Form with react-hook-form */}
                <Controller
                  control={props.control}
                  as={
                    <Select
                      isMulti
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  }
                  options={subjects}
                  name="subjects"
                  isClearable
                  rules={{ required: true }}
                  errors={props.errors.subjects}
                />
                {props.errors.subjects && (
                  <span className="text-danger">les matiere est requise</span>
                )}
              </div>
            </div>
          </div>
          {/* END Parent + Classe  */}

          <div className="card-footer">
            <button type="submit" className="btn btn-primary mr-2">
              Valider
            </button>
            <button
              type="reset"
              onClick={() => props.reset()}
              className="btn btn-secondary"
            >
              <i className="fa fa-redo"></i>reset
            </button>
          </div>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default TeacherForm;
