import React from "react";
import Container from "../../../components/Container/Container";
import ClubForm from "../../../components/Club/ClubForm";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { API } from "../../../constants/env";

const AddClub = () => {
  const { register, handleSubmit, errors, reset } = useForm();

  const onSubmit = (data) => {
    try {
      fetch(API.club.create, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error fel request", response.message, "error");
          }
          return swal("Good", "Club Ajouté", "success");
        })
        .catch((e) => {
          return swal(
            "Failed",
            "Something went wrong! check the server ",
            "error"
          );
        });
    } catch (error) {
      return swal("Failed", error.message, "error");
    }
  };

  return (
    <Container
      name="Ajouter un Club"
      back={true}
      backRoute="/club"
      submit={true}
    >
      <ClubForm
        registerRequired={register({ required: true })}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
      />
    </Container>
  );
};

export default AddClub;
