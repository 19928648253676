import React, { useState, useEffect } from "react";
import { API } from "../../constants/env";
import swal from "sweetalert";
import Select from "react-select";
import { Controller } from "react-hook-form";
function SubjectForm(props) {
  const [levelsPrepa, setLevelsPrepa] = useState([
    {
      value: "Prepa1",
      label: "Prepa1",
    },
    {
      value: "Prepa2",
      label: "Prepa2",
    },
    {
      value: "Prepa3",
      label: "Prepa3",
    },
    {
      value: "Prepa4",
      label: "Prepa4",
    },
  ]);
  const [levels, setLevels] = useState([
    {
      value: "1erA",
      label: "1erA",
    },
    {
      value: "1erB",
      label: "1erB",
    },
    {
      value: "1erC",
      label: "1erC",
    },
    {
      value: "1erD",
      label: "1erD",
    },
    {
      value: "1erE",
      label: "1erE",
    },
    {
      value: "1erF",
      label: "1erF",
    },
    {
      value: "2emeA",
      label: "2emeA",
    },
    {
      value: "2emeB",
      label: "2emeB",
    },
    {
      value: "2emeC",
      label: "2emeC",
    },
    {
      value: "2emeD",
      label: "2emeD",
    },
    {
      value: "2emeE",
      label: "2emeE",
    },
    {
      value: "2emeF",
      label: "2emeF",
    },
    {
      value: "3emeA",
      label: "3emeA",
    },
    {
      value: "3emeB",
      label: "3emeB",
    },
    {
      value: "3emeC",
      label: "3emeC",
    },
    {
      value: "3emeD",
      label: "3emeD",
    },
    {
      value: "3emeE",
      label: "3emeE",
    },
    {
      value: "4emeA",
      label: "4emeA",
    },
    {
      value: "4emeB",
      label: "4emeB",
    },
    {
      value: "4emeC",
      label: "4emeC",
    },
    {
      value: "4emeD",
      label: "4emeD",
    },
    {
      value: "4emeE",
      label: "4emeE",
    },
    {
      value: "5emeA",
      label: "5emeA",
    },
    {
      value: "5emeB",
      label: "5emeB",
    },
    ,
    {
      value: "5emeC",
      label: "5emeC",
    },
    ,
    {
      value: "5emeD",
      label: "5emeD",
    },
    ,
    {
      value: "5emeE",
      label: "5emeE",
    },
    ,
    {
      value: "6emeA",
      label: "6emeA",
    },
    ,
    {
      value: "6emeB",
      label: "6emeB",
    },
    ,
    {
      value: "6emeC",
      label: "6emeC",
    },
    ,
    {
      value: "6emeD",
      label: "6emeD",
    },
    {
      value: "6emeE",
      label: "6emeE",
    },
  ]);
  const [units, setUnits] = React.useState([]);
  const [unitsPrepa, setUnitsPrepa] = React.useState([]);

  const [classe, setClasse] = React.useState("P");
  const [trimestre, setTrimestre] = React.useState([
    "1er Trimestre",
    "2eme Trimestre",
    "3eme Trimestre",
  ]);
  const [types, setTypes] = React.useState(["Preparatoire", "Normal"]);
  useEffect(() => {
    if (typeof props !== "undefined" && Object.keys(props.data).length !== 0) {
      if (props.data.unit) {
        props.setValue(
          "unit",
          props.data?.unit
            ? {
                value: props.data.unit._id,
                label: props.data.unit.prepa
                  ? `${props.data.unit.name} "Preparatoire"`
                  : props.data.unit.name,
              }
            : {}
        );
      }
    }
  }, [props]);
  useEffect(() => {
    if (typeof props !== "undefined" && Object.keys(props.data).length !== 0) {
      if (props.data.unit) {
        props.setValue(
          "level",
          props.data?.level
            ? props.data.level.map((elem) => ({
                value: elem,
                label: elem,
              }))
            : {}
        );
      }
    }
  }, [props]);
  useEffect(() => {
    if (typeof props !== "undefined" && Object.keys(props.data).length !== 0) {
      if (props.data.type) {
        setClasse(props.data.type);
      } else {
        if (props.data.level[0].charAt(0) === "P") {
          setClasse("P");
        } else {
          setClasse("N");
        }
      }
    }
  }, [props]);
  useEffect(() => {
    const fetchClasseData = () => {
      fetch(API.module.all, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }
          response = response.map(({ _id, name, prepa }) => ({
            value: _id,
            label: `${name} ${prepa ? "Preparatoire" : ""}`,
          }));
          const unitsNormal = response.filter(
            (elem) => !elem.label.includes("Preparatoire")
          );
          const unitsPrepa = response.filter((elem) =>
            elem.label.includes("Preparatoire")
          );
          console.log("this units normal ", unitsNormal);
          console.log("this is prepa units", unitsPrepa);
          setUnits(unitsNormal);
          setUnitsPrepa(unitsPrepa);
        })
        .catch((e) => {
          return swal("Error", e.message, "error");
        });
    };
    fetchClasseData();
  }, []);
  return (
    <div className="card card-custom">
      {/*begin::Form*/}
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <div className="card-body">
          <div className="form-group">
            <label>
              Nom de matière <span className="text-danger">*</span>
            </label>
            <input
              ref={props.register}
              name="name"
              type="text"
              className="form-control"
              placeholder="Enter le nom de matière"
              defaultValue={props.data ? props.data.name : ""}
            />
            {props.errors.name && (
              <span className="text-danger">le nom de matière est requis</span>
            )}
          </div>
          <div className="form-group">
            <label>
              Le coefficient de matière <span className="text-danger">*</span>
            </label>
            <input
              ref={props.register}
              name="coefficient"
              type="number"
              className="form-control"
              placeholder="Enter le coefficient de matière"
              defaultValue={props.data ? props.data.coefficient : ""}
            />
            {props.errors.coefficient && (
              <span className="text-danger">
                Le coefficient de matière est requis
              </span>
            )}
          </div>

          <div className="row">
            <div className="form-group col-12">
              <label>
                Choissizez le type de matiere{" "}
                <span className="text-danger">*</span>
              </label>
              <select
                className="form-control"
                ref={props.register}
                name="type"
                onChange={(e) => setClasse(e.target.value)}
              >
                {types.map((elem) => {
                  if (props.data) {
                    if (elem === props.data.type) {
                      return (
                        <option selected value={elem}>
                          {elem}
                        </option>
                      );
                    } else {
                      return <option value={elem}>{elem}</option>;
                    }
                  } else {
                    return <option value={elem}>{elem}</option>;
                  }
                })}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="mx-auto col-6">
              <div className="form-group">
                <label>
                  Niveau de matière <span className="text-danger">*</span>
                </label>
                {/* Select-Form with react-hook-form */}
                <Controller
                  control={props.control}
                  as={
                    <Select
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  }
                  options={classe.charAt(0) === "P" ? levelsPrepa : levels}
                  name="level"
                  isClearable
                  isMulti
                />
              </div>
            </div>

            <div className="mx-auto col-6">
              <div className="form-group">
                <label>
                  Module <span className="text-danger">*</span>
                </label>
                {/* Select-Form with react-hook-form */}
                <Controller
                  control={props.control}
                  as={
                    <Select
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  }
                  options={classe.charAt(0) === "P" ? unitsPrepa : units}
                  name="unit"
                  isClearable
                />
              </div>
            </div>
          </div>
          {classe.charAt(0) === "P" && (
            <div className="row">
              <div className="form-group col-12">
                <label>
                  trimestre <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  ref={props.register}
                  name="trimestre"
                >
                  {trimestre.map((elem) => {
                    if (props.data) {
                      if (elem === props.data.trimestre) {
                        return (
                          <option selected value={elem}>
                            {elem}
                          </option>
                        );
                      } else {
                        return <option value={elem}>{elem}</option>;
                      }
                    } else {
                      return <option value={elem}>{elem}</option>;
                    }
                  })}
                </select>
                {props.errors.level && (
                  <span className="text-danger">
                    le niveau de matière est requis
                  </span>
                )}
              </div>
            </div>
          )}

          {classe.charAt(0) === "P" && (
            <div className="form-group">
              <label>
                Les Objectifs <span className="text-danger">*</span>
              </label>

              <div className="row">
                {props.fields?.map(({ object }, index) => (
                  <div className="col-10 m-2" key={index}>
                    <input
                      ref={props.registerRequired}
                      name={`objects[${index}.object]`}
                      type="text"
                      className="form-control col"
                      placeholder="Enter Un Objectif"
                      defaultValue={object}
                    />
                    <button
                      className="btn btn-icon btn-light btn-hover-danger  col-1"
                      onClick={() => props.remove(index)}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <svg
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <title>Supprimer</title>
                          <desc>Created with Sketch.</desc>
                          <defs />
                          <g
                            id="Stockholm-icons-/-General-/-Trash"
                            stroke="none"
                            strokeWidth={1}
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect
                              id="bound"
                              x={0}
                              y={0}
                              width={24}
                              height={24}
                            />
                            <path
                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                              id="round"
                              fill="#000000"
                              fillRule="nonzero"
                            />
                            <path
                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                              id="Shape"
                              fill="#000000"
                              opacity="0.3"
                            />
                          </g>
                        </svg>
                      </span>
                    </button>

                    {props.errors?.objects?.[index] && (
                      <span className="text-danger">Déjeuner est requis</span>
                    )}
                  </div>
                ))}

                <div className="col-4">
                  <button
                    className="btn btn-primary btn-sm font-weight-bolder "
                    onClick={(e) => {
                      e.preventDefault();
                      props.append("");
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="card-footer">
            <button type="submit" className="btn btn-primary mr-2">
              Valider
            </button>
            <button
              type="reset"
              onClick={() => props.reset()}
              className="btn btn-secondary"
            >
              <i className="fa fa-redo"></i>reset
            </button>
          </div>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default SubjectForm;
