import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { API } from "../../constants/env";

const SmoyenneG = ({
  title,
  fontSizeMoyenne,
  color,
  trimestre,
  studentId,
  moyenneTri3,
  minmax,
  saveCarnet,
}) => {
  const [allC, setAllC] = useState({});
  const h1Style = {
    textAlign: "center",
    margin: "0 auto!important",
    fontSize: fontSizeMoyenne,
    color: "#474444",
    color: color,
    fontWeight: 600,
  };
  useEffect(() => {
    if (studentId) {
      var trimestre = localStorage.getItem("tris");
      // console.log(studentId);
      fetch(API.overAllAverge.get(studentId), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (!response) {
            setAllC({
              moyenne: 0,
              max: 0,
              min: 0,
              tmax: 0,
              tmin: 0,
              trimestre: trimestre,
            });
          } else if (response.error) {
            return swal("Error", response.message, "error");
          } else {
            var trimestre = localStorage.getItem("tris");
            switch (trimestre) {
              case "premier":
                setAllC({
                  ...allC,
                  tmax: response.premier.max,
                  tmin: response.premier.min,
                  trimestre: trimestre,
                });
                break;
              case "deuxieme":
                setAllC({
                  ...allC,
                  tmax: response.deuxieme.max,
                  tmin: response.deuxieme.min,
                  trimestre: trimestre,
                });
                break;
              case "troisieme":
                setAllC({
                  ...allC,
                  tmax: response.troisieme.max,
                  tmin: response.troisieme.min,
                  trimestre: trimestre,
                });
                break;
            }
            setAllC(response);
          }
        })
        .catch((e) => {
          swal("Error", e.message, "error");
        });
    }
  }, [studentId]);

  const saveMoyennes = () => {
    fetch(API.overAllAverge.update(studentId), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        ...allC,
        trimestre: localStorage.getItem("tris"),
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        // return swal("Error", response.message, "error");
      })
      .catch((e) => {
        swal("Error", e.message, "error");
      });
  };

  const calcmoyGen = () => {
    const moyTri1 = allC?.premier?.moyenne;
    const moyTri2 = allC?.deuxieme?.moyenne * 1.5;
    const moyTri3 = allC?.troisieme?.moyenne * 2;
    const moyGen = ((moyTri1 + moyTri2 + moyTri3) / 4.5).toFixed(2);
    return moyGen;
  };
  const changeMoyenne = (value, name) => {
    if (value.length > 1 && value.charAt(0) === "0" && value.charAt(1) !== ",")
      value = value.substring(1);
    if (value > 20) value = 20;
    if (value < 0) value = 0;
    console.log(value);
    setAllC({ ...allC, [name]: Number(value) });
  };
  return (
    <div className="card" style={{ marginTop: 10 }}>
      <div className="card-body">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {minmax && trimestre != "troisieme" && (
            <>
              <div style={{ flex: 1 }}>
                <h1 style={h1Style}>
                  moyenne la plus élevée : <br />
                  <input
                    value={allC?.tmax}
                    type="number"
                    name="eleve"
                    placeholder="00,00"
                    onChange={(e) =>
                      changeMoyenne(e.currentTarget.value, "tmax")
                    }
                    style={{ outline: 0, width: "30%" }}
                    step="0.01"
                  />
                </h1>
              </div>
              <div style={{ flex: 1 }}>
                <h1 style={h1Style}>
                  Moyenne la plus basse : <br />
                  <input
                    style={{ outline: 0, width: "30%" }}
                    type="number"
                    name="bas"
                    step="0.01"
                    placeholder="00,00"
                    onChange={(e) =>
                      changeMoyenne(e.currentTarget.value, "tmin")
                    }
                    value={allC?.tmin}
                  />
                </h1>
              </div>
            </>
          )}

          {!minmax && (
            <>
              <div
                style={{
                  flex: 1,
                }}
              >
                <h1 style={h1Style}>
                  {title}
                  <br />
                  <input
                    type="number"
                    name="moyenne"
                    placeholder="00,00"
                    onChange={(e) =>
                      changeMoyenne(e.currentTarget.value, "moyenne")
                    }
                    step="0.01"
                    value={calcmoyGen()}
                    style={{ outline: 0, width: "30%" }}
                  />
                </h1>
              </div>
              <div style={{ flex: 1 }}>
                <h1 style={h1Style}>
                  moyenne général la plus élevée : <br />
                  <input
                    value={allC?.max}
                    type="number"
                    name="eleve"
                    placeholder="00,00"
                    onChange={(e) =>
                      changeMoyenne(e.currentTarget.value, "max")
                    }
                    style={{ outline: 0, width: "30%" }}
                    step="0.01"
                  />
                </h1>
              </div>
              <div style={{ flex: 1 }}>
                <h1 style={h1Style}>
                  Moyenne général la plus basse : <br />
                  <input
                    style={{ outline: 0, width: "30%" }}
                    type="number"
                    name="bas"
                    step="0.01"
                    placeholder="00,00"
                    onChange={(e) =>
                      changeMoyenne(e.currentTarget.value, "min")
                    }
                    value={allC?.min}
                  />
                </h1>
              </div>
            </>
          )}
        </div>
        <div>
          <button
            className="btn btn-success btn-lg btn-block mt-5"
            onClick={() => {
              saveMoyennes();
              saveCarnet();
            }}
          >
            Confirm Moyennes {minmax ? "trimestrielles" : "générales"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SmoyenneG;
