import React from "react";

import Table from "../../../components/Table/Table";
import { API, ROUTER } from "../../../constants/env";
import swal from "sweetalert";
import Container from "../../../components/Container/Container";
import moment from "moment";
const ListActions = () => {
  const fields = [
    { title: "Couleur", field: "color" },
    { title: "Le Type", field: "actionType" },
    { title: "Le Titre", field: "description" },
    { title: "La Date", field: "date" },
    { title: "La Classe", field: "listClasse" },
  ];
  const [data, setData] = React.useState([]);

  const fetchData = (currentPage, count, search, setTotalPage, setTotal) => {
    fetch(
      API.actions.list +
        `?page=${currentPage}&count=${count}&searchQuery=${search}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        const result = response.data.map((elem) => ({
          _id: elem._id,
          color: (
            <div
              style={{
                marginLeft: "4%",
                width: "30px",
                height: "23px",
                borderRadius: "10%",
                backgroundColor: elem.actionType.color,
              }}
            />
          ),
          actionType: elem.actionType.title,
          description: elem.description,
          date: elem.period
            ? `${moment(elem.startDate).format("DD/MM/YYYY")} --> ${moment(
                elem.endDate
              ).format("DD/MM/YYYY")}`
            : moment(elem.startDate).format("DD/MM/YYYY"),
          listClasse:
            elem.listClasse.length === 0
              ? "Tout"
              : `${elem.listClasse.map((item) => item.name)}`,
        }));
        setData(result);
        setTotalPage(response.totalPage);
        setTotal(response.total);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };
  return (
    <Container
      name="Les Actualités"
      actionRoute={ROUTER.ACTIONS.CREATE}
      actionName="Ajouter un evenement"
    >
      <Table
        pageName="action"
        name="Liste d'événements"
        fields={fields}
        data={data}
        editRoute={(id) => ROUTER.ACTIONS.EDIT(id)}
        deleteApiRoute={API.actions.remove}
        deleteMultiple={API.actions.deleteMultiple}
        fetchData={fetchData}
        showSearch={true}
      />
    </Container>
  );
};

export default ListActions;
