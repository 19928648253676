import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Container from "../../components/Container/Container";
import SettingsForm from "../../components/Settings/SettingsForm";
import { useForm } from "react-hook-form";
import { API } from "../../constants/env";

const Settings = () => {
  const { register, handleSubmit, errors } = useForm();
  const [body, setBody] = useState("");
  const [data, setData] = useState();
  useEffect(() => {
    fetch(API.settings.getSettings, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        setData(response);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  }, []);

  const onSubmit = (data) => {
    console.log(data.showCard);

    fetch(API.settings.update, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good job!", "Paramtétre modifie", "success");
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };

  return (
    <Container name="Paramétres">
      <SettingsForm
        data={data}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
      />
    </Container>
  );
};

export default Settings;
