import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import BillContainer from "../../../components/Bill/billContainer";
import Container from "../../../components/Container/Container";
import { API } from "../../../constants/env";

const Bill = () => {
  const [bill, setBill] = useState({});
  const [student, setStudent] = useState("");
  const { id } = useParams();
  useEffect(() => {
    const getBill = async () => {
      fetch(API.bill.info(id), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.error) {
            return swal("Error", response.message, "error");
          }

          setBill(response);
          setStudent(
            `${response.student.profil.firstName} ${response.student.profil.lastName}`
          );
        })
        .catch((e) => {
          return swal("Error", e.message, "error");
        });
    };
    getBill();
  }, []);

  const updateDB = (body) => {
    fetch(API.bill.update(id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good", "updated", "success");
      })
      .catch((e) => {
        return swal(
          "Failed",
          "Something went wrong! check the server ",
          "error"
        );
      });
  };

  const deleteBill = (obj, fieldName) => {
    const uiUpadatedField = bill[fieldName].filter((field) => field !== obj);
    setBill({ ...bill, [`${fieldName}`]: uiUpadatedField });
    const body = uiUpadatedField.map(({ _id, period }) => {
      if (_id.name) return { period, _id: _id._id };
      return _id;
    });
    updateDB({ [`${fieldName}`]: body });
  };

  const updateBill = (data, fieldName) => {
    let uiUpadatedField = bill[fieldName];
    let updatedField = uiUpadatedField.map(({ period, _id }) => {
      if (_id.name) {
        return { period, _id: _id._id };
      }
      return _id;
    });

    data[fieldName].map(({ obj, period }) => {
      const paidField = JSON.parse(obj);
      if (period) {
        uiUpadatedField = [...uiUpadatedField, { period, _id: paidField }];
        return (updatedField = [
          ...updatedField,
          { period, _id: paidField._id },
        ]);
      }
      uiUpadatedField = [...uiUpadatedField, paidField];
      return (updatedField = [...updatedField, paidField._id]);
    });

    setBill({ ...bill, [`${fieldName}`]: uiUpadatedField });
    updateDB({ [`${fieldName}`]: updatedField });
  };

  return (
    <Container name={student} back={true} backRoute="/payment" submit={true}>
      <BillContainer
        name="Scolarités"
        payments={bill.paidFees}
        fieldName="paidFees"
        listApi={API.fees.feesAll}
        updateBill={updateBill}
        deleteBill={deleteBill}
      />
      <BillContainer
        name="Services"
        payments={bill.paidService}
        fieldName="paidService"
        listApi={API.services.serviceAll}
        periodesApi
        updateBill={updateBill}
        deleteBill={deleteBill}
      />
      <BillContainer
        name="Clubs"
        payments={bill.paidClubs}
        fieldName="paidClubs"
        listApi={API.club.clubAll}
        periodesApi
        updateBill={updateBill}
        deleteBill={deleteBill}
      />
    </Container>
  );
};

export default Bill;
