import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select from "react-select";
import swal from "sweetalert";
import { API } from "../../../../constants/env";
function EditSeanceModal(props) {
  const { register, handleSubmit, setValue, errors } = useForm();
  const [days, setDays] = useState([
    { value: "0", label: "Dimanche" },
    { value: "1", label: "Lundi" },
    { value: "2", label: "Mardi" },
    { value: "3", label: "Mercredi" },
    { value: "4", label: "Jeudi" },
    { value: "5", label: "Vendredi" },
    { value: "6", label: "Samedi" },
  ]);
  const [timePicker, setTimePicker] = useState([
    { value: "08:00", label: "08:00" },
    { value: "08:15", label: "08:15" },
    { value: "08:30", label: "08:30" },
    { value: "08:45", label: "08:45" },
    { value: "09:00", label: "09:00" },
    { value: "09:15", label: "09:15" },
    { value: "09:30", label: "09:30" },
    { value: "09:45", label: "09:45" },
    { value: "10:00", label: "10:00" },
    { value: "10:15", label: "10:15" },
    { value: "10:30", label: "10:30" },
    { value: "10:45", label: "10:45" },
    { value: "11:00", label: "11:00" },
    { value: "11:15", label: "11:15" },
    { value: "11:30", label: "11:30" },
    { value: "11:45", label: "11:45" },
    { value: "12:00", label: "12:00" },
    { value: "12:15", label: "12:15" },
    { value: "12:30", label: "12:30" },
    { value: "12:45", label: "12:45" },
    { value: "13:00", label: "13:00" },
    { value: "13:15", label: "13:15" },
    { value: "13:30", label: "13:30" },
    { value: "13:45", label: "13:45" },
    { value: "14:00", label: "14:00" },
    { value: "14:15", label: "14:15" },
    { value: "14:30", label: "14:30" },
    { value: "14:45", label: "14:45" },
    { value: "15:00", label: "15:00" },
    { value: "15:15", label: "15:15" },
    { value: "15:30", label: "15:30" },
    { value: "15:45", label: "15:45" },
    { value: "16:00", label: "16:00" },
    { value: "16:15", label: "16:15" },
    { value: "16:30", label: "16:30" },
    { value: "16:45", label: "16:45" },
    { value: "17:00", label: "17:00" },
    { value: "17:15", label: "17:15" },
    { value: "17:30", label: "17:30" },
    { value: "17:45", label: "17:45" },
    { value: "18:00", label: "18:00" },
  ]);

  //Sended to the Seance Modal
  const [matieres, setMatieres] = useState([{ value: null, lable: null }]);
  const [salles, setSalles] = useState([{ value: null, lable: null }]);
  const [classes, setClasses] = useState([{ value: null, lable: null }]);
  // Fetch functions to charge { matieres,salles,classes } with Data
  const fetchMatieresOfEnseignant = (Enseignant_id) => {
    fetch(API.teacherTimetable.SubjectList(Enseignant_id), {
      //I will replace this by get les matieres d'un enseignant specific
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          console.log(response);
          return swal("Error", response.message, "error");
        }
        response = response.map((elem) => ({
          value: elem._id,
          label: `${elem.name} ${elem.level}`,
        }));
        setMatieres(response);
        console.log("fetchMatieresOfEnseignant -> response", response);
      })
      .catch((e) => {});
  };
  /*const fetchSalles = () =>{
        fetch(API.teacherTimetable.salleList,{ //I will replace this by get les salles disponibles
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            "x-auth-token":localStorage.getItem("token")
            },
            body: JSON.stringify({ 
                startHour: heureDebut.value, 
                endHour: heureFin.value,
                day:day.value
                
            }),
        })
        .then((res) => res.json())
        .then((response) => {
            if (response.error) {
                console.log(response);
                return swal("Error", response.message, "error");
            }
            response = response.map(elem=>(
                    {
                        value:elem._id,
                        label:elem.name
                    }
                )
            );
            setSalles(response);
            console.log("fetchSalles => setSalles (=>)",response);
        })
        .catch((e) => {});
    }*/
  const fetchClassesOfEnseignant = () => {
    fetch(API.teacherTimetable.classList, {
      //I will replace this by get les matieres d'un enseignant specific
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        startHour: heureDebut.value,
        endHour: heureFin.value,
        day: day.value,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        // console.log("===========================");
        // console.log(response);
        if (response.error) {
          console.log(response);
          return swal("Error", response.message, "error");
        }
        response = response.map((elem) => ({
          value: elem._id,
          label: `${elem.name} ${elem.level}`,
        }));
        setClasses(response);
        console.log("fetchClassesOfEnseignant => setClasses (=>)", response);
      })
      .catch((e) => {});
    // setClasses([
    //     {value:"01",label:"1er Annee"},
    //     {value:"02",label:"2eme Annee"},
    //     {value:"03",label:"3eme Annee"},
    //     {value:"04",label:"4eme Annee"},
    // ]);
  };
  const [subject, setSubject] = useState({ value: null, label: null });
  const [salle, setSalle] = useState({ value: null, label: null });
  const [classe, setClasse] = useState({ value: null, label: null });
  const [day, setDay] = useState({ value: null, label: null });
  const [heureDebut, setHeureDebut] = useState({ value: null, label: null });
  const [heureFin, setHeureFin] = useState({ value: null, label: null });
  const [seanceId, setSeanceId] = useState(null);
  useEffect(() => {
    console.log("EditSeanceModal.js");
  }, []);
  useEffect(() => {
    if (props.show && day.value && heureDebut.value && heureFin.value) {
      //fetchSalles();
      fetchClassesOfEnseignant();
    }
  }, [day, heureDebut, heureFin]);
  useEffect(() => {
    console.log("(2')");
    if (props.show) {
      fetchMatieresOfEnseignant(props.Enseignant.value);
      console.log("(useEffect props.show)");
      setDay(days[props.defaultValues.daysOfWeek[0]]);
      console.log("Day");
      setHeureDebut(
        timePicker.filter((elem) => {
          if (props.defaultValues.startTime == elem.value) {
            return elem;
          }
        })[0]
      );
      console.log("Hdebut");
      setHeureFin(
        timePicker.filter((elem) => {
          if (props.defaultValues.endTime == elem.value) {
            return elem;
          }
        })[0]
      );
      console.log("Hfin");
      console.log("props.defaultValues => ", props.defaultValues);
      setSeanceId(props.defaultValues.id);
    } else {
      setSubject({ value: null, label: null });
      // setSalle({value:null,label:null});
      setClasse({ value: null, label: null });
      setDay({ value: null, label: null });
      setHeureDebut({ value: null, label: null });
      setHeureFin({ value: null, label: null });
    }
  }, [props.show]);
  useEffect(() => {
    console.log("(3')");
    if (seanceId) {
      console.log("===============>>> UseEffect <<<===============");
      console.log("useEffect seanceId => ", seanceId);
      console.log("===============>>> UseEffect <<<===============");
      setValue("id", seanceId);
    }
  }, [seanceId]);
  useEffect(() => {
    console.log("(7')");
    if (day.value) {
      console.log("===============>>> UseEffect <<<===============");
      console.log("useEffect day => ", day);
      console.log("===============>>> UseEffect <<<===============");
      setValue("days", day);
    }
  }, [day]);
  useEffect(() => {
    console.log("(8')");
    if (heureDebut.value) {
      console.log("===============>>> UseEffect <<<===============");
      console.log("useEffect heureDebut => ", heureDebut);
      console.log("===============>>> UseEffect <<<===============");
      setValue("heureDebut", heureDebut);
    }
  }, [heureDebut]);
  useEffect(() => {
    console.log("(9')");
    if (heureFin.value) {
      console.log("===============>>> UseEffect <<<===============");
      console.log("useEffect heureFin => ", heureFin);
      console.log("===============>>> UseEffect <<<===============");
      setValue("heureFin", heureFin);
    }
  }, [heureFin]);
  useEffect(() => {
    console.log("(4')");
    if (subject.value) {
      console.log("===============>>> UseEffect <<<===============");
      console.log("useEffect subject => ", subject);
      console.log("===============>>> UseEffect <<<===============");
      setValue("subject", subject);
    }
  }, [subject]);
  /*useEffect(() => {
        console.log("(5')")
        if(salle.value){
            console.log("===============>>> UseEffect <<<===============");
            console.log("useEffect salle => ",salle);
            console.log("===============>>> UseEffect <<<===============");
            setValue("salle",salle);
        }
    }, [salle]);*/
  useEffect(() => {
    console.log("(6')");
    if (classe.value) {
      console.log("===============>>> UseEffect <<<===============");
      console.log("useEffect classe => ", classe);
      console.log("===============>>> UseEffect <<<===============");
      setValue("classe", classe);
    }
  }, [classe]);
  useEffect(() => {
    if (props.show) {
      setSubject(
        matieres.filter((elem) => {
          console.log("elem ======>>>>> ", elem);
          if (props.defaultValues.title == elem.label) {
            console.log("============== Matieres ==============");
            console.log("props.defaultValues => ", props.defaultValues);
            console.log("======================================");
            console.log("elem => ", elem);
            console.log("======================================");
            return elem;
          }
        })[0]
      );
    }
  }, [matieres]);
  /*useEffect(() => {
        if(props.show){
            setSalle(salles.filter(elem=>{
                if(props.defaultValues.Salle == elem.label){
                    return elem;
                }
            })[0]);
        }
    }, [salles])*/

    //TOFIX : label 
  useEffect(() => {
    if (props.show) {
      console.log("^^^^^^^^^^^^^^^^^^^^^^^^\n",props.defaultValues.classe) //1er Année B 1erB
      setClasse(
        classes.filter((elem) => {
          console.log("^^^^^^^^^^^^^^^^^^^^^^^^\n")
          console.log(elem.label)
          if (props.defaultValues.classe.includes(elem.label)) {
            console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^\n',elem)
            return elem;
          }
        })[0]
      );
    }
  }, [classes]);

  const handleSubjectChange = (selectedOption) => {
    console.log("===============>>> HandleChange <<<===============");
    console.log("handleSubjectChange setSubject => ", selectedOption);
    console.log("===============>>> HandleChange <<<===============");
    setSubject(selectedOption);
  };
  /* const handleSalleChange=(selectedOption)=>{
        console.log("===============>>> HandleChange <<<===============");
        console.log("handleSalleChange setSalle => ",selectedOption);
        console.log("===============>>> HandleChange <<<===============");
        setSalle(selectedOption);
    }*/
  const handleClasseChange = (selectedOption) => {
    console.log("===============>>> HandleChange <<<===============");
    console.log("handleClasseChange setClasse => ", selectedOption);
    console.log("===============>>> HandleChange <<<===============");
    setClasse(selectedOption);
  };
  const handleDayChange = (selectedOption) => {
    console.log("===============>>> HandleChange <<<===============");
    console.log("handleDayChange setDay => ", selectedOption);
    console.log("===============>>> HandleChange <<<===============");
    setDay(selectedOption);
  };
  const handleHeureDebutChange = (selectedOption) => {
    console.log("===============>>> HandleChange <<<===============");
    console.log("handleHeureDebutChange setHeureDebut => ", selectedOption);
    console.log("===============>>> HandleChange <<<===============");
    setHeureDebut(selectedOption);
  };
  const handleHeureFinChange = (selectedOption) => {
    console.log("===============>>> HandleChange <<<===============");
    console.log("handleHeureFinChange setHeureFin => ", selectedOption);
    console.log("===============>>> HandleChange <<<===============");
    setHeureFin(selectedOption);
  };

  useEffect(() => {
    register({ name: "id" });
    register({ name: "subject" });
    // register({ name: "salle" });
    register({ name: "classe" });
    register({ name: "days" });
    register({ name: "heureDebut" });
    register({ name: "heureFin" });
  }, [register]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ajouter Une Seance
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(props.onEdit)}>
        <Modal.Body>
          <div className="card card-custom">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>
                      Matiere: <span className="text-danger">*</span>
                    </label>
                    <Select
                      ref={register}
                      options={matieres}
                      name="subject"
                      onChange={handleSubjectChange}
                      value={subject}
                    />
                    {errors.subject && (
                      <span className="text-danger">la matiere est requis</span>
                    )}
                  </div>
                </div>
                {/* <div className="col-6">
                                <div className="form-group">
                                    <label>
                                    Salle <span className="text-danger">*</span>
                                    </label>
                                    <Select 
                                        ref={register}
                                        options={salles} 
                                        name="salle"
                                        onChange={handleSalleChange}
                                        value={salle}
                                        />
                                    {errors.subject && (
                                    <span className="text-danger">la matiere est requis</span>
                                    )}
                                </div>
                            </div> */}
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>
                      Classe <span className="text-danger">*</span>
                    </label>
                    <Select
                      ref={register}
                      options={classes}
                      name="classe"
                      onChange={handleClasseChange}
                      value={classe}
                    />
                    {errors.subject && (
                      <span className="text-danger">la matiere est requis</span>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>
                      Jour <span className="text-danger">*</span>
                    </label>
                    <Select
                      ref={register}
                      options={days}
                      name="days"
                      onChange={handleDayChange}
                      value={day}
                    />
                    {errors.subject && (
                      <span className="text-danger">la matiere est requis</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>
                      Heure Début <span className="text-danger">*</span>
                    </label>
                    <Select
                      ref={register}
                      options={timePicker}
                      name="heureDebut"
                      onChange={handleHeureDebutChange}
                      value={heureDebut}
                    />
                    {errors.subject && (
                      <span className="text-danger">la matiere est requis</span>
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>
                      Heure Fin <span className="text-danger">*</span>
                    </label>
                    <Select
                      ref={register}
                      options={timePicker}
                      name="heureFin"
                      onChange={handleHeureFinChange}
                      value={heureFin}
                    />
                    {errors.subject && (
                      <span className="text-danger">la matiere est requis</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => props.onDelete(seanceId)}>
            Supprimer
          </Button>
          <Button onClick={() => props.onHide(false)}>Quitter</Button>
          <Button type="submit">Modifier</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
export default EditSeanceModal;
