import React, { useState } from "react";

import Table from "../../../components/Table/Table";
import { API, ROUTER } from "../../../constants/env";
import swal from "sweetalert";
import Container from "../../../components/Container/Container";

const ActionType = () => {
  const fields = [
    { title: "Type", field: "title" },
    { title: "Couleur", field: "color" },
    { title: "Par Classe", field: "perClasse" },
  ];

  const [data, setData] = useState([]);

  const fetchData = (currentPage, count, search, setTotalPage, setTotal) => {
    fetch(
      API.actionTypes.list +
        `?page=${currentPage}&count=${count}&searchQuery=${search}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }

        setData(
          response.data.map(({ title, color, perClasse, _id }) => {
            return {
              _id,
              title,
              color: (
                <div
                  style={{
                    padding: 0,
                    width: 35,
                    height: 35,
                    background: `${color}`,
                    borderRadius: 7,
                  }}
                />
              ),
              perClasse: perClasse ? "Oui" : "Faux",
            };
          })
        );
        setTotalPage(response.totalPage);
        setTotal(response.total);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };
  return (
    <Container
      name="Types d'Actualités"
      actionRoute={ROUTER.ActionTYPES.CREATE}
      actionName="Ajouter un type"
    >
      <Table
        pageName="actiont"
        name="Liste des types"
        fields={fields}
        data={data}
        editRoute={(id) => ROUTER.ActionTYPES.EDIT(id)}
        deleteApiRoute={API.actionTypes.delete}
        deleteMultiple={API.actionTypes.deleteMultiple}
        fetchData={fetchData}
        showSearch={true}
      />
    </Container>
  );
};

export default ActionType;
