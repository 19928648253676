import React from "react";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import UserForm from "../../components/Users/UserForm";
import Container from "../../components/Container/Container";
import { API } from "../../constants/env";
function AddUser() {
  const { register, handleSubmit, errors, reset } = useForm();
  const onSubmit = (data) => {
    fetch(API.user.create, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        return swal("Good job!", "client ajouter", "success");
      })
      .catch((e) => {});
  };
  return (
    <Container
      name="Ajouter un utilisateur"
      back={true}
      backRoute="/users"
      submit={true}
    >
      <UserForm
        register={register({ required: true })}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
      />
    </Container>
  );
}

export default AddUser;
