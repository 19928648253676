/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { API, ROUTER } from "../../constants/env";
function Login() {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    console.log("the data =>",data)
    fetch(API.auth.login, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: data.username, password: data.password }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        localStorage.setItem("token", response.token);
        localStorage.setItem("user", JSON.stringify(response));
        window.location = ROUTER.HOME;
      })
      .catch((e) => {});
  };

  return (
    <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>
      {/*begin::Login*/}
      <div
        className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
        id="kt_login"
      >
        {/*begin::Aside*/}
        <div
          className="login-aside d-flex flex-row-auto p-10 p-lg-10"
          style={{ backgroundImage: "url(assets/media/bg/bg-dreamSchool.png)" }}
        >
          {/*begin: Aside Container*/}
          <div className="d-flex flex-row-fluid flex-column justify-content-between p-10 p-lg-10">
            {/*begin: Aside header*/}
            <a href="#" className="flex-column-auto mt-5 pb-lg-0 pb-10">
              <img
                src={"assets/images/odysse.png"}
                className="max-h-100px"
                alt="logo"
              />
            </a>
            {/*end: Aside header*/}
            {/*begin: Aside content*/}
            <div className="flex-column-fluid d-flex flex-column justify-content-center">
              <div style={{ backgroundColor: "rgba(255,255,255,.6)" }}>
                <div className="opacity-100 px-5 py-5">
                  <h3 className="font-size-h1 mb-5  text-warning">
                    Bienvenue Admin!<br></br>
                    <br></br>
                    L'Ecole Primaire Privée l'Odyssee
                  </h3>
                  {/* <p className="font-weight-lighter text-dark  opacity-80">
                    Ecole Primaire Privée l'Odyssee est but also the leap into
                    electronic typesetting, remaining essentially unchanged. It
                    was popularised in the 1960s with the release of Letraset
                    sheets
                  </p> */}
                </div>
              </div>
            </div>
            {/*end: Aside content*/}
            {/*begin: Aside footer for desktop*/}
            <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
              <div className="opacity-70 font-weight-bold	text-white">
                © 2020 NTech
              </div>
              <div className="d-flex">
                <a href="#" className="text-white">
                  Privacy
                </a>
                <a href="#" className="text-white ml-10">
                  Legal
                </a>
                <a href="#" className="text-white ml-10">
                  Contact
                </a>
              </div>
            </div>
            {/*end: Aside footer for desktop*/}
          </div>
          {/*end: Aside Container*/}
        </div>
        {/*begin::Aside*/}
        {/*begin::Content*/}
        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
          {/*begin::Content header*/}
          {/*
                <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">Dont have an account yet?</span>
              <a href="javascript:;" className="font-weight-bold ml-2" id="kt_login_signup">Sign Up!</a>
            </div>
                */}

          {/*end::Content header*/}
          {/*begin::Content body*/}
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            {/*begin::Signin*/}
            <div className="login-form login-signin">
              <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">Sign In</h3>
                <p className="text-muted font-weight-bold">
                  Enter nom d'utilisateur et password
                </p>
              </div>
              {/*begin::Form*/}
              <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <input
                    className="form-control form-control-solid h-auto py-5 px-6"
                    type="text"
                    placeholder="Username"
                    name="username"
                    autoComplete="off"
                    ref={register({ required: true })}
                  />
                  {errors.username && (
                    <span className="text-danger">username required</span>
                  )}
                </div>
                <div className="form-group">
                  <input
                    className="form-control form-control-solid h-auto py-5 px-6"
                    type="password"
                    placeholder="Password"
                    name="password"
                    autoComplete="off"
                    ref={register({ required: true })}
                  />
                  {errors.password && (
                    <span className="text-danger">password required</span>
                  )}
                </div>
                {/*begin::Action*/}

                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                  <div
                    className="text-dark-50 text-hover-primary my-3 mr-2"
                    id="kt_login_forgot"
                  ></div>
                  {/*
                      
                      */}
                  <button
                    type="submit"
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                  >
                    Sign In
                  </button>
                </div>
                {/*end::Action*/}
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Signin*/}
          </div>
          {/*end::Content body*/}
          {/*begin::Content footer for mobile*/}
          <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
            <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
              © 2020 M&H
            </div>
            <div className="d-flex order-1 order-sm-2 my-2">
              <a href="#" className="text-dark-75 text-hover-primary">
                Privacy
              </a>
              <a href="#" className="text-dark-75 text-hover-primary ml-4">
                Legal
              </a>
              <a href="#" className="text-dark-75 text-hover-primary ml-4">
                Contact
              </a>
            </div>
          </div>
          {/*end::Content footer for mobile*/}
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Login*/}
    </div>
  );
}

export default Login;
