import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import Container from "../../components/Container/Container";
import ModuleForm from "../../components/Module/ModuleForm";
import { API, ROUTER } from "../../constants/env";

const EditModule = () => {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    errors,
    reset,
    setValue,
    control,
  } = useForm();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(API.module.info(id), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }
        //   console.log(response);

        setData(response);
      })
      .catch((e) => {
        return swal("Error fel request", e.message, "error");
      });
  }, []);

  const onSubmit = (data) => {
    data.coefficient = +data.coefficient;
    // console.log(data);
    fetch(API.module.update(id), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error fel request", response.message, "error");
        }
        return swal("Good job!", "Module Modifié", "success");
      })
      .catch((e) => {
        return swal("Error fel request", e.message, "error");
      });
  };

  return (
    <Container
      name="Modifier  Module"
      back={true}
      backRoute={ROUTER.MODULES.LIST}
      submit={true}
    >
      <ModuleForm
        register={register()}
        registerRequired={register({ required: true })}
        errors={errors}
        data={data}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        reset={reset}
        setValue={setValue}
        control={control}
      />
    </Container>
  );
};

export default EditModule;
