import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import swal from "sweetalert";
import BillTable from "./billTable";
import BillFields from "./billFields";
import { STATIC } from "../../constants/env";

const BillContainer = ({
  name,
  payments,
  fieldName,
  listApi,
  periodesApi,
  updateBill,
  deleteBill,
}) => {
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      [`${fieldName}`]: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    name: fieldName,
    control,
  });
  const [list, setList] = useState([]);
  const [periodes, setPeriodes] = useState(null);

  useEffect(() => {
    fetch(listApi, { 
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        const results = response.map((object, index) => {
          return {
            option: object.name,
            value: JSON.stringify(object),
          };
        });
        setList(results);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
    if (periodesApi)
      setPeriodes(STATIC.PERIODE);
  }, []);

  const onSubmit = (data) => {
    updateBill(data, fieldName);
    fields.length = 0;
  };

  return (
    <div className="card mb-5">
      <div className="card-header">
        <div className="font-weight-bold">{name}</div>
      </div>
      <div>
        <BillTable
          payments={payments}
          fieldName={fieldName}
          deleteBill={deleteBill}
        />
        {fields.length !== 0 && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <label className="mx-8">
              Selectionnez l'un des {fieldName}
              <span className="text-danger"> *</span>
            </label>
            <div className="row p-3">
              <BillFields
                fields={fields}
                remove={remove}
                periodes={periodes}
                fieldName={fieldName}
                list={list}
                register={register}
              />
            </div>
            <button className="btn btn-primary m-2" type="submit">
              Valider
            </button>
          </form>
        )}
        <div className="card-footer">
          {fields.length !== 0 && (
            <React.Fragment>
              {" "}
              <button
                type="reset"
                onClick={() => reset()}
                className="btn btn-secondary m-2"
              >
                <i className="fa fa-redo "></i>reset
              </button>
            </React.Fragment>
          )}

          <button
            className="btn btn-primary float-right mb-2"
            onClick={() => append()}
          >
            ajouter {name}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BillContainer;
