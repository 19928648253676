/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import Container from "../../components/Container/Container";
import Table from "../../components/Table/Table";
import { API, ROUTER } from "../../constants/env";
import swal from "sweetalert";

import ModalListStudent from "./ModalListStudent";

function ListClasses() {
  const fields = [
    { title: "Nom Classe", field: "name" },
    { title: "Niveau", field: "level" },
    { title: "Liste Eleve", field: "students" },
  ];
  const [data, setData] = useState([]);
  const fetchData = (currentPage, count, search, setTotalPage, setTotal) => {
    fetch(
      API.classes.list +
        `?page=${currentPage}&count=${count}&searchQuery=${search}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("token"),
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          return swal("Error", response.message, "error");
        }
        const result = response.data.map((elem) => ({
          _id: elem._id,
          name: elem.name,
          level: elem.level,
          students: (
            <ModalListStudent
              name={elem.name}
              level={elem.level}
              id={elem._id}
            />
          ),
        }));
        setData(result);
        setTotalPage(response.totalPage);
        setTotal(response.total);
      })
      .catch((e) => {
        return swal("Error", e.message, "error");
      });
  };

  return (
    <Container
      name="Liste des classe"
      actionRoute="/classe/new"
      actionName="Ajouter une classe"
    >
      <Table
        pageName="class"
        name="classes"
        fields={fields}
        data={data}
        editRoute={(id) => ROUTER.CLASSES.EDIT(id)}
        deleteApiRoute={API.classes.remove}
        deleteMultiple={API.classes.deleteMultiple}
        fetchData={fetchData}
        showSearch={true}
      />
    </Container>
  );
}

export default ListClasses;
